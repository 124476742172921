import axios from 'axios'
import React,{useEffect, useState} from 'react'
import { useNavigate} from 'react-router-dom'

function Notification() {
     const [notificationData, setNotificationData] = useState(null);
     const [notiStatus, setNotiStatus] = useState(null);
     const history = useNavigate();
   



    useEffect(()=>{
            axios.get('/displaynotification',{
              headers: { 'Content-Type': 'application/json'}
           })
            .then((response) =>{
                  if(response.data.notiData === false){
                            setNotiStatus('You have no notification');
                            setNotificationData(null);
                  }else{
                            setNotificationData(response.data.notiData);
                  }
            })
    },[])

    return (
        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

            
          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Notification</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      
                    </ol>
                  </div>
                </div>
              </div>
            </div>



            <div className="row">
        <div className="col-xl-12">

          { notificationData === null ? <div className="alert alert-info text-center">No notification yet</div>: 
          
        
        <div className="table-responsive">
        <table className="table project-list-table table-nowrap align-middle table-borderless">
          <thead>
            <tr>
              <th scope="col" style={{width: '100px'}}>#</th>
              <th scope="col">Title</th>
              <th scope="col">Type</th>
              <th scope="col">Status</th>
              <th scope="col">Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            { 
               notificationData && notificationData.map(({status,subject,date_time,note_type,notify_id},index) =>{
                  return (
                    <tr key={index}>
                    <td>
                        { 1+index}
                        </td>
                    <td>
                      <h5 className="text-truncate font-size-14"><div className="text-dark">{ subject }</div></h5>
                    
                    </td>
                    <td>{note_type}</td>
                    <td>
                        { status === 'seen' ? <span className="badge bg-success">SEEN</span>
                        :
                        <span className="badge bg-danger">UNSEEN</span>
                      }
                      
                      
                      </td>
                    <td>
                       { date_time }
                      </td>
                      <td>
                      <button type="button" onClick={()=> history('/notification/'+notify_id)}  className="btn btn-primary btn-sm btn-rounded waves-effect waves-light">
         
                      View Details

                   </button>
                      </td>
                   
              
                  </tr>
                  )
               })
           
           
}
           
          </tbody>
        </table>
      </div>
}
        {/* end col */}
       
      </div>


         </div>
         </div>
        </div>
        </div>
    )
}

export default Notification
