import React,{useState, useEffect} from 'react'
import axios from 'axios'
import ReactPaginate from 'react-paginate';

function OrderManagment() {
  axios.defaults.withCredentials = true;
    const [orderCode, setOrderCode] = useState('');
    const [orderStatus, setOrderStatus] = useState(false);
    const [notify, setNotify] = useState(null);
    const [ orderNotify, setOrderNotify] = useState(false);
    const [orderData, setOrderData] = useState();
    const [invoiceData, setInvoiceData] = useState(false);
    const [loadInvoice, setLoadInvoice] = useState();
    const [orderAddress, setOrderAddress] = useState();
    const [orderPhone, setOrderPhone] = useState();
    const [orderIdCode, setOrderIDCode] = useState();
    const [totalPrice, setTotalPrice] = useState();
    const [loading, setLoading] = useState(false);

    let price =0;
    
    const convertCurrency = (num) =>{
      return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    const [pageNumber, setPageNumber] = useState(0);

    const studentPerPage = 8;
    const pagesVisited = pageNumber * studentPerPage;
    const displayStudent = orderData && orderData
    .slice(pagesVisited, pagesVisited + studentPerPage)
    .map((ord) =>{
      return (
        <tr key={ord.id} >
                                                        
        <td>{ ord.order_id } </td>
       
        <td> { ord.payment_status === "Paid" ? <span className="badge bg-success">PAID</span>
              :
              <span className="badge bg-warning">{ ord.payment_status }</span>
      } </td>
       <td> { ord.mode } </td>
        <td> { ord.delivery_status === "Delivered" ? <span className="badge bg-success">Delivered</span>
              :
              <span className="badge bg-warning">{ ord.delivery_status }</span>
      } </td>
       <td>{ ord.order_date } </td>
       
        <td>
        <button type="button" onClick={()=>handleInvoice(ord.order_id)} className="btn btn-primary btn-sm btn-rounded waves-effect waves-light">
                                        View Details
                                    </button>
        </td>
   </tr>

      )
    })
    
    const pageCount = Math.ceil(orderData && orderData.length / studentPerPage);
    const changePage = ({selected}) =>{
          setPageNumber(selected)
    }



    const shipmentData = () =>{
         
      fetch('https://fastexcellence.co.za/order_management_data/profile/'+orderCode)
      .then( res => res.json())
      .then(
        (result)=>{
            setInvoiceData(true);
             setOrderAddress(result[0].address);
             setOrderPhone(result[0].phone);
             setLoading(false)
           
        }
      )


    }


    const handleConnect = () =>{
      axios.put('/update-user-order',{
        usercode:orderCode
      },{
        headers: { 'Content-Type': 'application/json'}
     }).then((response)=>{

            setTimeout(()=>{
              if(response.data.status === 'ok'){
                setOrderNotify('Connected');

                setTimeout(()=>{
                  setOrderStatus(true)
                  setOrderNotify(false);
                },2000)
               
                
              }


            },2000);

      })


    }

    const handleInvoice = (orderID) =>{
        setLoading(true)
      fetch('https://fastexcellence.co.za/order_management_data/'+orderCode+"/"+orderID)
      .then( res => res.json())
      .then(
        (result)=>{
          setTimeout(()=>{
           
              if(result.error === false){
                    
              }else
              {
                  
                  shipmentData();
                  setLoadInvoice(result);
                  setOrderIDCode(orderID);
                 //console.log(result)

                result.map(p =>(
                        price = price + p.price
                 ))
                      setTotalPrice(price)
                      
         
              }

            

          },2000)
         
        }
      )
    

    }

    const handleOrder = () =>{
            if(orderCode === ''){
                    setNotify('Please order code is needed');
                    setTimeout(()=>{
                        setNotify(null)
                    },2000)
            }else
            {
              setOrderNotify('Connecting...');


              fetch('https://fastexcellence.co.za/order_management_data/'+orderCode)
              .then( res => res.json())
              .then(
                (result)=>{
                  setTimeout(()=>{
                   
                      if(result.error === false){
                           
                      }else
                      {
                       
                          setOrderData(result);
                          handleConnect();
                      }
    
                    
    
                  },2000)
                 
                }
              )
            
               

            }
    }

    useEffect(()=>{
        const apiorderCode ='/get-order-code';
         axios.get(apiorderCode,{
          headers: { 'Content-Type': 'application/json'}
       })
         .then((resp)=>{
               if(resp.data.mstatus === 'not ok'){
                setOrderStatus(false);
               }else
               {
                setOrderStatus(true);

                setOrderCode(resp.data.mstatus);
                fetch('https://fastexcellence.co.za/order_management_data/'+resp.data.mstatus)
                .then( res => res.json())
                .then(
                  (result)=>{
                
                      //console.log(result);
                        if(result.error === false){
                             
                        }else
                        {
                           
                           setOrderData(result)
                        }       
                  }
                )


               }
         })


    },[])

    return (
        <div>
    
        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Order Management</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Centre</li>
                      <li className="breadcrumb-item active">Order Management</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
           
            { orderStatus === false ? 
            <div className="row">
            <div className="col-xl-3">
                </div>
              <div className="col-xl-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Order Code</h5>
                     { notify !== null ? (<div className="alert alert-info">{notify}</div>) : null}
                    <p className="card-title-desc">Provide your order code to connect to your order management from fastexcellence. If you don't have the code, kindly Click <a href="https://fastexcellence.co.za/book_shop/auth" rel="noreferrer" target="_blank">Here</a></p>
                    <form>
                      <div className="form-floating mb-3">
                        <input type="text" className="form-control" 
                        id="floatingnameInput" 
                        placeholder="Enter Order Code" defaultValue="" onChange={(e)=> setOrderCode(e.target.value)} />
                        <label htmlFor="floatingnameInput">Code</label>
                      </div>
                      <div className="row">
                        
                      </div>
                      
                      <div>

                        { orderNotify !== false ? 
                           <button className="btn btn-primary waves-effect waves-light" disabled>

                           <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              { orderNotify }</button>
                              :
                        <button type="button" className="btn btn-primary w-md" onClick={handleOrder}>Submit</button>

                        }
                       
                      </div>
                    </form>
                  </div>
                  {/* end card body */}
                </div>
                {/* end card */}
              </div>
              {/* end col */}
            </div>
            :
            
            <div>
            {/* Table here */}


             { invoiceData === false ? 
                <div>
                  


                  
             <div className="row">
             <div className="col-lg-12">
               <div className="tuk">
               <div className="row mb-2">
      
      </div>
                 <div className="table-responsive">
                   <table className="table project-list-table table-nowrap align-middle table-borderless">
                     <thead>
                       <tr>
                         
                         <th scope="col">#orderID</th>
                         
                         <th scope="col">Payment Status</th>
                         <th scope="col">Mode of Payment</th>
                         <th scope="col">Delivery Status</th>
                         <th scope="col">Order Date</th>
                         <th scope="col">Action</th>
                       </tr>
                     </thead>
                     <tbody>

                     
                    { displayStudent}
                      
                    
                    </tbody>
                   </table>

                   <ReactPaginate
                   previousLabel={<i className="mdi mdi-chevron-left" />}
                   nextLabel={<i className="mdi mdi-chevron-right" />}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  breakClassName={'page-item'}
                  breakLinkClassName={'page-link'}
                  containerClassName={'pagination pagination-rounded justify-content-center mt-2 mb-5'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextClassName={'page-item'}
                  nextLinkClassName={'page-link'}
                  activeClassName={'active'}
                  
                  />

                   <center>
         { loading === true ?
      <div className="spinner-border text-primary m-1 " role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            : null }
      </center>
                    <div style={{marginTop:100}}></div>
                 </div>
               </div>
             </div>
              
           </div>

          </div>  
          :
          <div>
          
          <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="invoice-title">
                <h4 className="float-end font-size-16">Order # { orderIdCode }</h4>
                <div className="mb-4">
                  <img src="autheme/assets/images/logo-dark.png" alt="logo" height={20} />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-6">
                  
                </div>
                <div className="col-sm-6 text-sm-end">
                  <address className="mt-2 mt-sm-0">
                    <strong>Shipped To:</strong><br />
                     { orderAddress } <br />
                    { orderPhone }
                  </address>
                </div>
              </div>
             
              <div className="py-2 mt-3">
                <h3 className="font-size-15 font-weight-bold">Order summary</h3>
              </div>
              <div className="table-responsive">
                <table className="table table-nowrap">
                  <thead>
                    <tr>
                      <th style={{width: '70px'}}>Qty</th>
                      <th>Item</th>
                      <th>Grade</th>
                      <th className="text-end">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    { loadInvoice && loadInvoice.map(n=>(

                        <tr>
                        <td>{n.qty}</td>
                        <td>{n.title}</td>
                        <td>{n.grade}</td>
                        <td className="text-end">{convertCurrency(n.price)}</td>
                        </tr>
                    ))
                    }
                   
                  
                    <tr>
                      <td colSpan={3} className="text-end">Sub Total</td>
                      <td className="text-end">{ convertCurrency(totalPrice) }</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="border-0 text-end">
                        <strong>Shipping</strong></td>
                      <td className="border-0 text-end">R0.00</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="border-0 text-end">
                        <strong>Total</strong></td>
                      <td className="border-0 text-end"><h4 className="m-0">{ convertCurrency(totalPrice) }</h4></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-print-none">
                <div className="float-end">
                  <button className="btn btn-success waves-effect waves-light me-1"><i className="fa fa-print" /></button>
                  <button href="#" className="btn btn-primary w-md waves-effect waves-light" onClick={()=> setInvoiceData(false)}>Back to orders</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
          
          
          </div> 
          
          
          }  
             
            </div>
}
            {/* end row */}
          </div> {/* container-fluid */}
        </div>
        {/* End Page-content */}
        
      </div>
            </div>
    )
}

export default OrderManagment
