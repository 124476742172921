import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom'
import axios from 'axios'


function NotificationData() {
     const {nid} = useParams();
     const [subject, setSubject] = useState();
     const [requestType, setRequestType] = useState();
     const [note, setNote] = useState();
     const [status, setStatus] = useState(false);
      useEffect(()=>{
            
            axios.get('/get-user-notification/'+nid,{
                headers: { 'Content-Type': 'application/json'}
            }).then((res)=>{
                  
                 if(res.data.notice === false){
                        setStatus(false)
                 }else
                 {
                       setStatus(true)
                       setNote(res.data.notice[0].note)
                       setSubject(res.data.notice[0].subject)
                       setRequestType(res.data.notice[0].note_type)
                 }
            })
      },[])
    return (
        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Notification</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
                            <div className="col-12">
                            { status === false ? <div className="alert alert-info text-center">Oops We are unable to process your request</div> : 
                            <div className="card">
                            <div className="card-body">
        <div className="media mb-4">
          <div className="media-body">
            <h5 className="font-size-14 mt-1">{ subject }</h5>
            <small className="text-muted">{ requestType }</small>
          </div>
        </div>
       
        <p>{ note }</p>
       
       
      
     
     </div>
                             </div>
}

       </div>
           </div>

            
        </div>
        </div>
        </div>
    )
}

export default NotificationData
