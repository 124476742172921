import React from 'react'
import {Link} from 'react-router-dom'
function NotFound() {
    return (
        <div className="row justify-content-center mt-lg-5">
          <div className="col-xl-5 col-sm-8">
            <div className="card">
              <div className="card-body">
                <div className="text-center">
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <h4 className="mt-4 font-weight-semibold">No Student Found </h4>
                      <p className="text-muted mt-3">Oops! We regret to inform you that, we are unable to retrive the requested student Information </p>
                      <div className="mt-4">
                        {/* Button trigger modal */}
                        <Link className="btn btn-primary" to="/student/all-student">
                          back to all student
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center mt-5 mb-2">
                    <div className="col-sm-6 col-8">
                      <div>
                        <img src="/autheme/assets/images/error-img.png" alt="" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                  
                </div>
                {/* Modal */}
               
             
              </div>
            </div>
          </div>
        </div>
       
    )
}

export default NotFound
