import React, { useState } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import CompleteReg from './CompleteReg';
import ConfirmUserType from './ConfirmUserType'
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
Axios.defaults.withCredentials = true;

function Login({checkLogin}) {
      const validateUser = Yup.object().shape({
            email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),

            userPassword: Yup.string()
            .min(6,'Password must be at least 6 chacracter long')
            .required('Password is required')
      });
  const formOption = { resolver: yupResolver(validateUser)};

  const curDate = new Date().getFullYear();
  const [notifyuser, setNotifyUser] = useState('');
  const [userid, setUserId] = useState();
  const [useroption, setUserOption] = useState();
  const history = useNavigate();
   const [loadStatus, setLoadStatus] = useState(false);
  const checkUserOptionApi ="/verify/updateusertype";

 
 const siteName = 'FastExcellence.com';

      const {register, handleSubmit, formState } = useForm(formOption);
      const {errors} = formState;
       
      const submitForm = (data) =>{
        setLoadStatus(true);
    const api_log = "/authenticate/login"
         Axios.post(api_log,data,{
      headers: { 'Content-Type': 'application/json'}
     }).then((response)=>{

            
     if(response.data.message){
           
      setTimeout(()=>{
         setLoadStatus(false);
       setNotifyUser(response.data.message)
       localStorage.setItem("Authenticate",false);
      },2000)
  

}else
{

   if(response.data.ucome){
        setTimeout(()=>{
         setLoadStatus(false);
         localStorage.setItem("Authenticate",false);
         setNotifyUser('Start');
         setUserId(response.data.muserid)
        //history("/register");
        },2000);
    
   }else
   {
     const mUser = response.data.muser;
        localStorage.setItem("Authenticate",true);
        localStorage.setItem("userType",mUser);
           
         setTimeout(()=>{
           setLoadStatus(false);
           checkLogin(true);
           
           if(mUser === 'Tutor'){
             history("/");  
            }else
            if(mUser === 'Parent'){
             history("/");  
            }

         },2000)
      
      
}

}


     })
     
  
    
}

const parentAccount = () =>{
    const userType = 'Parent';
     Axios.put(checkUserOptionApi,
       {userid:userid,
         userType:userType},{
          headers: { 'Content-Type': 'application/json'}
       })
         .then((response)=>{
               if(response.data.utype){

               }
               else{
                  const mstatus = "Pending";
                  const muser = "Parent";
                  setNotifyUser(mstatus);
                  setUserOption(muser);
               }
         })

}

const tutorAccount = () =>{
 const userType = 'Tutor';
 Axios.put(checkUserOptionApi,
   {userid:userid,
     userType:userType},{
      headers: { 'Content-Type': 'application/json'}
   })
     .then((response)=>{
           if(response.data.utype){

           }
           else{
             const mstatus = "Pending";
             const muser = "Tutor";
             setNotifyUser(mstatus);
             setUserOption(muser);
           }
     })
}

if(notifyuser ==='Start'){

   return (
      <div>
        <ConfirmUserType siteName={siteName} handleParent={parentAccount} handleTutor={tutorAccount} />
      </div>
   )

}else
if(notifyuser === 'Pending'){
   return (
    <div>
     <CompleteReg companyName={siteName} myOption={useroption} myuserid={userid} />
    </div>
   )

   
}
else
{

 
  return (
  
    <div className="account-pages my-5 pt-sm-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6 col-xl-5">
          <div className="card overflow-hidden">
            <div className="bg-primary bg-soft">
              <div className="row">
                <div className="col-7">
                  <div className="text-primary p-4">
                    <h5 className="text-primary">Welcome Back!</h5>
                    <p>Sign in.</p>
                  </div>
                </div>
                <div className="col-5 align-self-end">
                
                  <img src="autheme/assets/images/eq4.png" alt="" className="img-fluid" style={{marginTop:-50, marginLeft:-20}} />
               
                </div>
              </div>
            </div>
            <div className="card-body pt-0"> 
              <div className="auth-logo">
                <Link to="/login" className="auth-logo-light">
                  <div className="avatar-md profile-user-wid mb-4">
                    <span className="avatar-title rounded-circle bg-light">
                      <img src="autheme/assets/images/eq1.png" alt="" className="rounded-circle" height={34} />
                    </span>
                  </div>
                </Link>
                <Link to="/login" className="auth-logo-dark">
                  <div className="avatar-md profile-user-wid mb-4">
                    <span className="avatar-title rounded-circle bg-light">
                      <img src="autheme/assets/images/eq1.png" alt="" className="rounded-circle" height={34} />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="p-2">
                { notifyuser === '' ? (<div></div>) : (<div className="alert alert-info text-center">{ notifyuser} </div>)}
                <form className="form-horizontal" onSubmit={handleSubmit(submitForm)} >
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Enter Email</label>
                    <input type="text" name="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} 
                    id="email" placeholder="" {...register('email')}  />
                     <div className="invalid-feedback">{errors.email?.message}</div>
                    
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Enter Password</label>
                    <div className="input-group auth-pass-inputgroup">
                      <input type="password" name="userPassword" className={`form-control ${errors.userPassword ? 'is-invalid' : ''}`}
                      placeholder="" aria-label="Password" aria-describedby="password-addon" {...register('userPassword')}   />
                      <div className="invalid-feedback">{errors.userPassword?.message}</div>
                     </div>
                    

                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="remember-check" />
                    <label className="form-check-label" htmlFor="remember-check">
                      Remember me
                    </label>
                  </div>
                  <div className="mt-3 d-grid">
                   
                     { loadStatus === false ?
                    <button className="btn btn-primary waves-effect waves-light" type="submit">

                      Log In</button>
                      :
                      <button className="btn btn-primary waves-effect waves-light" disabled>

                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                             Please Wait...</button>
}

                  </div>
                  <div className="mt-4 text-center">
                  
                  </div>
                  <div className="mt-4 text-center">
                    <Link to="/forgot" className="text-muted"><i className="mdi mdi-lock me-1" /> Forgot your password?</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="mt-5 text-center">
            <div>
              <p>Don't have an account ? <Link to="/register" className="fw-medium text-primary"> Signup now </Link> </p>
              <p>©  { curDate +" "+siteName } </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
 )
}
}

export default Login
