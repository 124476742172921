import React,{useState, useEffect} from 'react'
import axios from 'axios';
import $ from 'jquery'
import {useParams, Link} from 'react-router-dom'

function AssesmentCapture() {
    const { id } = useParams();
    const [loadingstatus, setLoadingStatus] = useState(false);
    const [years, setYears] = useState();
    const [centrestatus, setCentreStatus] = useState(null);
    const [loadCentres, setLoadcentre] = useState(null);
    const [viewGrade, setViewGrade] = useState(null);
    const [centresID, setCentresID] = useState();
    const [subject, setSubject] = useState(null);
    const [subjectStatus, setSubjectStatus] = useState();
    const [gradeId, setGradeID] = useState();
    const [assess, setAssess] = useState(null);
    const [assessStatus, setAssessStatus] = useState();
    const [subjectID, setSubjectID] = useState();
    const [displayCapture, setDisplayCapture] = useState(null);
    const [notifyState, setNotifyState] = useState(null)
    const [status, setStatus] = useState();
    const [marks, setMarks] = useState();
    const [subjectName,setSubjectName] = useState();
    const [term, setTerm] = useState();
    const [mid, setMid] = useState();
    const [captureStatus, setCaptureStatus] = useState(false);
    const [gradeRdata, setGradeRData] = useState(null);
    const [studN, setStudN] = useState();
    const [subjectN, setSubjectN] = useState();
    const [gterm, setGterm] = useState();
    const [studID, setStudID] = useState();
    const [quID, setqID] = useState();
    const [topID,setTopID] = useState();
    const [alertStatus, setAlertStatus] = useState();
    const [userScore, setUserScore] = useState();
    const [checkAlreadyScore, setCheckAlreadyScore] = useState('');
    const [downloadID, setDownloadID] = useState();
    const [subjectId, setSubjectId] = useState();
    const [question, setQuestion] = useState(null);
    const [topic_id, setTopic_ID] = useState();
    const [highergradenotify, setHigherGradeNotify] =  useState(null);
    const [studentName, setStudentName] = useState();
    const [studentID, setStudentID] = useState();
    const [gradeData, setGradeData] = useState(null);
    const [currTerms, setCurrTerms] = useState(null);
    const [uplID, setUplID] = useState();
  
   

  

    const terms = [
      {termly:'Term 1', id:1},
      {termly:'Term 2', id:2},
      {termly:'Term 3', id:3},
      {termly:'Term 4', id:4}
      
    ]
    

    const checkTerms = () => {
      let options = "";
    
      switch (currTerms) {
        case "1":
          options = <option value={'Term 1'}>Term 1</option>;
          break;
        case "2":
          options = <option value={'Term 2'}>Term 2</option>; 
          break;
        case '3':
          options = <option value={'Term 3'}>Term 3</option>;
          break;
        case '4':
          options = <option value={'Term 4'}>Term 4</option>;
          break;
        default:
          options = <option disabled>Terms</option>;
      }
    
      return options;
    };


  const task =id;


  const getStudentGrade = (cid) => {    
               
    axios.get('/get-user-grade?cid='+cid,{
       headers:{ 'Content-Type': 'application/json'}
     })
     .then((response)=>{
                
           if(response.data.status === false){
          
               setGradeData(null)
             
           }else{
            setGradeData(response.data.status)
           }

     }) 
}

  const checkGrade = (centres) =>{
       if(centres === ''){
          setGradeRData(null)
       }else
       {
         getStudentGrade(centres)
        setCentresID(centres);

       }
        
       
          
  }

     const addingMark = (mark,uploadID,totalMark,id) =>{
        setMarks(mark);
        setMid(id)
 
if(parseInt(mark) > parseInt(totalMark)){
  setStatus(<strong style={{color:'red'}}>Invalid Input</strong>);
}else{
     setStatus(null)
axios.post('/add-assessmentmark',{
    mark:mark,
    uploadID:uploadID
},{
headers:{ 'Content-Type': 'application/json'}
}).then((res)=>{
     setStatus(<strong style={{color:'darkgreen'}}>{res.data.message}</strong>)
})
}
}


  const notifyMessage = (id) =>{
         if(mid===id){
            return status;
         }else
         {
             return null;
         }
  }


  const checkSubject = (grade) =>{
    setLoadingStatus(true);
      setGradeID(grade);
        axios.get('/get-student-subject-data/'+grade,{
          headers:{ 'Content-Type': 'application/json'} 
        }).then((res)=>{
            setTimeout(()=>{
              setLoadingStatus(false);
              if(res.data.studentSubject === false){
                setSubjectStatus(<option disabled>Not Available</option>);
          }else
          {
                setSubject(res.data.studentSubject);
          }
            },2000)
           
        })
  }

  const checkAssessment = (subjectID) =>{
        setSubjectID(subjectID);
    setLoadingStatus(true);
         axios.post('/get-access-assessment',{
          subjectID:subjectID,
           gradeID:gradeId, 
           task:task,
           term:term,
           year:years
         },{
          headers:{ 'Content-Type': 'application/json'} 
         }).then((res)=>{
           setTimeout(()=>{
            setLoadingStatus(false);
            if(res.data.assData === false){
              setAssessStatus(<option disabled>Not available</option>)
         }else
         {
               setAssess(res.data.assData);
         }
           },2000)
         })
  }


  const captureData = (inviID) => {
        setUplID(inviID);
    setLoadingStatus(true);
        axios.post('/get-assessmentdata-upload',{ 
          inviID:inviID, 
          gradeID:gradeId,
          centreID:centresID, 
          subjectIDs:subjectID,
          cterm:term,
          cyear:years,
           utype:'tutor'
        }).then((res)=>{
             setTimeout(()=>{
              setLoadingStatus(false);
              if(res.data.upData === false){
                setNotifyState('Oops no data found');
                setDisplayCapture(null)
           }else
           {
                setDisplayCapture(res.data.upData);
                setNotifyState(null)
           }
             },2000)
          
        })
  }
   

    useEffect(()=>{

      let min = new Date().getFullYear(),
      max = 2040,
     select = document.getElementById('year');
 
 for (var i = min; i<=max; i++){
     var opt = document.createElement('option');
     opt.value = i;
     opt.innerHTML = i;
     select.appendChild(opt);
 }
 
 select.value = new Date().getFullYear();

        setLoadingStatus(true);
     

       axios.get('/centres',{
        headers:{ 'Content-Type': 'application/json'}
       }).then((response)=>{
             setTimeout(()=>{
              setCurrTerms(response.data.term)
              setLoadingStatus(false);
              if(response.data.result === false){
                setCentreStatus(<option disabled>Not Available</option>);
          }else
          {
               setLoadcentre(response.data.result);
          }
             })
           
       })

       document.getElementById('captureForm').reset();
       setLoadingStatus(false)
       setNotifyState(null)
      
},[task])

 const arrangeStudentCaptureResult = (studID,subjectID,term,downloadID,year,studName,subjectName,mstatus)=>{
                    
                    setStudN(studName)
                    setSubjectN(subjectName);
                    setGterm(term);
                    setStudID(studID);

                    if(mstatus === 'edit'){

                      setDownloadID(downloadID);
                      setSubjectId(subjectID);

                    }else{
                   
                    axios.get('/get-subject-data-r/'+downloadID+'/'+term+'/'+subjectID+'/'+studID,{
                      headers:{ 'Content-Type': 'application/json'}
                    }).then((resp)=>{
                          if(resp.data.gradeData === false){
                            setCaptureStatus(true);
                                  setGradeRData(null)
                          }else{
                              if(resp.data.gradeData === 'modal'){
                                    setCheckAlreadyScore('modal');
                              }else{
                                setCaptureStatus(true);
                                setGradeRData(resp.data.gradeData);
                              }
                           
                          }
                    })
                  }
 }


 const continueUpdateQuest = () =>{
          ///get-grade-lower-quest/:downid/:subid'
          setCaptureStatus(true);
          axios.get('/get-grade-lower-quest/'+downloadID+'/'+subjectId,{
            headers:{ 'Content-Type': 'application/json'}
          }).then((resp)=>{
                if(resp.data.gradeData === false){
                
                        setGradeRData(null)
                }else{
                   
                     
                      setGradeRData(resp.data.gradeData);
                    
                 
                }
          })
 }


  const checkSave = (topicID,qID) =>{

              if(topicID === topID && quID===qID){
                        return(
                          <div>{ alertStatus }</div>
                        )        
                 }
  }

  

  const saveMark = (topic,givenScore,topicID,quest,score,term,uploadID,qid)=>{

        setTopID(topicID);
        setqID(qid);
        setUserScore(givenScore);
        
          const QData = {year:years,subjectID:subjectID,topic:topic,subject:subjectN,studID:studID,topicID:topicID,givenScore:givenScore,score:score,question:quest,qid:qid,term:term,uploadID:uploadID}
          axios.post('/save-grader-score',QData,{
            headers:{ 'Content-Type': 'application/json'}
          }).then((res)=> {
              if(res.data.gstatus === false){
                setAlertStatus(<strong style={{color:'red'}}>{ res.data.markStatus}</strong>);
              }else{
                setAlertStatus(<strong style={{color:'green'}}>{ res.data.markStatus}</strong>);
              }
          })
        

  }

   $(document).ready(function(){
    $(".checkUserScore").unbind().change(function(){
      let mscore = $(this).attr("id").replace("score-","");
        let scoreValue = $("#score-"+mscore).val();
        switch(parseInt(scoreValue)){
          case 1:
         
             $("#grade-"+mscore).html('0 - 29%');
             
            break;

            case 2:
           
           $("#grade-"+mscore).html('30 - 39%');
            
            break;
            case 3:
           $("#grade-"+mscore).html('40 - 49%');

            break;
            case 4:
           
           $("#grade-"+mscore).html('50 - 59%');
            break;

            case 5:
            
           $("#grade-"+mscore).html('60 - 69%');
             
            break;
            case 6:
            
           $("#grade-"+mscore).html('70 - 79%');
            break;
            case 7:
           
           $("#grade-"+mscore).html('80 - 100%');
              
            break;
      }

       //$("#grade-"+mscore).html();
       
})

   })


   const captureOthers = (student_id,subject_id,year,studentName,subject_name,invDown,terms) =>{
                  setStudentID(student_id);
                  setSubjectID(subject_id);
                  setStudentName(studentName);
                  
               const uDatas = {studID:student_id,subID:subject_id,year:year,upDown:invDown,term:terms}
            axios.post('/get-higher-grade-quest',uDatas,{
              headers:{ 'Content-Type': 'application/json'}
            }).then((res)=>{
                  if(res.data.mDatas === false){
                       setHigherGradeNotify(<div className='alert alert-info text-center'>No topic found for this {subject_name}</div>)
                  }else{
                    setCaptureStatus(true);
                    setHigherGradeNotify(null)
                      setQuestion(res.data.mDatas[0].question);
                      setTopic_ID(res.data.mDatas[0].topic_id);
                      setSubjectName(res.data.mDatas[0].subject);
                  }
            })

   }

   const notifyScore =(qid)=>{
    if(qid == quID){
          return(
           <div>{ alertStatus }</div>
          )
    }
}

const saveHigherGrade = (qid,quest,score) =>{
  setqID(qid);

  const markData ={qid:qid,quest:quest,score:score,topicID:topic_id,studentID:studentID,term:term,year:years,uploadID:uplID}
  axios.post('/add-higher-grade-mark',markData,{
    headers:{ 'Content-Type': 'application/json'}
  }).then((res)=>{
      if(res.data.status === false){
        setAlertStatus(<strong style={{color:'red'}}>Not Saved</strong>);
      }else{
        setAlertStatus(<strong style={{color:'green'}}>{ res.data.status}</strong>);
      }
  })

}


const displayHigherGradeQuestion = () =>{
 
 
  if(highergradenotify === null){
    if(question === null){

    }else{

        let quest = question.split('=>');

    
  return (
    <div className="table-responsive">

<button className="btn btn-primary waves-effect waves-light" style={{float:'right'}} type='button' onClick={()=>setCaptureStatus(false)}>Back to all {task }</button>

    <h2 className='card-title'>Subject: { subjectName }</h2>
    <small><strong>Note: result capture are saved automatically. You can re-capture this learner anytime you wish </strong></small><br></br>
    <table className="table project-list-table table-nowrap align-middle table-borderless">
        <thead>
        <tr>

            <th scope="col">Topic</th>

            <th scope="col">Terms</th>

            <th scope="col">Assessment</th>

            <th scope="col">Level</th>

        </tr>
        </thead>

        <tbody>
        { quest.map((value, index)=>(
        <tr key={index}>
            <td>{value} (G {gradeId}) </td>
            <td>{term}</td>
            <td>{task}</td>
            <td>
                <div className="row">

                    <div className="col-md-4">
                        <select className="form-select form-control checkUserScore" id={'score-'+index} style={{float:'right'}} onChange={(e)=>saveHigherGrade(index+1,value,e.target.value)} >
                            <option value="">Level</option>
                            <option value={7}>Level 7 (A+)</option>
                            <option value={6}>Level 6 (A)</option>
                            <option value={5}>Level 5 (B)</option>
                            <option value={4}>Level 4 (C)</option>
                            <option value={3}>Level 3 (D)</option>
                            <option value={2}>Level 2 (E)</option>
                            <option value={1}>Level 1 (F)</option>
                        </select>
                    </div>


                    <div className="col-md-2">
                        <div style={{marginTop:8}}></div>
                        <span id={'grade-'+index}></span>

                    </div>
                    <div className="col-md-2">
                        { notifyScore(index+1)}
                    </div>

                </div>
            </td>
        </tr>

        ))}



        </tbody>

    </table>

</div>
  )
            }
  
          }else{
              return (
                highergradenotify
              )
          }     

}



   const displayQuestion = (question,score,topicID,term,uploadID,topic) => {
              const quest = question.split('=>');
                 return(
                     <tbody>
                       { quest.map((value, index)=>(
                          <tr key={index}>
                            <td style={{width:10}}>{index+1})</td>
                            <td>{value}</td>
                            <td>
                               <div className="row">
                                
                              <div className="col-md-4">
                              <select className="form-select form-control checkUserScore" id={'score-'+index} style={{float:'right'}} onChange={(e)=>saveMark(topic,e.target.value,topicID,value,score,term,uploadID,index+1)} >
                                <option value="">Score</option>
                                <option value={7}>Code 7 (A+)</option>
                                <option value={6}>Code 6 (A)</option>
                                <option value={5}>Code 5 (B)</option>
                                <option value={4}>Code 4 (C)</option>
                                <option value={3}>Code 3 (D)</option>
                                <option value={2}>Code 2 (E)</option>
                                <option value={1}>Code 1 (F)</option>
                                </select>
                                </div>
                             

                              <div className="col-md-3">
                                <p id={'grade-'+index}></p>
                            
                                </div>
                                <div className="col-md-2">
                                <p>{ checkSave(topicID,index+1) }</p>
                                  </div>

                                </div>
                             
                            </td>
                            
                          </tr>
                       ))}
                     </tbody>
                 )

   }



    return (
        <div>
     
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Capture { task }</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Academics</li>
                        <li className="breadcrumb-item active">Capture { task }</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>


          <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
            
              <form className="row gy-3 gx-5 align-items-center" id='captureForm'>

              <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Term</label>
                   
                  <select className="form-select form-control" onChange={(e)=>setTerm(e.target.value)} id="autoSizingSelect" style={{textTransform:'capitalize'}}>
                  <option value=''> Term </option>
                        { checkTerms() }
                  </select>
                </div>


                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Academic Year</label>
                   
                  <select className="form-select form-control" id="year" onChange={(e)=>setYears(e.target.value)}>
                  <option value=''> Year </option> 
                  </select>
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Centres</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=>checkGrade(e.target.value)}>
                    <option value=''> Centres</option>
                        { loadCentres === null ? centrestatus :
                            loadCentres && loadCentres.map(ct=>(
                               <option key={ct.id} value={ct.center_id}>{ ct.centreName }</option>
                            ))
                        }
                  </select>
                </div>


                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Grade</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=>checkSubject(e.target.value)}>
                    <option value={false}> Grade</option>
                     { gradeData === null ? <option disabled>Not Available</option> :
                          gradeData.map(g=>(
                            <option key={g.id} value={g.grade}> Grade: { g.grade }</option>
                          ))
                     }
                  </select>
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Subject</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=> checkAssessment(e.target.value)}>
                    <option value={false}> Subject</option>
                    { subject === null ? subjectStatus :
                      subject && subject.map(s=>(
                        <option key={s.id} value={s.subject_id}>{s.subject+' ('+s.lang+')'}</option>
                      ))
                    }
                   
                  </select>
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect" >Assessment</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=> captureData(e.target.value)} >
                    <option value={false}> Assessment</option>
                      { assess === null ? assessStatus :
                        assess && assess.map(ad=>(
                          <option key={ad.id} value={ad.invigilator_id}>{ ad.assessment }</option>
                        ))}
                  </select>
                </div>
                
                <center>
                    { loadingstatus !== false ?
                <div className="spinner-border text-primary m-1 " role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            : 
                                            notifyState !== null ? <div className="alert alert-info text-center"> { notifyState }</div> :
                                            null
}

                </center>
              </form>
            </div>
            {/* end card body */}
          </div>
          {/* end card */}
        </div>
        {/* end col */}
      </div>


        { displayCapture === null ? null :
         <div className="row">
         <div className="col-lg-12">
           
          { captureStatus !==false  ? 

                 gradeId === 'R' ?

               <div>
                     <button className="btn btn-primary waves-effect waves-light" style={{float:'right'}} type='button' onClick={()=>setCaptureStatus(false)}>Back to all Task</button>
              <h3 className="card-title">{gterm+' - '+subjectN+' ('+studN+')' }</h3>
              <small><strong>Note: score are saved automatically. You can re-score this learner anytime you wish </strong></small><br></br>
              <br></br>
                 { gradeRdata !== null ?

                    gradeRdata && gradeRdata.map(({topic,question,term,score,assessment_upload_id,topic_id},index)=>{
                        return(

                          <div className="row" key={index}>

                          
                        <div className="table-responsive">
                        <table className="table mb-0">
                          <thead>
                            <tr style={{backgroundColor:'darkblue', color:'white'}}>
                              <th ></th>
                              <th ><strong>{index+1}</strong>) Topic: {topic}</th>
                              <th></th>
                             
                            </tr>
                            </thead>
                             
                                  { displayQuestion(question,score,topic_id,term,assessment_upload_id,topic)}
                              

                                </table>
                            </div>
        
                            
                        </div>


                        )
                    })
                 
                    
                    :
                     <div className="alert alert-info text-center">
                       No data found 
                       </div>
                }

                 </div>
                 : 

                 displayHigherGradeQuestion()
          : 
        
         <div className="table-responsive">
 
 <table className="table project-list-table table-nowrap align-middle table-borderless">
       <thead>
         <tr>
           
           <th scope="col">Learners</th>
           
           <th scope="col">Subject</th>
          
           <th scope="col">Assessment</th>
           <th scope="col">Location</th>
           <th scope="col">Mark</th>
          
         </tr>
       </thead>
 
       <tbody>
          { displayCapture && displayCapture.map(({invigilator_id,grade,subject_name,studentName,student_id,subject_id,tasktype,mark,overall_mark,assess_upload_id,id,terms,year},index)=>{
                 return (
                      <tr key={index}>
                        <td style={{textTransform:'capitalize'}}>{ studentName }</td>
                        <td>{subject_name }</td>
                        <td>Grade: {grade} - {tasktype}</td>
                        <td></td>
                        <td>
                        { grade === 'R' ?  
                          checkAlreadyScore === 'modal' ?  <button type="button" onClick={()=>arrangeStudentCaptureResult(student_id,subject_id,terms,invigilator_id,year,studentName,subject_name,'edit')} data-bs-toggle="modal" data-bs-target="#staticBackdrop"  className="btn btn-primary btn-sm waves-effect waves-light"> Capture Mark </button>
                            :
                        <button type="button" onClick={()=>arrangeStudentCaptureResult(student_id,subject_id,terms,invigilator_id,year,studentName,subject_name,'none')}  className="btn btn-primary btn-sm waves-effect waves-light"> Capture Mark </button>
                            :
                          <div>
                          
                         
                          <button type="button" onClick={()=>captureOthers(student_id,subject_id,year,studentName,subject_name,invigilator_id,terms)}  className="btn btn-primary btn-sm waves-effect waves-light"> Capture Mark </button>
                      


                          </div>
          }
                          
                          
                          </td>
                      </tr>
                 )
          })}
            
       </tbody>
 
       </table>
 
 
   </div>
}
 
               </div>
         </div>
 
        
}
        

<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                    <h5 className="modal-title" id="staticBackdropLabel" style={{marginLeft:10, marginTop:20}}>Notcie!</h5>
                                                          
                                                        <div className="modal-body">
                                                            <p>Result for this particular subject for this student has been compile for this term. If you wish to re-compile this result, kindly click continue but note previous result compilation for this term  will be lost</p>
                                                        </div>
                                                        <div className="modal-footer">
                                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                            <button type="button" onClick={()=>continueUpdateQuest()} className="btn btn-primary" data-bs-dismiss="modal">Yes Understood</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
          </div> {/* container-fluid */}
        </div>
        {/* End Page-content */}
     
      </div>
      {/* end main content*/}
        </div>
    )
}

export default AssesmentCapture
