import React from 'react'
import { Link } from 'react-router-dom'

function ErrorParent({checkLogin}) {
    return (
        <div>
               <div className="main-content">
        <div className="page-content">
          <div className="container-fluid"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mb-5">
                <h1 className="display-2 fw-medium">4<i className="bx bx-buoy bx-spin text-primary display-3" />4</h1>
                <h4 className="text-uppercase">Sorry, page not found</h4>
                <div className="mt-5 text-center">
                  
                  <Link className="btn btn-primary waves-effect waves-light" to="/">
                  { checkLogin !== true ?
                    (<div>Back to Login</div>) :
                    (<div>Back to Dashboard</div>)
                  }</Link>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-8 col-xl-6">
              <div>
                <img src="/autheme/assets/images/error-img.png" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
    )
}

export default ErrorParent
