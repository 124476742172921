import axios from 'axios';
import React,{useState, useEffect } from 'react'
import {useParams} from 'react-router-dom'
import Footer from '../../Nav/Footer';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import NotFound from '../../Authent/NotFound';

function StudentDetails() {
    axios.defaults.withCredentials = true;

    const schema = Yup.object().shape({
        firstname: Yup.string()
        .required('First name is required'),
        lastname: Yup.string()
        .required('Last name is required'),
        email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
        phone:Yup.string()
        .required('Phone is required'),
        studentID: Yup.string()
        .required('Student ID is required'),
        studentrace: Yup.string()
        .required('Student race is required'),
        gender: Yup.string()
        .required('Gender is required'),
        otherLang: Yup.string()
        .required('Other language is required'),
        age: Yup.string()
        .required('Age  is required'),
        dob: Yup.string()
        .required('Date of birth is required'),
        address: Yup.string()
        .required('Address is required'),
        studcode: Yup.string()
        .required('First name is required')

    })

    const formOptions = { resolver: yupResolver(schema) };

    const {register, handleSubmit, formState } = useForm(formOptions);
     const {errors} = formState;

        const submitForm = (data) => {
                    console.log("am ready")
        }

    const { id } = useParams();
    const [validuser, setValidUser] = useState(false);
    const [firstname, setFirstName] = useState();
    const [lastname, setLastName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [studentid, setStudentId] = useState();
    const [studentrace, setStudentRace] = useState();
    const [studentgender, setStudentGender] = useState();
    const [studenthomelang, setStudentHomeLang] = useState();
    const [studentotherland, setStudentOtherlang] = useState();
    const [studentdob, setStudentDob] = useState();
    const [studenthomeAge, setStudentAge] = useState();
    const [studentaddress, setStudentAddress] = useState();

  
    
    useEffect(() => {
       const ApiRout = "/get-student-data/"+id;
         axios.get(ApiRout,{
          headers: { 'Content-Type': 'application/json'}
       })
         .then((response) => {
                 setValidUser(response.data.userstatus);
               if(response.data.userstatus === true){
                      setFirstName(response.data.result[0].firstname)
                      setLastName(response.data.result[0].lastname)
                      setEmail(response.data.result[0].email)
                      setPhone(response.data.result[0].phone)
                      setStudentId(response.data.result[0].student_id_pass)
                      setStudentRace(response.data.result[0].student_race)
                      setStudentGender(response.data.result[0].gender)
                      setStudentHomeLang(response.data.result[0].home_lang)
                      setStudentDob(response.data.result[0].dob)
                      setStudentAge(response.data.result[0].age)
                      setStudentOtherlang(response.data.other_lang);
                      setStudentAddress(response.data.result[0].home_address);
                      
                        
               }
         })
       
    }, [])
     
    return (
        <div id="layout-wrapper">
        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
          
          { validuser === false ? 
          
         <NotFound />
          
          : 
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title" id="title">Student Information </h5>
                    <p className="card-title-desc">Provide all details to complete your registration</p>
                    <form onSubmit={handleSubmit(submitForm)}>
                    <input type="hidden" name="studcode" defaultValue={id} {...register('studcode')}  />
                   
  
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="text"  name="firstname" id="fnames" placeholder="Enter Your Full Names" {...register('firstname')} className={`form-control ${errors.firstname ? 'is-invalid' : ''}`}  value={firstname}  />
                          <label htmlFor="floatingemailInput">First Name</label>
                          <div className="invalid-feedback">{errors.firstname?.message}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="text" name="lastname" id="lnames" placeholder="Enter id/passport number" {...register('lastname')} className={`form-control ${errors.lastname ? 'is-invalid' : ''}`}  value={lastname}  />
                          <label htmlFor="floatingemailInput">Last Name</label>
                          <div className="invalid-feedback">{errors.lastname?.message}</div>
                        </div>
                      </div>
                    </div>
    
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="email" name="email" id="email" placeholder="Enter Email address" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} value={email}  />
                            <label htmlFor="floatingemailInput">Email address</label>
                            <div className="invalid-feedback">{errors.email?.message}</div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="text" name="phone" id="phone" placeholder="Enter Phone Number" {...register('phone')} className={`form-control ${errors.phone ? 'is-invalid' : ''}`}  defaultValue={phone} />
                            <label htmlFor="floatingemailInput">Phone Number</label>
                            <div className="invalid-feedback">{errors.phone?.message}</div>
                          </div>
                        </div>
                      </div>
  
                      <div className="form-floating mb-3">
                        <input type="text" name="studentID" id="studentid" placeholder="Enter Student ID/Passport ID" {...register('studentID')} className={`form-control ${errors.studentID ? 'is-invalid' : ''}`}  defaultValue={studentid} />
                        <label htmlFor="floatingnameInput">Student ID/Passport No</label>
                        <div className="invalid-feedback">{errors.studentID?.message}</div>
                      </div>
  
  
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="text" name="studentrace" id="studentrace" placeholder="Enter Student Race" {...register('studentrace')} className={`form-control ${errors.studentrace ? 'is-invalid' : ''}`}  defaultValue={studentrace}  />
                            <label htmlFor="floatingemailInput">Student Race</label>
                            <div className="invalid-feedback">{errors.studentrace?.message}</div>
                          </div>
                        </div>
  
  
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="text" name="gender" id="gender" placeholder="Enter Gender" {...register('gender')} className={`form-control ${errors.gender ? 'is-invalid' : ''}`}  defaultValue={studentgender} />
                            <label htmlFor="floatingemailInput">Gender</label>
                            <div className="invalid-feedback">{errors.gender?.message}</div>
                          </div>
                        </div>
  
                      </div>
  
  
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="text"  name="home_lang" id="email" placeholder="Enter Home Language" {...register('home_lang')} className={`form-control ${errors.home_lang ? 'is-invalid' : ''}`}  defaultValue={studenthomelang}   />
                            <label htmlFor="floatingemailInput">Home Language</label>
                            <div className="invalid-feedback">{errors.home_lang?.message}</div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="text" name="otherLang" id="otherlang" placeholder="Enter Other Language" {...register('otherLang')} className={`form-control ${errors.otherLang ? 'is-invalid' : ''}`}  defaultValue={studentotherland} />
                            <label htmlFor="floatingemailInput">Other Languages</label>
                            <div className="invalid-feedback">{errors.otherLang?.message}</div>
                          </div>
                        </div>
                      </div>
                     
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="text" name="dob" id="dob" placeholder="Date of Birth" {...register('dob')} className={`form-control ${errors.dob? 'is-invalid' : ''}`}  defaultValue={studentdob}   />
                            <label htmlFor="floatingemailInput">Date of Birth</label>
                            <div className="invalid-feedback">{errors.dob?.message}</div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="text"  name="age" id="age" placeholder="Enter Student Age" {...register('age')} className={`form-control ${errors.age ? 'is-invalid' : ''}`}  defaultValue={studenthomeAge} />
                            <label htmlFor="floatingemailInput">Student Age</label>
                            <div className="invalid-feedback">{errors.age?.message}</div>
                          </div>
                        </div>
                      </div>
  
   
                     
                      <div className="form-floating mb-3">
                        <input type="text" name="address" id="address" placeholder="Enter Home Address" {...register('address')} className={`form-control ${errors.address ? 'is-invalid' : ''}`}  defaultValue={studentaddress}  />
                        <label htmlFor="floatingnameInput">Home Address</label>
                        <div className="invalid-feedback">{errors.address?.message}</div>
                      </div>
  
  
                      <div style={{float: 'right'}}>
                        <button type="submit" className="btn btn-primary w-md submit">Save</button>
                  
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
}
         
          </div>
          </div>
         <Footer />
        </div>
      </div>
       
    )
}

export default StudentDetails
