import axios from 'axios';
import React,{ useState} from 'react';
import { Link } from 'react-router-dom'

function ValidateCode({userEmail,siteName,sendCode}) {
         const curDate = new Date().getFullYear();
        const [code, setCode] = useState();
        const [loadData, setLoadData] = useState(false);
        const [notify, setNotify] = useState(null);

        const resendCode = () => {
          setLoadData(true)
             axios.post('/password-reset-user',{email:userEmail},{
                headers:{'Content-Type':'application/json'}
             }).then((res)=>{
                  setTimeout(()=>{
                    setLoadData(false)
                    setNotify(res.data.message);
                  },500)
                 
             })
        }

        //nextLocation,useremail,coder
     const handleCode = () =>{
             if(!code.trim()){
                setNotify('Please provide verification code');
             }else{
                   setLoadData(true)
                 axios.post('/validate-user-code',{
                       code:code,
                       email:userEmail
                 }).then((response)=>{
                         setTimeout(()=>{
                            if(response.data.status === 'allow'){
                                sendCode('reset',userEmail,code)
                           }else
                           {
                                setNotify(response.data.message);
                           }
                         },2000)
                      
                 }) 

             }
     }

    return (
        <div>
             <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mb-5 text-muted">
                <Link href="/" className="d-block auth-logo">
                  <img src="autheme/assets/images/eq4.png" alt="" height={30} className="auth-logo-dark mx-auto" />
                  <img src="autheme/assets/images/eq4.png" alt="" height={30} className="auth-logo-light mx-auto" />
                </Link>
                <p className="mt-3">Password Reset</p>
              </div>
            </div>
          </div>
          {/* end row */}
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card">
                <div className="card-body">
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary" />
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Verify your email</h4>
                        <p>We have sent you verification code to <span className="font-weight-semibold">{ userEmail }</span>.<br></br> Please check your inbox, if is not in your inbox, kindly check your spam box.<br></br>
                         
                        </p>
                      </div>
                       { notify !== null ? <div className="alert alert-info">{ notify} </div> : notify }
                      
                    </div>
                  
                   
                      <div className="mb-3">
                        <label htmlFor="useremail" className="form-label">Enter Code</label>
                        <input type="number" maxLength={6} minLength={6} className="form-control text-center" onChange={(e)=>setCode(e.target.value)} />
                      </div>
                      <div className="text-end">
                        <button onClick={()=>handleCode()} className="btn btn-primary w-md waves-effect waves-light" type="submit">Submit</button>
                      </div>
                
                  </div>
                </div>
              </div>
              { loadData === false ? null : 
      <div id="preloader-spinner" className="preloader spinner">

<div className="wrapper">
    <span className="loader"></span>

    <p style={{marginTop:120}}>Processing your request. Please wait...</p>
</div>

</div>
}
            <div className="mt-5 text-center">
            <p>Did not receive OTP code ? <div onClick={()=> resendCode()} class="fw-medium text-primary" style={{cursor:'pointer'}}> Resend </div> </p>
                <p>©  { curDate +' '+ siteName } </p>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    )
}

export default ValidateCode
