import React, {useEffect, useState, useRef} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'


let autoComplete;


const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current
  );
  autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  const longitude = addressObject.geometry.location.lng();
  const latitude = addressObject.geometry.location.lat();
  
   localStorage.setItem('lat',latitude);
   localStorage.setItem('lng',longitude);
   
  updateQuery(query);
 
 // console.log(addressObject);

}



function AddCentre({handle}) {
        const [query, setQuery] = useState("");
        const autoCompleteRef = useRef(null);
        const [centreName, setCentreName] = useState("");
        const [centrePhone, setCentrePhone] = useState("");
        const [centreEmail, setCentreEmail] = useState("");
        const [nameError, setNameError] = useState('');
        const [emailError, setEmailError] = useState('');
        const [phoneError, setPhoneError] = useState('');
        const [addressError, setAddressError] = useState('');
        const [loading, setLoading] = useState(false);
        const [notify, setNotify] = useState(null);
        const [mregion, setMRegion] = useState(null);
        const [loadRegion, setLoadRegion] = useState();
        const [region,setRegion] = useState();
       

      
   
  


        const checkError = () =>{
              let valid = true;
              if(!centreName.trim()){
                    setNameError('Please provide centre name');
                    valid = false
              }else
              {
                  setNameError('');
                    valid = true;
              }
              

              if (!centreEmail.trim()) {
                  setEmailError('Please enter your email address');
                  valid = false;
                    
              }else if (!/\S+@\S+\.\S+/.test(centreEmail)) {
                setEmailError('Please provide a valid email address');
                valid = false;
                 
              }else{
                 setEmailError('');
                 valid = true;
              }

              if (!centrePhone.trim()) {
                  setPhoneError('Please provide your phone number');
                  valid = false;
                  
              }else
              {
                  setPhoneError('')
                  valid = true;
              }

              if (!query.trim()) {
                  setAddressError('Please provide your centre address');
                  valid = false
                  
              }else
              {
                valid = true;
                 setAddressError('');
              }

               return valid;
        }


      

           const handleSubmit = (e) =>{
              
              e.preventDefault();
              const lat = localStorage.getItem('lat');
              const lng = localStorage.getItem('lng');


                  if(!checkError()){}else{

                  axios.post('/create-centre',{
                      centreName:centreName,
                      centreEmail:centreEmail,
                      centrePhone:centrePhone,
                      centreAddress: query,
                      lat:lat,
                      lng:lng,
                      region:region,
                      ufrom:'tutor'

                  },{
                    headers: { 'Content-Type': 'application/json'}
                 })
                  .then((response)=>{
                              setLoading(true)
                         setTimeout(()=>{
                               setLoading(false);
                             
                            if (response.data.message === true) {
                                  setNotify('Your centre was created successfully')
                            }else
                            {
                              setNotify(response.data.message);
                            }

                         },2000);
                  })
                }
           }

           const fetchRegion = () => {
            axios.get('/all-region',{
              headers: { 'Content-Type': 'application/json'},
              withCredentials:true
              
           })
            .then((response)=>{
                 if(response.data.allRegion === false){
                    setMRegion(<option disabled>No data available</option>)
                 }else{
                   
                  setLoadRegion(response.data.allRegion)
                 }
            })
        } 
       
        useEffect(() => {
          loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
          );
          fetchRegion();
        
        }, []);
    return (
        <div>
           
            
          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Add Centre</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item"><Link to="#">Centre</Link></li>
                      <li className="breadcrumb-item active">Add Centre</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>


            <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title" id="title">Centre Information </h5>
                  <p className="card-title-desc">Provide all details to complete your registration</p>
                 
                 { notify !== null ? (<div className="alert alert-info text-center">{ notify }</div>) : null }
                  <form onSubmit={handleSubmit} noValidate autoComplete='off'>
                   
                      <div className="form-floating mb-3">
                        <input type="text" className="form-control" name="centreName" 
                        id="centreName" placeholder="Enter Your Full Names" 
                        value={centreName} onChange={(e)=> setCentreName(e.target.value)} />
                        <label htmlFor="floatingemailInput">Centre Name</label>
                        { nameError !=='' ? <p style={{color:'red'}}>{ nameError }</p> : null }
                      
                      </div>
                    
                    
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="email" className="form-control"
                           name="centreEmail" id="email" placeholder="Enter Email address" value={centreEmail} onChange={(e)=> setCentreEmail(e.target.value)}  />
                          <label htmlFor="floatingemailInput">Centre Email address</label>
                          { emailError !=='' ? <p style={{color:'red'}}>{ emailError }</p> : null }
                         
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="number" className="form-control" name="centrePhone"
                           id="phone" placeholder="Enter Phone Number" value={centrePhone} onChange={(e)=> setCentrePhone(e.target.value)} />
                          <label htmlFor="floatingemailInput">Centre Phone Number</label>
                          { phoneError !=='' ? <p style={{color:'red'}}>{ phoneError }</p> : null }
                        </div>
                      </div>
                    </div>

                    <div className="form-floating mb-3">
                    <select name="applying_year" id="floatingSelectGrid" onChange={(e)=>setRegion(e.target.value)} aria-label="Floating label select example"  className="form-select">
                    { mregion !== null ? mregion :
                        loadRegion && loadRegion.map(yu =>(
                          <option key={yu.id} defaultValue={yu.location}>{ yu.location }</option>
                        ))
                    }
                            </select>
                            <label htmlFor="floatingSelectGrid">Select Region</label>
                            </div>
                   
                   
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" name="centreAddress" id="address"
                      ref={autoCompleteRef}
                      onChange={ (e)=> setQuery(e.target.value) }
                       value={query}
                       placeholder="Enter Home Address" autoComplete="off" />
                      <label htmlFor="floatingnameInput">Centre Address</label>
                      { addressError !=='' ? <p style={{color:'red'}}>{ addressError }</p> : null }

                     
                    </div>

                    
                    <button type="button" className="btn btn-dark w-md submit" onClick={()=> handle()}>Back to Centre</button>
                 
                    <div style={{float: 'right'}}>

                  
                  { loading === false ?
                    <button type="submit" className="btn btn-primary w-md submit">Submit</button>
                        :
                    <button className="btn btn-primary waves-effect waves-light" disabled>

                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      Please Wait...</button>
                      }


                 </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        
    
            
        </div>
   
   )
}

export default AddCentre
