import React,{useState} from 'react'
import ValidateEmail from './ValidateEmail';
import ValidateCode from './ValidateCode';
import ValidatePass from './ValidatePass';
import PasswordConfirm from './PasswordConfirm';

const Forgot = () => {
    const siteName = 'FastExcellence.com';
    const [direction, setDirection] = useState('email');
    const [email, setEmail] = useState();
    const [code, setCode] = useState();
    const sendCode = (nextLocation,useremail,coder) =>{
            setEmail(useremail);
            setCode(coder)
           setDirection(nextLocation);
          


    }
      switch(direction){
           case 'email':
                return(
                  <div>
                    <ValidateEmail sendCode={sendCode} siteName={siteName}/>
                  </div>
                )
             break;
             case 'code':
              return(
                <div>
                  <ValidateCode userEmail={email} sendCode={sendCode} siteName={siteName} />
                </div>
              )
               break;
             case 'reset':
              return(
                <div>
                  <ValidatePass userEmail={email} sendCode={sendCode} siteName={siteName} coder={code} />
                </div>
              )
               break;

               case 'success':
                 return(
                    <div>
                      <PasswordConfirm sitename={siteName} />
                    </div>
                 )
                 break;
                 default:
                   return(
                     <div>
                        
                     </div>
                   )
      }
   
}

export default Forgot
