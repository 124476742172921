import React, {useEffect, useState} from 'react'
import Footer from '../../Nav/Footer';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

function AssessUpload() {
    const {id} = useParams();
  const [loadingstatus, setLoadingStatus] = useState(false);
  const [loadupl, setUploadupl] = useState(false);
  const [gradeStatus, setGradeStatus] = useState(null);
  const [loadGrade, setLoadGrade] = useState();

  const [subjectStatus, setSubjectStatus] = useState(null); 

  const [assStatus, setAssStatus] = useState(null);
  const [assessment, setAssessment] = useState(null);
  const [gradeID, setGradeID] = useState();
  const [ student, setStudent] = useState();
  const [studentID, setStudentID] = useState();
  const [subject, setSubject] = useState(null);
  const [subjectID, setSubjectID] = useState();
  const [vigilate, setVigilate] = useState(null);
  const [getinvig, setGetInvigil] = useState(null); 
  const [subjectName,setSubjectName] = useState();
  const [handleFile, setHandleFile] = useState('');
  const [assessID, setAssessID] = useState();
  const [notify, setNotify] = useState(null);
  const [studentN, setStudentN] = useState();
  const [checkUpload, setCheckUpload] = useState();
  const [year, setYear] = useState(new Date().getFullYear());
  const [term, setTerm] = useState();
  const [newUpload, setNewUpload] = useState(false);
  const [newFile, setNewFile] = useState('');
  const [notifyNew, setNotifyNew] = useState(null)
  const [myInt, setMyInt] = useState();
  const [studName, setStudName] = useState();
  const [studLang, setStudLang] = useState();
  const [subjectN, setSubjectN] = useState();
  const [taskType, setTaskType] = useState();
  const [notifyInvoice, setNotifyInvoice] = useState(null);
  const [uplAd, setUplAd] = useState(null);
  const [remo, setRemo] = useState(false);
  const [remoStatus, setRemoStatus] = useState(null);
  const [invd, setInvd] = useState();
  const [mgrade, setMgrade] = useState(null);
  const [currTerms, setCurrTerms] = useState(null);
  

 
  const task = id;


  const terms = [
    {termly:'Term 1', id:1},
    {termly:'Term 2', id:2},
    {termly:'Term 3', id:3},
    {termly:'Term 4', id:4}
    
  ]


  const checkTerms = () => {
    let options = "";
  
    switch (currTerms) {
      case "1":
        options = <option value={'Term 1'}>Term 1</option>;
        break;
      case "2":
        options = <option value={'Term 2'}>Term 2</option>; 
        break;
      case '3':
        options = <option value={'Term 3'}>Term 3</option>;
        break;
      case '4':
        options = <option value={'Term 4'}>Term 4</option>;
        break;
      default:
        options = <option disabled>Terms</option>;
    }
  
    return options;
  };

 
  const getStudent = (gradeID)=>{
    setLoadingStatus(true);
    setGradeID(gradeID);
    axios.get('/getlearners-data/'+gradeID,{
      headers:{ 'Content-Type': 'application/json'} 
    }).then((res)=>{
        setTimeout(()=>{
           setLoadingStatus(false)
          setStudent(res.data.studentData);
        },2000)
         
    })
}


  
const getSubject = (e) =>{
          let md = e.target.value;
            let uk = md.split(',');
              let status = uk[4];
                console.log(status)
            if(uk[3] === 'Paid'){
              setNotifyInvoice(null)
        setStudLang(uk[2]);
        setStudName(uk[1]);
          
  setLoadingStatus(true);
  setStudentID(uk[0])
 axios.post('/getsubject-data',{
    grade:uk[0]
 }).then((response)=>{
     setTimeout(()=>{
          setLoadingStatus(false);
      if(response.data.subjectData === false){
        setSubjectStatus(<option disabled>Not Available</option>);
 }else
 {
        setSubject(response.data.subjectData);
 }
     },2000)
     
 }).catch((err)=>{
    console.log(err)
 })
            }else{

              setNotifyInvoice(<div className='alert alert-info text-center'>You have an uncleared invoice for this learner. Kindly <Link to="/finance/unpaid-invoice" className="btn btn-primary waves-effect waves-light btn-sm">Click here  </Link></div>)

            }
 
}

    const getAssessmentDetails = (e) =>{
        let sub = e.target.value;
         let mk = sub.split(',');
          console.log(mk[0])
      setSubjectID(mk[0]);
      setSubjectN(mk[1]);
      setLoadingStatus(true)
     axios.post('/get-access-assessment',{
          gradeID:gradeID,
          subjectID:mk[0],
          task:task,
          year:year,
          term:term
     },
     {
      headers:{ 'Content-Type': 'application/json'} 
     }).then((response)=>{
          setTimeout(()=>{
              setLoadingStatus(false)
            if(response.data.assData === false){
              setAssessment(null)
                  setAssStatus(<option disabled>Not Available</option>);
                  
             }else
             {
              setAssStatus(null)
                   setAssessment(response.data.assData);
                   
             }
          },2000)
           
     })   
    }

    const uploadAssessment = (invigi_ID) =>{
              setLoadingStatus(true)
              setInvd(invigi_ID);
              let gh = invigi_ID.split(',');
              setMyInt(gh[0]);
              setTaskType(gh[1])
            axios.post('/get-assessmentdata-upload',{ 
                  invigiID:gh[0],
                  grade:gradeID,
                  studentID:studentID,
                  subjectID:subjectID,
                  pyear:year,
                  pterm:term,
                  utype:'parent',
                 


            },{
              headers:{ 'Content-Type': 'application/json'} 
            }).then((res)=>{
                         console.log(res.data.upData)
                      setTimeout(()=>{
                         setLoadingStatus(false)
                         if(res.data.upData === false){
                               
                                if(res.data.status === false){
                                  setVigilate(res.data.message);
                                }else{
                                setGetInvigil(null); 
                                 setNewUpload(true)
                                }
                         }else{
                          setVigilate(null)
                          setNewUpload(false)
                                 setGetInvigil(res.data.upData); 
                         }
                      },2000)
            })
    }

    const getSubjectID = (subID) =>{
      axios.get('/getsubject-data/'+subID,{
        headers:{ 'Content-Type': 'application/json'}
      }).then((res)=>{
            setSubjectName(res.data.msubjectData[0].subject);
            return res.data.msubjectData[0].subject;
      })
}

      const updateData = (assID,studName,upload) => {
            setAssessID(assID);
            setStudentN(studName);
            setCheckUpload(upload);
      }


      const handleNewUpload = () =>{
             if(newFile === ''){
                setNotifyNew('Please upload assessment');
             }else
             {
              setUplAd('upload')
                const uformData = new FormData();
                uformData.append('file',newFile);
                uformData.append('grade',gradeID);
                uformData.append('studID',studentID);
                uformData.append('subjectID',subjectID);
                uformData.append('year',year);
                uformData.append('term',term);
                uformData.append('utype','parent');
                uformData.append('invDownloadID',myInt);
                uformData.append('cate',task);
                uformData.append('studLang',studLang);
                uformData.append('studName',studName);
                uformData.append('subject',subjectN);
                uformData.append('taskType',taskType);


                axios.post('/upload-new-assesment', uformData).then((resp)=>{
                      setTimeout(()=>{
                        setUplAd(null);
                        if(resp.data.status === 'not'){
                          setNotifyNew(resp.data.message)
                       }else{
                        setNotifyNew(null)
                          setNewUpload(true)
                          setVigilate(null)
                            setNewUpload(false)
                                   setGetInvigil(resp.data.upData); 
  
                      }
                      },1000);

                   
                   })


                  
             }
      }

      const handleUploadFile = () =>{

                if(handleFile === ''){
                    setNotify(<div className='alert alert-info text-center'> Kindly upload your complete { task }</div>);
                }else
                {
                   setUploadupl(true)
              const formData = new FormData();
               formData.append('file',handleFile);
               formData.append('assessID',assessID);
               formData.append('studentName',studentN);
                    axios.post('/upload-assessment-data',formData).then((res)=>{
                        setTimeout(()=>{
                          setUploadupl(false)
                              setNotify(<div className='alert alert-info text-center'> {res.data.message} </div>);
                        },2000);
                    })

                   
                }

      }

   
      const getStudentGrade = () => {    
               
        axios.get('/get-user-grade',{
           headers:{ 'Content-Type': 'application/json'}
         })
         .then((response)=>{
               if(response.data.status){
                setMgrade(response.data.status)
               }else{
                 setMgrade(null)
               }
         }) 
   }

     useEffect(()=>{
      let min = new Date().getFullYear(),
      max = 2040,
     select = document.getElementById('year');
 
 for (var i = min; i<=max; i++){
     var opt = document.createElement('option'); 
     opt.value = i;
     opt.innerHTML = i;
     select.appendChild(opt);
 }
 
 select.value = new Date().getFullYear();

      setLoadingStatus(true)
      axios.get("/get-student-grade",{
        headers: { 'Content-Type': 'application/json'}
     })
      .then((response)=>{

              setTimeout(()=>{
                 setCurrTerms(response.data.term)
                setLoadingStatus(false)
                if(response.data.studentGrade === false){
                  setGradeStatus(<option disabled>No data available</option>)
            }else
            {
                    setLoadGrade(response.data.studentGrade);
                  
                     getStudentGrade()

            }
           
              },2000)
           
      })

        document.getElementById('formDatas').reset();

    },[task])


    const removeData = (upid) => {
          setRemo(true);
           setRemoStatus(null)
          axios.post('/remove-assessmentupload',{uid:upid},{
            headers: { 'Content-Type': 'application/json'}
          }).then((res)=>{
                setTimeout(()=>{
                    setRemo(false);
                    uploadAssessment(invd);
                    setRemoStatus(<div className='alert alert-info text-center'>{res.data.status}</div>)
                },1000);
          })
    }

    return (
      
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Upload { task } </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Academics</li>
                      <li className="breadcrumb-item active">Upload {task}</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>


          <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-4">{task} Upload</h5>
              <form className="row gy-3 gx-5 align-items-center" id='formDatas'>



              <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Term</label>
                   
                  <select className="form-select form-control" onChange={(e)=>setTerm(e.target.value)} id="autoSizingSelect" style={{textTransform:'capitalize'}}>
                  <option value=''> Term </option>
                        { checkTerms() }
                  </select>
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Academic Year</label>
                   
                  <select className="form-select form-control" id="year" onChange={(e)=>setYear(e.target.value)}>
                  <option value=''> Year </option> 
                
                  </select>
                </div>


                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Grade</label>
                   
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=>getStudent(e.target.value)}>
                    <option value={false}> Grade</option>
                    { 
                        mgrade === null ? '<option value="">No data found</option>' : 

                       mgrade.map(gd=>(
                         <option value={gd.grade} key={gd.id}>{'Grade '+gd.grade}</option>
                       ))
                    }
                  </select>
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Learners</label>
                  
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=>getSubject(e)}>
                    <option value={false}> Learners</option>
                    { student && student.map(st=>(
                         <option key={st.id} value={st.student_id+','+st.firstname+' '+st.lastname+','+st.lang_intruct+','+st.payment_status+','+st.status}>{ st.firstname+' '+st.lastname}</option>
                      ))}
                   
                  </select>
                 
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect" >Subject</label>
                  
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=> getAssessmentDetails(e)}>
                    <option value={false}> Subject</option>
                    { subject === null ? subjectStatus :
                       subject && subject.map(sub=>(
                          <option key={sub.id} value={sub.subject_id+','+sub.subject}>{ sub.subject+' ('+studLang+')'}</option>
                       ))
                   }
                   
                  </select>
                 
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Assessment</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=>uploadAssessment(e.target.value)}>
                    <option value={false}> Assessment</option>
                    {assessment === null ? assStatus : 
                          assessment && assessment.map(as=>(
                                <option key={as.id} value={as.invigilator_id+','+as.assessment}>{ as.assessment }</option>
                          ))
                     }
                  </select>
                </div>
                
               
              </form>

              <center>
                <br></br>
                     { notifyInvoice === null ? null : notifyInvoice }
                    { loadingstatus !== false ?
                <div className="spinner-border text-primary m-1 " role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            : 
                                          
                                            vigilate !== null ? <div className="alert alert-info text-center">{vigilate}</div> : null 
}

                </center>
            </div>
            {/* end card body */}
          </div>
          {/* end card */}
        </div>
        {/* end col */}
      </div>

        { newUpload !== false ?
      <div className="row">
 <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-4">Upload { task }</h5>
        <div className="col-lg-2">

          </div>
          <div className="col-lg-8">
            { notifyNew === null ? null : <div className="alert alert-info text-center">{notifyNew} </div>}

          <div className="mt-3">
                                                       
        <label for="formFile" className="form-label"> File Supported Format (PDF, MS WORDS, POWER POINT, EXCEL) </label>
                                                     
          <input className="form-control" type="file"  
          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
           id="formFile" onChange={(e)=> setNewFile(e.target.files[0])} />
        <br />
         { uplAd === null ? 
        <button type="button" className="btn btn-primary btn-xl" onClick={()=> handleNewUpload()}>Upload Assessment</button>
            :

            <button className="btn btn-primary waves-effect waves-light" disabled>

            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
               Please Wait...</button>
              }
     </div>
                                       

          </div>
            
            </div>
            </div>
            </div>
      : null }

      { getinvig === null ? 
      
      
         null
      
      
      :
       <div className="row">
       <div className="col-lg-12">
         
       <div className="table-responsive">
          { remo !== false ? 
          <div>
       <div className="spinner-border text-primary m-1 " role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <p>Removing Assessment. Please wait...</p>
                                            </div>
                                            :
                                            remoStatus === false ? null : remoStatus }

                                           
 
<table className="table project-list-table table-nowrap align-middle table-borderless">
     <thead>
       <tr>
         
         <th scope="col">Learners</th>
         
         <th scope="col">Assessment</th>

         <th scope="col">Upload</th>
         <th scope="col">Upload date</th>
         <th scope="col">Action</th>
        
       </tr>
     </thead>

     <tbody>
        { getinvig && getinvig.map(({studentName,subject_name,tasktype,assess_upload_id,date_upload,upload,grade},index)=>{
               return (
                    <tr key={index}>
                      <td style={{textTransform:'capitalize'}}>{ studentName }</td>
                      <td>Grade: {grade} - {subject_name} - {tasktype}</td>
                      <td>{ upload === null ? '--' : <span className="badge badge-pill badge-soft-success font-size-12">Available</span> }</td>
                      <td>{ date_upload === null ? '--' : date_upload }</td>
                      <td>
                      <div className="btn btn-primary btn-sm waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={()=> updateData(assess_upload_id,studentName,upload)}>Re-Upload </div>  
                      &nbsp;&nbsp;&nbsp;&nbsp; 
                      <div className="btn btn-primary btn-sm waves-effect waves-light" onClick={()=> removeData(assess_upload_id)}>Remove </div>  
                     
                        </td>
                    </tr>
               )
        })}
          
     </tbody>

     </table>

     <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Upload Assessment</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">


                              <div className="row">
                                     <div className="col-sm-12">
                                                        { notify === null ? null : notify }
                                                      
                                           

                                                    <div className="mt-3">
                                                     
                                                        
                                                        <label for="formFile" className="form-label"> File Supported Format (PDF, MS WORDS, POWER POINT, EXCEL) </label>
                                                        
                                                        <input className="form-control" type="file"  
                                                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                         id="formFile" onChange={(e)=> setHandleFile(e.target.files[0])}/>
                                                    </div>
                                                        
                                                </div>
                                            </div>


              
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
             
             { loadupl === false ? 
              
              <button type="button" className="btn btn-primary" onClick={()=> handleUploadFile()}>Upload</button>
              : 
  
              <button type="button" className="btn btn-primary" disabled>
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Uploading...
              </button>
            }
            </div>
          </div>
        </div>
      </div>


 </div>

             </div>
       </div>

}
          </div> {/* container-fluid */}
        </div>
        {/* End Page-content */}
        <Footer />
      </div>
     
    )
}

export default AssessUpload
