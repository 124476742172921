import React,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import AddCentre from './AddCentre';
import axios from 'axios';
import ReactPaginate from 'react-paginate';

function AllCentre() {
    const [addCentre, setAddCentre] = useState(false);
    const [foundCentre, setFoundCentre] = useState(false);
    const [loadCentre, setLoadCentre] = useState();
 
    const [checkAve, setCheckAve] = useState(null);
  
    const [pageNumber, setPageNumber] = useState(0);

      const history = useNavigate();

      const studentPerPage = 10;
      const pagesVisited = pageNumber * studentPerPage;
      const displayStudent = loadCentre && loadCentre
      .slice(pagesVisited, pagesVisited + studentPerPage)
      .map((data) =>{
        return (
          <tr key={data.id}>
                        
          <td>
            <h5 className="text-truncate font-size-14"><div className="text-dark">{ data.centreName }</div></h5>
            <p className="text-muted mb-0">Location: { data.centreAddress }</p>
          </td>
          <td> { data.centreEmail }</td>
          <td> { data.centrePhone } </td>
          <td> { data.date_reg } </td>
          <td>
            { data.status === "Pending" ?  <span className="badge bg-warning">{ data.status }</span>
               :
            <span className="badge bg-success">{ data.status }</span>
       }
          
          </td>
          <td>
          <div className="dropdown">
           <div className="dropdown-toggle card-drop" data-bs-toggle="dropdown" aria-expanded="false">
             <i className="mdi mdi-dots-horizontal font-size-18" />
           </div>
           <div className="dropdown-menu dropdown-menu-end">
             <div style={{cursor:'pointer'}} className="dropdown-item" onClick={() => viewCentreData(data.center_id)}>View All Learners</div>
             <div style={{cursor:'pointer'}} className="dropdown-item" onClick={()=>  history('/centre/update-centre-details/'+data.center_id)}>Update Centre</div>
             
           </div>
         </div>
          </td>
        </tr>
      )
      })
      
      const pageCount = Math.ceil(loadCentre && loadCentre.length / studentPerPage);
      const changePage = ({selected}) =>{
            setPageNumber(selected)
      }


    

      const handleSwitchback = () =>{
            
              if(checkAve !== null){
                setAddCentre(false)
                setFoundCentre(true)
                window.location.reload(false);
              }else
              {
                setAddCentre(false);
                setFoundCentre(false);
              
              }
             
      }

      const createNewCentreLink = () => {
            setFoundCentre(false);
             setAddCentre(true);
             
      }

      const viewCentreData = (centreID) =>{
        history('/centre/center-details/'+centreID);
      }

      useEffect(()=>{
            axios.get('/centres',{
              headers: { 'Content-Type': 'application/json'}
           })
            .then((response)=>{
                 
                   if (response.data.result === false) {
                         setFoundCentre(false);
                   }else
                   {
                     setCheckAve('ava');
                    setFoundCentre(true);
                        setLoadCentre(response.data.result);
                        

                   }
            })


      },[foundCentre])
    return (
        <div>
          
            <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

         
         { addCentre !== false ? <div>
         
         <AddCentre handle={handleSwitchback}  />
         
         
         </div> 
         
         
         :
         <div>
          
          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">All Centre</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Centre</li>
                      <li className="breadcrumb-item active">All Centre</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            { foundCentre === false ? 
            <div className="row justify-content-center mt-lg-5">
              <div className="col-xl-5 col-sm-8">
                <div className="card">
                  <div className="card-body">
                    <div className="text-center">
                      <div className="row justify-content-center">
                        <div className="col-lg-10">
                          <h4 className="mt-4 font-weight-semibold">No Centre Found</h4>
                          <p className="text-muted mt-3">Oops! you have not register any centre. Kindly use the add centre button below to add centre</p>
                          <div className="mt-4">
                            {/* Button trigger modal */}
                            <button className="btn btn-primary" onClick={()=> setAddCentre(true)}>
                              Add Centre
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center mt-5 mb-2">
                        <div className="col-sm-6 col-8">
                          <div>
                            <img src="/autheme/assets/images/verification-img.png" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    {/* Modal */}
                   
                 
                  </div>
                </div>
              </div>
            </div>
              :

            
             <div className="row">
             <div className="col-lg-12">
               <div className="tuk">
               <div className="row mb-2">
        <div className="col-sm-4">
          
        </div>
        <div className="col-sm-8">
          <div className="text-sm-end">
            
            <button onClick={()=> createNewCentreLink()} className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"><i className="mdi mdi-plus me-1" /> Add New Centre</button>
          </div>
        </div>{/* end col*/}
      </div>
                 <div className="table-responsive">
                   <table className="table project-list-table table-nowrap align-middle table-borderless">
                     <thead>
                       <tr>
                         
                         <th scope="col">Centre Name</th>
                         <th scope="col">Centre Email</th>
                         <th scope="col">Centre Phone</th>
                         <th scope="col">Date Created</th>
                         <th scope="col">Active Status</th>
                         <th scope="col">Action</th>
                       </tr>
                     </thead>
                     <tbody>

                      { displayStudent }
                    
                    </tbody>
                   </table>

                   <ReactPaginate
                   previousLabel={<i className="mdi mdi-chevron-left" />}
                   nextLabel={<i className="mdi mdi-chevron-right" />}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  breakClassName={'page-item'}
                  breakLinkClassName={'page-link'}
                  containerClassName={'pagination pagination-rounded justify-content-center mt-2 mb-5'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextClassName={'page-item'}
                  nextLinkClassName={'page-link'}
                  activeClassName={'active'}
                  
                  />

                    <div style={{marginTop:100}}></div>
                 </div>
               </div>
             </div>
              
           </div>
        
       
            }

            </div>

         }
            </div>
            </div>
            </div>


            
        </div>
    )
}

export default AllCentre
