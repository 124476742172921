import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';

function CompleteTutorReg({companyName,myOption,myuserid}) {
    const curDate = new Date().getFullYear();
    const compUrl = "/registration/category";
    const [notify,setNotify] = useState(null);
    const [combut, setComBut] = useState(false);
   

const schema = Yup.object().shape({
    tutorName: Yup.string()
    .required('Tutor name is required'),
    tutorId: Yup.string()
    .required('Passport ID/ ID No. is required'),
    tutorEmail: Yup.string()
    .required('Email is required')
    .email('Email is invalid'),
    tutorPhone: Yup.string()
    .required('Phone number is required'),
    tutorAddress: Yup.string()
    .required('Address is required'),
    userType: Yup.string()
    .required('Title is required'),
    tutoringid: Yup.string()
    .required('Title is required'),
});

const formOption = { resolver: yupResolver(schema)};
const {register, handleSubmit, formState } = useForm(formOption);
    const {errors} = formState;

    const handleSubmitTutor = (data) =>{
        setComBut(true);
        axios.post(compUrl,data,{
          headers: { 'Content-Type': 'application/json'}
       }).then((response)=>{
             setTimeout(()=>{
               setComBut(false);
               switch(response.data.auth){   
                 case 'allow':
                  setNotify(<div>Tutor registration is complete. Click <Link to="/login">here to login</Link></div>);
                   break;
                   case 'not allow':
                    setNotify(response.data.message);
                     break;
                     default:
                       setNotify(null);
             }
             },2000);
                
           
        })
      }


    return (
        <div>
          
        <div id="layout-wrapper">
        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center mb-5 text-muted">
                  <div className="d-block auth-logo">
                  <img src="/autheme/assets/images/eq4.png" alt="" height={50} className="auth-logo-dark mx-auto" />
                  <img src="/autheme/assets/images/eq4.png" alt="" height={50} className="auth-logo-light mx-auto" />
                  </div>
                  <p className="mt-3">Complete your home tutor registration</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title" id="title">{myOption} Information </h5>
                    <p className="card-title-desc">Provide all details to complete your registration</p>
                    { notify !== null ? (<div className="alert alert-warning">{notify}</div>) : null}
                    <form key={2} onSubmit={handleSubmit(handleSubmitTutor)}>
                    <input type="hidden" name="userType" defaultValue="Tutor" {...register('userType')} />
                    <input type="hidden" name="tutoringid" defaultValue={myuserid} {...register('tutoringid')} />
  
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="text"  name="tutorName" id="tutorName" placeholder="Enter Your Full Names" {...register('tutorName')} className={`form-control ${errors.tutorName ? 'is-invalid' : ''}`} />
                          <label htmlFor="floatingemailInput">Full Name</label>
                          <div className="invalid-feedback">{errors.tutorName?.message}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="text"  name="tutorId" id="tutorId" placeholder="Enter id/passport number" {...register('tutorId')}  className={`form-control ${errors.tutorId ? 'is-invalid' : ''}`} />
                          <label htmlFor="floatingemailInput">Whatsapp Number</label>
                          <div className="invalid-feedback">{errors.tutorId?.message}</div>
                        </div>
                      </div>
                    </div>
    
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="email"  name="tutorEmail" id="tutoremail" placeholder="Enter Email address" {...register('tutorEmail')} className={`form-control ${errors.tutorEmail ? 'is-invalid' : ''}`}  />
                            <label htmlFor="floatingemailInput">Email address</label>
                            <div className="invalid-feedback">{errors.tutorEmail?.message}</div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="text"  name="tutorPhone" id="tutorPhone" placeholder="Enter Phone Number" {...register('tutorPhone')}  className={`form-control ${errors.tutorPhone ? 'is-invalid' : ''}`} />
                            <label htmlFor="floatingemailInput">Phone Number</label>
                            <div className="invalid-feedback">{errors.tutorPhone?.message}</div>
                          </div>
                        </div>
                      </div>
                     
                     
                      <div className="form-floating mb-3">
                        <input type="text"  name="tutorAddress" id="tutorAddress" placeholder="Enter Home Address" {...register('tutorAddress')} className={`form-control ${errors.tutorAddress ? 'is-invalid' : ''}`}/>
                        <label htmlFor="floatingnameInput">Address</label>
                        <div className="invalid-feedback">{errors.tutorAddress?.message}</div>
                      </div>
  
          
                     
                      <div style={{float: 'right'}}>
  
                       { combut === false ?
                      <button type="submit" className="btn btn-primary w-md submit">Submit</button>
                            :
                      <button className="btn btn-primary waves-effect waves-light" disabled>
  
                                  <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                     Please Wait...</button>
         }
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  { curDate } © {companyName}
                </div>
                <div className="col-sm-6">
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
       
       
        </div>
     )
}

export default CompleteTutorReg
