import React, { useState, useEffect} from 'react'
import Footer from '../../Nav/Footer'
import '../../Authent/Loader.css';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'

const currencyFormat = (num) => {
  return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const checkDueDate = (due) =>{
     if(due === null){
        return 'Not Yet';
     }else
     {
          return due;
     }
}

const checkBill = (bill) =>{
  switch(bill){
      case 'Termly':
         return 'Termly';
        break;
        case 'year':
           return ('Yearly')
          break;
          default:
            return 'No Billing Yet';
  }
}



function StudentProfile({studentInfo,handleBack}) {
      const [gradeH, setGradeH] = useState();
      const [subjectStatus, setViewSubject] = useState(false);
      const [subjectData, setSubjectData] = useState();
      const [centreData, setCentreData] = useState(false);
      const [centreName, setCentreName] = useState();
      const [centStatus, setCentStatus] = useState(null);
      const [upsub, setUpSub] = useState(false);
      const [sfirstname, setSFirstName] = useState(studentInfo[0].firstname);
      const [slastname, setSLastName] = useState(studentInfo[0].lastname);
      const [sgender, setGender] = useState(studentInfo[0].gender);
      const [semail, setEmail] = useState(studentInfo[0].email);
      const [sphone, setPhone] = useState(studentInfo[0].phone);
      const [slocation, setLocation] = useState(studentInfo[0].home_address);
      const [indicator, setIndicator] = useState(false);
      const [note, setNote] = useState('');
      const [noteStatus, setNoteStatus] = useState(null);
      const [sfirstnameError, setFirstNameError] = useState(null);
      const [slastnameError, setLastNameError] = useState(null);
      const [sgenderError, setGenderError] = useState(null);
      const [semailError, setEmailError] = useState(null);
      const [sphoneError, setPhoneError] = useState(null);
      const [slocationError, setLocationError] = useState(null);
      const [notify, setNotify] = useState(null);
      const [removeStudentStatus, setRemoveStudentStatus] = useState(false);
     
      const [noteCent, setNoteCent] = useState(null);
      const history = useNavigate();

    const backStudent = () =>{
         handleBack();
    }


    const removeStudent = () => {
          setRemoveStudentStatus(true)
        axios.post('/remove-student-center',{studID:studentInfo[0].student_id},{
          headers: {'Content-Type': 'application/json'}
        }).then((resp)=>{
             setTimeout(()=>{
              setRemoveStudentStatus(false)
                if(resp.data.mstatus === 'allow'){
                   window.location ="";
                }
             },500)
        }).catch(err=>{
           console.log(err);
        })
    }

    const validateInput = () =>{
         let valid = true;
           if(!sfirstname.trim()){
                setFirstNameError('Please provide first name');
               valid = false;
                
           }else{
            setFirstNameError(null);
              valid = true;

           }

           if(!slastname.trim()){
            setLastNameError('Please provide last name');
           valid = false;
            
       }else{
        setLastNameError(null);
          valid = true;

       }

           if(!sgender.trim()){
            setGenderError('Please provide your gender');
            valid = false;
        }else{
          setGenderError(null);
           valid = true;
        }

        if(!semail.trim()){
          setEmailError('Please provide email address');
          valid = false;
         }else if (!/\S+@\S+\.\S+/.test(semail)){
          setEmailError('Please valid email address');
         valid = false;

         }else
         {
          setEmailError(null);
          valid = true;
         }

      if(!sphone.trim()){
        valid = false;
        setPhoneError('Please provide phone number');
        }else{
          setPhoneError(null);
       valid = true;
         }

         if(!slocation.trim()){
          setLocationError('Please provide your location');
          valid = false;
          }else{
            setLocationError(null);
         valid = true;
           }


            return valid;
    }


    

   const viewStudentSubject = (studentID,grade) =>{
            setViewSubject(true);
            axios.get('/view-subject-data/'+studentID+'/'+grade)
            .then((response)=>{
                  if(response.data.subjectData === false){

                  }else
                  {
                     setSubjectData(response.data.subjectData);
                  }
            });
   }


      const checkCentrePending = () =>{
           axios.get('/check-centre-status/'+studentInfo[0].student_id,{
            headers: {'Content-Type': 'application/json'}
           }).then((res)=>{
                  if(res.data.checkStatus === false){
                        setNoteCent(null)
                  }else
                  {
                        setNoteCent(res.data.checkStatus[0].verify)
                  }
           })
      }

     useEffect(()=>{
                setInterval(()=>{
                  checkCentrePending();
                },2000)
             
            let studentID =studentInfo[0].student_id;
            axios.get('/get-grade-history/'+studentID,{
              headers: {'Content-Type': 'application/json'}
           })
            .then((response)=>{
                  if(response.data.gradeHist === false){

                  }else{
                       setGradeH(response.data.gradeHist);
                  }
            });

            const getCentre = () =>{
      
              axios.get('/getstudentCentre/'+studentInfo[0].student_id,{
                headers: { 'Content-Type': 'application/json'}
             })
              .then((resp)=>{
                   
                   if(resp.data.centData === false){
                        setCentreData(false);
            
                   }else{
                     
                    setCentreData(true);
                   }    
                    
              })
              
         }

            getCentre();
     },[])

      const backtoHistory = () =>{
        setViewSubject(false);
      }

      const linkToCentre = () => {
        history("/student/linking-to-centre/"+studentInfo[0].student_id);  
      }

      const removeFromCentre = () => {
       
                if(!note.trim()){
                      setNoteStatus('Please make sure input field is not empty');
                }else{
                    setUpSub(true)
              axios.put('/centre/removestudent',{
                 sid_cid:studentInfo[0].student_id,
                 note:note,
                  userType:'Parent'
              },{
                headers: { 'Content-Type': 'application/json'}
             })
              .then((response)=>{
                      setTimeout(()=>{
                        setUpSub(false)
                        if(response.data.status=== false){
                          setNoteStatus('Unable to process your request at this moment');
                        }else{
                          setNoteStatus('Your request was sent successfully!');
                          setNote('');
                        }
                      },2000)
                   
              })
            }  


            setTimeout(()=>{
                setNoteStatus(null)
            },5000);
      }

      const handleUpdate = () =>{
            if(!validateInput()){}else{
                      setUpSub(true);
                  axios.put('/update-studentdata',{
                      email:semail,
                      fname:sfirstname,
                      lname:slastname,
                      phone:sphone,
                      location:slocation,
                      gender:sgender,
                      studid:studentInfo[0].student_id
                      
                  },{
                    headers: { 'Content-Type': 'application/json'}
                 })
                  .then((response)=>{
                        setTimeout(()=>{
                          setUpSub(false);
                          if(response.data.respond === 'updated'){
                                setNotify('Your details was updated successfully');
                          }else{
                                setNotify('Unable to update your details at this moment');
                          }

                            setTimeout(()=>{
                                setNotify(null)
                            },3000);
                        },2000)
                        
                  })
            }
      }

    return (
        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Profile</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Learner</li>
                      <li className="breadcrumb-item active">Profile</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}

            { indicator !== false ?
            <div id="preloader-spinner" className="preloader spinner">

              <div className="wrapper">
                  <span className="loader"></span>

                  <p style={{marginTop:120, marginLeft:-20}}>Processing your request. Please wait...</p>
              </div>

          </div>
          :
            null
}

            
            <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="media">
              <div className="me-4">
                <i className="mdi mdi-account-circle text-primary h1"></i>
                </div>
                <div className="media-body align-self-center">
                  <div className="text-muted">
                    <h5>{ studentInfo[0].firstname+' '+studentInfo[0].lastname }</h5>
                    <p className="mb-1"><b>Grade: { studentInfo[0].grade }</b></p>
                    <p className="mb-0">Student ID no: #{ studentInfo[0].student_id }</p>
                        <br></br>
                        
                      { 
                      studentInfo[0].payment_status === 'Pending' ? <div className="alert alert-warning text-center">You have uncleared payment. To make payment  <Link to="/finance/unpaid-invoice">Click Here</Link> </div> :
                      centreData === false ?
                    <button type="button" onClick={()=> linkToCentre()} className="btn btn-primary waves-effect waves-light btn-sm"> 
                 Add Student to Centre</button>
                 :
                 
                 removeStudentStatus === true ?  <button type="button" className="btn btn-primary waves-effect waves-light btn-sm" disabled> 
                 Removing student. Please wait...</button> :
                 <button type="button" className="btn btn-primary waves-effect waves-light btn-sm" onClick={() => removeStudent()}> 
                Remove Student From Centre</button>
}



                  </div>
                  { noteCent === 'Pending' ? <div className="alert alert-info text-center">Your request to link student to centre is under review for approval.</div> : null }

                </div>

               
              
                  <button type="button" onClick={()=> backStudent()} className="btn btn-primary waves-effect waves-light btn-sm"> 
                <i className="mdi mdi-chevron-left" /> Back to All Student</button>
              
              </div>
            </div>
          </div>
        </div>
      </div>


            <div className="row">
              <div className="col-xl-4">
            
                {/* end card */}
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Personal Information</h4>
    
                    <div className="table-responsive">
                      <table className="table table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>{ studentInfo[0].firstname+' '+studentInfo[0].lastname }</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>{ studentInfo[0].phone }</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>{ studentInfo[0].email }</td>
                          </tr>
                          <tr>
                            <th scope="row">Gender :</th>
                            <td>{ studentInfo[0].gender }</td>
                          </tr>
                          <tr>
                            <th scope="row">Location :</th>
                            <td>{ studentInfo[0].home_address }</td>
                          </tr>
                        </tbody>
                      </table>
                      <br></br>
                    
                      <button type="button" className="btn btn-primary waves-effect waves-light btn-sm" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> 
                      <i className="mdi mdi-pencil" /> Edit Information</button>

                <button type="button" style={{float:'right'}} className="btn btn-primary waves-effect waves-light btn-sm" onClick={()=> history("/student/add-student/complete/"+studentInfo[0].student_id)}> 
                <i className="mdi mdi-account" /> Re - Register Student</button>
                     
                     
                    </div> 
                  </div>
                </div>
               
              </div>         
              <div className="col-xl-8">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card mini-stats-wid">
                      <div className="card-body">
                        <div className="media">
                          <div className="media-body">
                            <p className="text-muted fw-medium mb-2">Registration Status</p>
                            <h4 className="mb-0" style={{fontSize:12, textTransform:'uppercase'}}>{ studentInfo[0].registration_status }</h4>
                          </div>
                          <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-check-circle font-size-24" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card mini-stats-wid">
                      <div className="card-body">
                        <div className="media">
                          <div className="media-body">
                            <p className="text-muted fw-medium mb-2">Billing Cycle - Due Date</p>
                            <h4 className="mb-0" style={{fontSize:12}}>{ 
                            checkBill( studentInfo[0].bill_cycle)+' - '
                                    + checkDueDate(studentInfo[0].due_date) }</h4>
                          </div>
                          <div className="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-hourglass font-size-24" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card mini-stats-wid">
                      <div className="card-body">
                        <div className="media">
                          <div className="media-body">
                            <p className="text-muted fw-medium mb-2">Fee Paid</p>
                            <h4 className="mb-0" style={{fontSize:12}}>{ currencyFormat(parseInt(studentInfo[0].money_to_pay)) }</h4>
                          </div>
                          <div className="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="bx bx-package font-size-24" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

               
                <div className="card">
                  <div className="card-body">
                  { subjectStatus === false ? 
                    <h4 className="card-title mb-4">Grade &amp; Subject History</h4>

                    :
                    <div>
                   
                    
                    <button style={{float:'right'}} type="button" onClick={()=>backtoHistory()} className="btn btn-primary waves-effect waves-light btn-sm"><i className="mdi mdi-arrow-left ms-1" /> Back</button>
                    <h4 className="card-title mb-4">List of Subject Offering</h4>
                      </div>
                          
                  }
                    <div className="table-responsive">

                      { subjectStatus === false ? 
                      <table className="table table-nowrap table-hover mb-0">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Grade</th>
                            <th scope="col">No. of Subject Offering</th>
                            <th scope="col">Current Centre</th>
                            <th scope="col">Action</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          { gradeH && gradeH.map(({student_id,grade,no_subject,centre_id,centre_name},index) =>{
                                
                            return(  <tr key={index}>
                            <th scope="row">{ 1+index}</th>
                            <td>{studentInfo[0].grade === grade ? <div>{grade}</div> : grade }</td>
                            <td className="text-center" style={{textTransform:'uppercase'}}>{ no_subject } Subjects</td>
                            <td className="" style={{textTransform:'uppercase'}}> { centre_name}</td>
                            <td>
                            <button type="button" onClick={()=>viewStudentSubject(student_id,grade)} className="btn btn-primary waves-effect waves-light btn-sm">View Details <i className="mdi mdi-arrow-right ms-1" /></button>
                            
                            </td>
                          
                          </tr>
                            )
                          })
                          }
                         
                        </tbody>
                      </table>
                      :
                      <table className="table project-list-table table-nowrap align-middle table-borderless">
                            <thead>
                       <tr>
                         
                         <th scope="col">#</th>
                         
                         <th scope="col">Subject</th>
                         <th scope="col">Subject Code</th>
                         <th scope="col">Subject Price</th>
                         <th scope="col">Status</th>
                       </tr>
                     </thead>
                     <tbody>
                            { subjectData && subjectData.map(({subject, subject_id, price},index)=>{
                                  return(
                                     <tr key={index}>
                                       <td>{ 1+ index }</td>
                                       <td>{subject }</td>
                                       <td>{subject_id }</td>
                                       <td>{ price === '' ? 'R0.00' : currencyFormat(parseInt(price)) }</td>
                                       <td><span className="badge bg-success">Paid</span></td>
                                     </tr>
                                  )
                            })}
                     </tbody>
                      </table>
                      }
                    </div>
                  </div>
                </div>
             
              </div>
            </div>
            {/* end row */}
          </div> {/* container-fluid */}
        </div>
        {/* End Page-content */}

       
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Update Student Information</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            
            <div className="modal-body">
            
                    { notify !== null ? <div className="alert alert-info">{notify }</div> : null }

            <div>
        <div className="row">
          <div className="col-lg-12">
            <div>
              <label className="form-label">First Name</label>
                     
              <input type="text" className="form-control" name="lastname" id="lastname"
                      onChange={ (e)=> setSFirstName(e.target.value) }
                       value={sfirstname}
                       placeholder="Enter First Name" autoComplete="off" />


                <small style={{color:'red'}}>{ sfirstnameError !== null ? sfirstnameError : null }</small>
            </div>
          </div>
       
        </div>
       


            <div className="row" style={{marginTop:20}}>
          <div className="col-lg-12">
            <div>
              <label className="form-label">Last Name</label>
              <input type="text" className="form-control" name="lastname" id="lastname"
                      onChange={ (e)=> setSLastName(e.target.value) }
                       value={slastname}
                       placeholder="Enter Last Name" autoComplete="off" />
                <small style={{color:'red'}}>{ slastnameError !== null ? slastnameError : null }</small>
            </div>
          </div>
       
        </div>
            

        <div className="row" style={{marginTop:20}}>
          <div className="col-lg-12 ">
            <div className="mb-4">
              <label className="form-label">Email</label>
             
              <input type="text" className="form-control" name="EmailAddress" id="email"
                      onChange={ (e)=> setEmail(e.target.value) }
                       value={semail}
                       placeholder="Enter Email Address" autoComplete="off" />
             
              <small style={{color:'red'}}>{ semailError !== null ? semailError : null }</small>
            </div>
          </div>
       
        </div>
      

        <div className="row">
          <div className="col-lg-12 ">
            <div >
              <label className="form-label">Phone</label>

              <input type="text" className="form-control" name="Phone" id="Phone"
                      onChange={ (e)=> setPhone(e.target.value) }
                       value={sphone}
                       placeholder="Enter phone" autoComplete="off" />
               <small style={{color:'red'}}>{ sphoneError !== null ? sphoneError : null }</small>
            </div>
          </div>
       
        </div>


        <div className="row" style={{marginTop:20}}>
          <div className="col-lg-12 " >
            <div >
              <label className="form-label">Gender</label>
             
              <input type="text" className="form-control" name="Phone" id="Phone"
                      onChange={ (e)=> setGender(e.target.value) }
                       value={sgender}
                       placeholder="Enter Gender" autoComplete="off" />
              
              <small style={{color:'red'}}>{ sgenderError !== null ? sgenderError : null }</small>
            </div>
          </div>
       
        </div>
      

        
        <div className="row" style={{marginTop:20}}>
          <div className="col-lg-12 ">
            <div >
              <label className="form-label">Home Address</label>
              
              <input type="text" className="form-control" name="homeAddress" id="HomeAddress"
                      onChange={ (e)=> setLocation(e.target.value) }
                       value={slocation}
                       placeholder="Enter Home Address" autoComplete="off" />
              
              <small style={{color:'red'}}>{ slocationError !== null ? slocationError : null }</small>
            </div>
          </div>
       
        </div>
      
      </div>

           
            </div>
            
            <div className="modal-footer">
             
              <button type="button" className="btn btn-light btn-sm btn-rounded waves-effect waves-light" data-bs-dismiss="modal">
                            Close
                            </button>

                { upsub === false ? 
              <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" onClick={()=>handleUpdate()}>
                            Update Dateils
                            </button>
                      :
              <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" disabled>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Updating...
                            </button>
                    }
            </div>
          
          </div>
        </div>
      </div>


      <div className="modal fade" id="staticNote" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Your details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>

            <div className="modal-body">
                            { noteStatus !== null ? <div className="alert alert-info">{noteStatus}</div> : null}
                 
                                     <div className="mb-3">
                                        <label className="form-label">Reason why you want to remove your child</label>
                                        <div>
                                            <textarea className="form-control" rows="5" 
                                            style={{resize:'none'}} 
                                            onChange={(e)=>setNote(e.target.value)}>{ note }</textarea>
                                        </div>
                                    </div>

            </div>

            <div className="modal-footer">
            { upsub === false ? 
            <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" onClick={()=> removeFromCentre()}>
                            Submit Request
                            </button>
                      :
              <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" disabled>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Requesting...
                            </button>
                    }
              </div>

            </div>
        </div>
      </div>



       <Footer />
      </div>
    )
}

export default StudentProfile
