import React, {useEffect, useState, useRef} from 'react'
import {Link, useParams } from 'react-router-dom'
import axios from 'axios'



let autoComplete;


const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  console.log(addressObject);
}


function UpdateCentre() {
        const { cid } = useParams();
        const [query, setQuery] = useState("");
        const autoCompleteRef = useRef(null);
        const [centreName, setCentreName] = useState("");
        const [centrePhone, setCentrePhone] = useState("");
        const [centreEmail, setCentreEmail] = useState("");
        const [nameError, setNameError] = useState('');
        const [emailError, setEmailError] = useState('');
        const [phoneError, setPhoneError] = useState('');
        const [addressError, setAddressError] = useState('');
        const [loading, setLoading] = useState(false);
        const [notify, setNotify] = useState(null);
        const [permit, setPermit] = useState(null);
        const [upsub, setUpSub] = useState(false);
        const [noteStatus, setNoteStatus] = useState(null);
        const [note, setNote] = useState('');


        const updCentre = () =>{
                axios.get('/centre-update-data/'+cid,{
                  headers: { 'Content-Type': 'application/json'}
               })
                .then((response)=>{
                    
                   setCentreName(response.data.tutorData[0].centreName);
                   setCentrePhone(response.data.tutorData[0].centrePhone);
                   setCentreEmail(response.data.tutorData[0].centreEmail);
                   setQuery(response.data.tutorData[0].centreAddress);
                   setPermit(response.data.tutorData[0].permit);
    
                });
        }

        const checkError = () =>{
              let valid = true;
              if(!centreName.trim()){
                    setNameError('Please provide centre name');
                    valid = false
              }else
              {
                  setNameError('');
                    valid = true;
              }
              

              if (!centreEmail.trim()) {
                  setEmailError('Please enter your email address');
                  valid = false;
                    
              }else if (!/\S+@\S+\.\S+/.test(centreEmail)) {
                setEmailError('Please provide a valid email address');
                valid = false;
                 
              }else{
                 setEmailError('');
                 valid = true;
              }

              if (!centrePhone.trim()) {
                  setPhoneError('Please provide your phone number');
                  valid = false;
                  
              }else
              {
                  setPhoneError('')
                  valid = true;
              }

              if (!query.trim()) {
                  setAddressError('Please provide your centre address');
                  valid = false
                  
              }else
              {
                valid = true;
                 setAddressError('');
              }

               return valid;
        }


          const updateCentreDetails = () => {
                    if(!note.trim()){
                         setNoteStatus("Please provide your reason");
                    }else
                    {
                        if(note.length > 15){
                            setNoteStatus(null);
                                setUpSub(true)
                              axios.post('/request-to-change',{
                                   note:note,
                                   cid:cid,
                                   centreName:centreName
                              },{
                                headers: { 'Content-Type': 'application/json'}
                             }).then((response) =>{
                                     setTimeout(()=>{
                                        setUpSub(false)
                                        if(response.data.report === 'success'){
                                            setNoteStatus("Your request was submitted successfully");
                                        }else
                                        {
                                            setNoteStatus("We are unable to process your request at this moment");
                                        }
                                     },2000)
                                  
                              })

                        }else
                        {
                            setNoteStatus("Your reason is to short, kindly provide more reason");
                        }
                    }
          }


           const handleSubmit = (e) =>{

              e.preventDefault();
                  if(!checkError()){}else{

                  axios.put('/update-centre',{
                      centreName:centreName,
                      centreEmail:centreEmail,
                      centrePhone:centrePhone,
                      centreAddress: query,
                      cid:cid

                  },{
                    headers: { 'Content-Type': 'application/json'}
                 })
                  .then((response)=>{
                              setLoading(true)
                         setTimeout(()=>{
                               setLoading(false);
                            if (response.data.reportCent === 'success') {
                                  setNotify('Your centre was updated successfully')
                            }else
                            {
                              setNotify('Unable to process your request');
                            }

                         },2000);
                  })
                }
           }
       

        useEffect(() => {
          loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
          );

          updCentre();
        }, []);
    return (
        <div>
           
         
           <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Update Centre</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item"><Link to="#">Centre</Link></li>
                      <li className="breadcrumb-item active">Update Centre</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>


            <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title" id="title">Update Centre Information </h5>
                  <p className="card-title-desc">Provide new centre information details</p>
                   
                  { permit === 2 ? null : <div className="alert alert-warning">To update your centre information, kindly send a request. <button type="button" className="btn btn-primary waves-effect waves-light btn-sm" data-bs-toggle="modal" data-bs-target="#staticNote"> Request to update centre </button> </div> }
                 
                 { notify !== null ? (<div className="alert alert-info">{ notify }</div>) : null }
                  <form onSubmit={handleSubmit} noValidate autoComplete='off'>
                   
                      <div className="form-floating mb-3">
                        <input type="text" className="form-control" name="centreName" 
                        id="centreName" placeholder="Enter Your Full Names" 
                        value={centreName} onChange={(e)=> setCentreName(e.target.value)} />
                        <label htmlFor="floatingemailInput">Centre Name</label>
                        { nameError !=='' ? <p style={{color:'red'}}>{ nameError }</p> : null }
                      
                      </div>
                    
                    
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="email" className="form-control"
                           name="centreEmail" id="email" placeholder="Enter Email address" value={centreEmail} onChange={(e)=> setCentreEmail(e.target.value)}  />
                          <label htmlFor="floatingemailInput">Centre Email address</label>
                          { emailError !=='' ? <p style={{color:'red'}}>{ emailError }</p> : null }
                         
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="number" className="form-control" name="centrePhone"
                           id="phone" placeholder="Enter Phone Number" value={centrePhone} onChange={(e)=> setCentrePhone(e.target.value)} />
                          <label htmlFor="floatingemailInput">Centre Phone Number</label>
                          { phoneError !=='' ? <p style={{color:'red'}}>{ phoneError }</p> : null }
                        </div>
                      </div>
                    </div>
                   
                   
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" name="centreAddress" id="address"
                      ref={autoCompleteRef}
                      onChange={ (e)=> setQuery(e.target.value) }
                       value={query}
                       placeholder="Enter Home Address" autoComplete="off" />
                      <label htmlFor="floatingnameInput">Centre Address</label>
                      { addressError !=='' ? <p style={{color:'red'}}>{ addressError }</p> : null }

                     
                    </div>

                    
                  
                   { permit === 1 ? null : 
                    <div style={{float: 'right'}}>

                  
                  { loading === false ?
                    <button type="submit" className="btn btn-primary w-md submit">Submit</button>
                        :
                    <button className="btn btn-primary waves-effect waves-light" disabled>

                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      Please Wait...</button>
                      }


                 </div>
                            }
                 
                  </form>


      <div className="modal fade" id="staticNote" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Your details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>

            <div className="modal-body">
                            { noteStatus !== null ? <div className="alert alert-info">{noteStatus}</div> : null}
                 
                                     <div className="mb-3">
                                        <label className="form-label">Reason why you want to change your centre information</label>
                                        <div>
                                            <textarea className="form-control" rows="5" 
                                            style={{resize:'none'}} 
                                            onChange={(e)=>setNote(e.target.value)}>{ note }</textarea>
                                        </div>
                                    </div>

            </div>

            <div className="modal-footer">
            { upsub === false ? 
            <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" onClick={()=> updateCentreDetails()}>
                            Submit Request
                            </button>
                      :
              <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" disabled>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Requesting...
                            </button>
                    }
              </div>

            </div>
        </div>
      </div>




                </div>
              </div>
            </div>
          </div>
        
                        </div>
                        </div>
                        </div>
            
        </div>
   
   )
}

export default UpdateCentre
