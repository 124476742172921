import React, {useState, useEffect } from 'react'
import Footer from '../../Nav/Footer'
import axios from 'axios';
import download from 'downloadjs';

function Support() {
    axios.defaults.withCredentials = true;
    const [supportdata, setSupportData] = useState();
    const [yeardata, setYearData] = useState();
    const [loaddata, setLoadData] = useState(false);
    const [checkData, setCheckData] = useState(false);
    const [datamessage, setDataMessage] = useState(null);
    const [selectcategory, setSelectCategory] = useState();
    const [loaddisplay, setLoadDisplay] = useState(true);
    const [detailsmessage, setDetailMessage] = useState(null);
    const [loadfiles, setLoadFiles] = useState();

    const handleYearList = (yearid,year) =>{
          setSelectCategory(year);
          setLoadDisplay(true)
         axios.get("/support-files/"+yearid,{
          headers: { 'Content-Type': 'application/json'}
       })
         .then((response)=>{
                    setCheckData(true);
                if(response.data.supportData === false){
                      setDataMessage(null);
                     setDataMessage("Oops no support data available ");

                }else
                {
                        setDataMessage(true)
                        setSupportData(response.data.supportData)

                }

         })
       
    }

    const handleDisplayData = (id) =>{
          axios.get("/support-details/"+id,{
            headers: { 'Content-Type': 'application/json'}
         })
          .then((response)=>{
                  if(response.data.dataResult === false){
                          setDetailMessage("Oops this folder is empty. Kindly check back later");
                  }else
                  {
                       setDetailMessage(null);
                        setLoadFiles(response.data.dataResult);

                  }
          })
            setLoadDisplay(false)
    }


    const downloadDoc = (details,id) => {
       
      axios.get("/download-support/"+id,{
     responseType: 'blob'
   }).then((response)=>{
     const content = response.headers['content-type'];
     download(response.data, details+'.pdf', content)   
   })
}



     useEffect(()=> {
          axios.get("/support-data",{
            headers: { 'Content-Type': 'application/json'}
         })
          .then((response)=>{
                    setYearData(response.data.result);
            
                    if(response.data.status === true){
                          setLoadData(true)
                    }else
                    {
                        setLoadData(false)
                    }
                  
               
                
          }) 

     },[])
    
    return (
      
            <div id="layout-wrapper">

<div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Support</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Facilitator</li>
                      <li className="breadcrumb-item active">Support</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
           
            <div className="d-xl-flex">
              <div className="w-100">
                <div className="d-md-flex">
                  <div className="card filemanager-sidebar me-md-2">
                    <div className="card-body">
                      <div className="d-flex flex-column h-100">
                        <div className="mb-4">
                        
                            <div className="text-center">
                         <h5 className="font-size-15 mb-4">Year Category</h5>
                            </div>
                           
                            {loaddata === false ? (<p>Data not available</p>) : 
                          <ul className="list-unstyled categories-list">
                                    
                                  
                                   { yeardata.map(list =>(
                                        <li key={list.id} >
                                        <div className="text-body d-flex align-items-center" style={{cursor:'pointer'}} onClick={ () => handleYearList(list.year_id,list.year)}>
                                        <i className="mdi mdi-calendar text-success font-size-16 me-2" /> <span className="me-auto"> { list.year } Support Data</span>
                                        </div>
                                        </li>
                                   ))}
                                  
                                   
                                         
                            
                          </ul>
                            }
                        </div>
                      
                      </div>
                    </div>
                  </div>
                  {/* filemanager-leftsidebar */}
                  <div className="w-100">
                    <div className="card">
                      <div className="card-body">
                        <div>
                          <div className="row mb-3">
                            <div className="col-xl-3 col-sm-6">
                              <div className="mt-2">
                                <h5> Support Data { selectcategory  }</h5>
                              </div>
                            </div>
                            <div className="col-xl-9 col-sm-6">
                            
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="row">

                              { 
                              
                                loaddisplay !== true ? 
                                
                                   detailsmessage !== null ? (<div className="alert alert-info">{ detailsmessage }</div>) :  

                                   
                                <div className="mt-10">
                              
                                <div className="table-responsive">
                                  <table className="table align-middle table-nowrap table-hover mb-0">
                                    <thead>
                                      
                                        <tr>
                                          <th></th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Date modified</th>
                                        <th scope="col" colSpan={2}>Size</th>
                                      </tr>
   
                                    </thead>
                                    <tbody>
                                      { loadfiles && loadfiles.map(files =>(
                                         
                                      <tr style={{cursor:'pointer'}} key={files.id}>
                                         <td style={{width: '45'}}>
                                                            <div className="avatar-sm">
                                                                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                                                                    <i className="bx bxs-file-pdf"></i>
                                                                </span>
                                                            </div>
                                                        </td>
                                        <td><div className="text-dark fw-medium">
                                      
                                          { files.details}</div>
                                          </td>
                                        <td>{ files.date_modify }</td>
                                        <td>{ files.filesize }</td>
                                        <td>
                                        <td>
                                                            <div className="text-center" onClick={()=>downloadDoc(files.details,files.id)}>
                                                                <span className="text-dark"><i className="bx bx-download h3 m-0"></i></span>
                                                            </div>
                                                        </td>
                                        </td>
                                       
                                      </tr>
                                        ))}
                                    
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                                
                                
                                :
                              
                              checkData === false ? (<p style={{textAlign:'center'}}>Select year category on the left</p>) : 
                                  
                                   datamessage !== true ?
                                  (<div className="alert alert-info"> {datamessage}</div>) :
                                 supportdata && supportdata.map(data =>(

                                    <div className="col-xl-4 col-sm-6" style={{cursor:'pointer'}} key={data.id} onClick={()=> handleDisplayData(data.id)}>
                                    <div className="card shadow-none border">
                                      <div className="card-body p-3">
                                        <div>
                                
                                          <div className="avatar-xs me-3 mb-3">
                                            <div className="avatar-title bg-transparent rounded">
                                              <i className="bx bxs-folder font-size-24 text-warning" />
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            <div className="overflow-hidden me-auto">
                                              <h5 className="font-size-14 text-truncate mb-1"><div className="text-body">{ data.name }</div></h5>
                                              <p className="text-muted text-truncate mb-0"></p>
                                            </div>
                                            <div className="align-self-end ms-2">
                                              <p className="text-muted mb-0">{ data.numdata } Files</p>
                                            </div>
                                          </div>
                                        
                                        
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  ))
                             
                            
                           

                              }
                           
                            {/* end col */}
                          </div>
                          {/* end row */}
                        </div>

                    
                      
                      </div>
                    </div>
                    {/* end card */}
                  </div>
                  {/* end w-100 */}
                </div>
              </div>
            
            </div>
            {/* end row */}
          </div> {/* container-fluid */}
        </div>
        {/* End Page-content */}
       <Footer />
      </div>

            </div>

     
    )
}

export default Support
