import React from 'react';
import CompleteParentReg from './CompleteParentReg';
import CompleteTutorReg from './CompleteTutorReg';




const CompleteReg = ({companyName,myOption,myuserid}) => {
    
       if(myOption === 'Parent'){
 
          return(
            <CompleteParentReg companyName={companyName} myOption={myOption} myuserid={myuserid} />
          )

  }else
  {

     return(
        <CompleteTutorReg companyName={companyName} myOption={myOption} myuserid={myuserid} />
     )

  }
    
}

export default CompleteReg
