import React, {useState } from 'react'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Axios from 'axios';
import CompleteReg from './CompleteReg';
import ConfirmUserType from './ConfirmUserType'

const Register = () => {
    const siteName = 'FastExcellence.com';
    const checkUserOptionApi ="/verify/updateusertype";
    Axios.defaults.withCredentials = true;
    const [notifyuser, setNotifyUser] = useState('');
    const [userid, setUserId] = useState();
    const [useroption, setUserOption] = useState('');
    const [userstatus, setUserStatus] = useState('');


    const schema = Yup.object().shape({
      email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
      password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .max(8,'Password must not be more than 8 characters long')
      .required('Password is required'),
      confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),

    });

    const formOption = { resolver: yupResolver(schema)};

    const {register, handleSubmit, formState } = useForm(formOption);
    const {errors} = formState;

      const submitForm = (data) => {
         const api_reg = "/authenticate/register";
          Axios.post(api_reg,
            data,{
              headers: { 'Content-Type': 'application/json'}
           })
            .then((response) => {
               if(response.data.message){
                  
                  setUserStatus(response.data.message);
               }else
               {
                
                      setNotifyUser('Start');
                      setUserId(response.data.muserid);
                      setUserStatus(response.data.muserid);
                    
               }

            })
            
      }

      const parentAccount = () =>{
        const userType = 'Parent';
         Axios.put(checkUserOptionApi,
           {userid:userid,
             userType:userType},{
              headers: { 'Content-Type': 'application/json'}
           })
             .then((response)=>{
                   if(response.data.utype){

                   }
                   else{
                      const mstatus = "Pending";
                      const muser = "Parent";
                      setNotifyUser(mstatus);
                      setUserOption(muser);
                   }
             })

   }

   const tutorAccount = () =>{
     const userType = 'Tutor';
     Axios.put(checkUserOptionApi,
       {userid:userid,
         userType:userType},{
          headers: { 'Content-Type': 'application/json'}
       })
         .then((response)=>{
               if(response.data.utype){

               }
               else{
                 const mstatus = "Pending";
                 const muser = "Tutor";
                 setNotifyUser(mstatus);
                 setUserOption(muser);
               }
         })
   }


         if(notifyuser === 'Start'){

          return (
            <div>
              <ConfirmUserType siteName={siteName} handleParent={parentAccount} handleTutor={tutorAccount} />
            </div>
         )

         }else
         if(notifyuser === 'Pending'){

          return (
            <div>
             <CompleteReg companyName={siteName} myOption={useroption} myuserid={userid} />
            </div>
           )

         }else
         {
    return (
        <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card overflow-hidden">
                <div className="bg-primary bg-soft">
                  <div className="row">
                    <div className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Register</h5>
                        <p>Create FastExcellence Account</p>
                      </div>
                    </div>
                    <div className="col-5 align-self-end">
                    <img src="autheme/assets/images/eq4.png" alt="FastExcellence" className="img-fluid" style={{marginTop:-50, marginLeft:-20}} />
               
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0"> 
                  <div>
                    <Link to="/register">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                        <img src="autheme/assets/images/eq1.png" alt="" className="rounded-circle" height={34} />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                   
                     { userstatus !== '' ? ( <div className="alert alert-danger">{ userstatus } </div> ) : (<div></div>) }
                    <form className="needs-validation" noValidate onSubmit={ handleSubmit(submitForm) }>
                 
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">Enter Email</label>
                        <input type="email" name="email" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} 
                         id="useremail" placeholder=""  required /> 
                         <div className="invalid-feedback">{errors.email?.message}</div>
                         
                      </div>

                      <div className="mb-3">
                        <label htmlFor="userpassword" className="form-label">Enter Password</label>
                        <input type="password" name="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} id="userpassword"
                         placeholder=""   required />
                         <div className="invalid-feedback">{errors.password?.message}</div>
                             
                      </div>

                      <div className="mb-3">
                        <label htmlFor="userpassword" className="form-label">Confirm Password</label>
                        <input type="password" name="" {...register('confirmPassword')} className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                         id="userpassword" placeholder="Confirm password"    required />
                          <div className="invalid-feedback">{errors.confirmPassword?.message}</div>

                      </div>

                      <div className="mt-4 d-grid">
                        <button className="btn btn-primary waves-effect waves-light" type="submit">Register</button>
                      </div>
                     
                      <div className="mt-4 text-center">
                        <p className="mb-0">By registering you agree to the { siteName } <a rel="noopener noreferrer" href="https://fastexcellence.co.za/terms_of_use" className="text-primary">Terms of Use</a></p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <div>
                  <p>Already have an account ? <Link to="/login" className="fw-medium text-primary"> Login</Link> </p>
                  <p>©  { siteName } </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
    }


}

export default Register
