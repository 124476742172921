import React, {useState, useEffect} from 'react'
import { useNavigate} from 'react-router-dom'
import { useForm } from 'react-hook-form';
import Footer from '../../Nav/Footer';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Axios from 'axios';



function AddStudent() {
  Axios.defaults.withCredentials = true;
  const AddstudentApi = "/addstudent";

  const [checkstatus, setCheckStatus] = useState(false);
  const [isLoad, setisLoad] = useState(false);
  const [notifyuser, setNotifyUSer] = useState('');
  const [mregion, setMRegion] = useState(null);
  const [loadRegion, setLoadRegion] = useState();
  const history = useNavigate();

  const schema = Yup.object().shape({
       firstname: Yup.string()
       .required('First name is required'),
       lastname: Yup.string().required('Last name is required'),
       email: Yup.string()
       .required('Email is required')
       .email('Email is invalid'),
       phone: Yup.string().required('Phone number is required'),
       id_passport: Yup.string().required('ID/Passport is required'),
       student_race: Yup.string().required('Student race is required'),
       gender: Yup.string().required('Gender is required'),
       home_lang: Yup.string().required('Home language is required'),
       other_lang: Yup.string().required('Other languages is required'),
       dob: Yup.string().required('Date of birth required'),
       age: Yup.string().required('Age is required'),
       applying_year: Yup.string().required('Applying year is required'),
       lang_intruct: Yup.string().required('Language instructor is required'),
       home_address: Yup.string().required('Home address required'),
       student_region: Yup.string().required('Please select your region'),
     
  })
  const formOptions = { resolver: yupResolver(schema) };

  const {register, handleSubmit, formState } = useForm(formOptions);
  const {errors} = formState;

  const submitForm = (data) =>{
          setisLoad(true)
        Axios.post(AddstudentApi,data,{
          headers: { 'Content-Type': 'application/json'}
       })
        .then((response)=>{
              setTimeout(()=>{
                setisLoad(false) 
                if(response.data.validdata === true){
                  setNotifyUSer('');
                  
                  setCheckStatus(true);
                  history("/student/add-student/complete/"+response.data.studID);
                  
            }else
            {
                    setNotifyUSer(response.data.message)
            }

              },2000);
             
        })
  }

  const fetchRegion = () =>{
    Axios.get('/all-region',{
      headers: { 'Content-Type': 'application/json'}
   })
    .then((response)=>{
         if(response.data.allRegion === false){
            setMRegion(<option disabled>No data available</option>)
         }else{
           
          setLoadRegion(response.data.allRegion)
         }
    })
} 

    useEffect(()=>{
      let min = new Date().getFullYear(),
      max = 2040,
     select = document.getElementById('year');
 
 for (var i = min; i<=max; i++){
     var opt = document.createElement('option');
     opt.value = i;
     opt.innerHTML = i;
     select.appendChild(opt);
 }
 
 select.value = new Date().getFullYear();

            fetchRegion();
    },[])
  
  
    return (
        <div>
         

          <div id="layout-wrapper">
      <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
        
          
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title" id="title">Student Information </h5>
                  <p className="card-title-desc">Provide all details to complete your registration</p>

                  { notifyuser === '' ? (<div></div>) : (<div className="alert alert-info">{ notifyuser} </div>)}
                  <form onSubmit={handleSubmit(submitForm)}>
                  
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <input type="text" name="firstname" id="firstname" placeholder="Enter Your First Name" {...register('firstname')} className={`form-control ${errors.firstname ? 'is-invalid' : ''}`}  />
                        <label htmlFor="floatingemailInput">First Name</label>
                        <div className="invalid-feedback">{errors.firstname?.message}</div>
                        
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <input type="text" name="lastname" id="lastname" placeholder="Enter Last Name"  {...register('lastname')} className={`form-control ${errors.lastname ? 'is-invalid' : ''}`}  />
                        <label htmlFor="floatingemailInput">Last Name</label>
                        <div className="invalid-feedback">{errors.lastname?.message}</div>
                      </div>
                    </div>
                  </div>
  
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="email" name="email" id="email" placeholder="Enter Email address"   {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                          <label htmlFor="floatingemailInput">Email address</label>
                          <div className="invalid-feedback">{errors.email?.message}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="text" name="phone" id="phone" placeholder="Enter Phone Number"  {...register('phone')} className={`form-control ${errors.phone ? 'is-invalid' : ''}`} />
                          <label htmlFor="floatingemailInput">Phone Number</label>
                          <div className="invalid-feedback">{errors.phone?.message}</div>
                        </div>
                      </div>
                    </div>

                    <div className="form-floating mb-3">
                      <input type="text" name="id_passport" id="id_passport" placeholder="Enter passport or ID number" {...register('id_passport')} className={`form-control ${errors.id_passport ? 'is-invalid' : ''}`} />
                      <label htmlFor="floatingnameInput">Student ID/Passport No</label>
                      <div className="invalid-feedback">{errors.id_passport?.message}</div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                      <div className="form-floating mb-3">
                          <input type="text"  name="home_address" id="phone" placeholder="Enter home address" {...register('home_address')} className={`form-control ${errors.home_address ? 'is-invalid' : ''}`}  />
                          <label htmlFor="floatingemailInput"> Address</label>
                          <div className="invalid-feedback">{errors.home_address?.message}</div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <select name="student_region" id="floatingSelectGrid" aria-label="Floating label select example" {...register('student_region')} className={`form-select ${errors.student_region ? 'is-invalid' : ''}`}>
                          <option>Select Region</option>
                    { mregion !== null ? mregion :
                        loadRegion && loadRegion.map(yu =>(
                          <option key={yu.id} defaultValue={yu.location}>{ yu.location }</option>
                        ))
                    }
                           </select>
                         
                          <label htmlFor="floatingemailInput">Student Region</label>
                          <div className="invalid-feedback">{errors.student_region?.message}</div>
                        </div>
                      </div>


                    </div>


                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <select name="student_race" id="floatingSelectGrid" aria-label="Floating label select example" {...register('student_race')} className={`form-select ${errors.student_race ? 'is-invalid' : ''}`}>
                              <option value="">Select Race</option>
                              <option value="Black African">Black African	</option>
                              <option value="Colored">Colored</option>
                              <option value="White">White</option>
                              <option value="Indian/Asian">Indian/Asian</option>
                           </select>
                         
                          <label htmlFor="floatingemailInput">Student Race</label>
                          <div className="invalid-feedback">{errors.student_race?.message}</div>
                        </div>
                      </div>


                           <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <select name="gender" id="floatingSelectGrid" aria-label="Floating label select example" {...register('gender')} className={`form-select ${errors.gender ? 'is-invalid' : ''}`} >
                              <option value="">Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                            <label htmlFor="floatingSelectGrid">Gender</label>
                            <div className="invalid-feedback">{errors.gender?.message}</div>
                          </div>
                        </div>

                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="date" name="dob" id="example-date-input" placeholder="Enter Date of Birth"  {...register('dob')} className={`form-control ${errors.dob ? 'is-invalid' : ''}`} />
                          <label htmlFor="floatingemailInput">Date of Birth</label>
                          <div className="invalid-feedback">{errors.dob?.message}</div>
                        
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="text" name="age" id="age" placeholder="Enter Student Age" {...register('age')} className={`form-control ${errors.age ? 'is-invalid' : ''}`} />
                          <label htmlFor="floatingemailInput">Student Age</label>
                          <div className="invalid-feedback">{errors.age?.message}</div>
                        </div>
                      </div>

                    </div>



                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="text" name="home_lang" id="home_lang" placeholder="Enter Home Language"  {...register('home_lang')} className={`form-control ${errors.home_lang ? 'is-invalid' : ''}`} />
                          <label htmlFor="floatingemailInput">Home Language</label>
                          <div className="invalid-feedback">{errors.home_lang?.message}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input type="text" name="other_lang" id="other_lang" placeholder="Enter Other Languages" {...register('other_lang')} className={`form-control ${errors.other_lang ? 'is-invalid' : ''}`} />
                          <label htmlFor="floatingemailInput">Other Languages</label>
                          <div className="invalid-feedback">{errors.other_lang?.message}</div>
                        </div>
                      </div>
                    </div>
                   


                    <div className="row">

                        <div className="col-md-6">
                        <div className="form-floating mb-3">
                        <select name="lang_intruct" id="lang_intruct" aria-label="Floating label select example" {...register('lang_intruct')} className={`form-select ${errors.lang_intruct ? 'is-invalid' : ''}`}>
                              <option value="">Select Language</option>
                              <option value="Afrikaans">Afrikaans</option>
                              <option value="English">English</option>
                            
                            </select>

                      <label htmlFor="floatingnameInput">Language of Instruction</label>
                      <div className="invalid-feedback">{errors.lang_intruct?.message}</div>
                      
                    </div>
                      </div>


                      <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <select name="applying_year" id="year" aria-label="Floating label select example" {...register('applying_year')} className={`form-select ${errors.applying_year ? 'is-invalid' : ''}`}>
                              <option value="">Select Academic Year</option>
                            
                            </select>
                            <label htmlFor="floatingSelectGrid">Year Applying For</label>
                            <div className="invalid-feedback">{errors.applying_year?.message}</div>
                          </div>
                        </div>

                    </div>
  
                   
                   
                   

                   

                    <div style={{float: 'right'}}>
                        { isLoad !== false ?
                         <button className="btn btn-primary waves-effect waves-light" disabled>

                         <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                           Please Wait...</button>
                           :
                      <button type="submit" className="btn btn-primary w-md submit">Continue</button>

                        }
                
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
           
          
        
        </div>
        </div>
       <Footer />
      </div>
    </div>
     
     

        </div>
    )
}

export default AddStudent
