import './App.css';
import React, { useState, useEffect } from 'react';
import Login from './component/Authent/Login';
import Register from "./component/Authent/Register";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Forgot from './component/Authent/Forgot';
import Term from './component/Layout/Term';
import ProtectRoute from './component/Protectpage'
import ParentDashboard from './component/parent/Pdashboard'
import TutorDashboard from './component/teacher/Tdashboard'
import ErrorPage from './component/Authent/ErrorPage'
import axios from 'axios';
import AddStudent from './component/parent/student/AddStudent'
import AllStudent from './component/parent/student/AllStudent'
import NavHead from './component/Nav/NavHead'
import StudentDetails from './component/parent/student/StudentDetails'
import PaymentStatus from './component/parent/student/PaymentStatus'
import Invoice from './component/parent/student/Invoice'
import Support from './component/parent/student/Support'
import AssessmentSummary from './component/parent/student/AssessmentSummary';

import TermFinal from './component/parent/student/Termfinal'
import Paid from './component/Finance/Paid'
import Unpaid from './component/Finance/Unpaid'
import Footer from './component/Nav/Footer';
import TutorSupport from './component/teacher/TutorSupport'

import ViewAssessment from './component/teacher/ViewAssessment';

import OrderManagment from './component/teacher/OrderManagment';
import AddCentre from './component/teacher/AddCentre';
import AllCentre from './component/teacher/AllCentre';
import Termfinal from './component/teacher/Termsfinal';
import CentreData from './component/teacher/CentreData';
import Wallet from './component/parent/Wallet';

import ErrorParent from './component/Authent/ErrorParent';
import OrderManagement from './component/parent/OrderManagement';
import CentresLinking from './component/Authent/CentresLinking';
import TutorWallet from './component/teacher/Wallet';
import Notification from './component/parent/Notification';
import Profile from './component/parent/Profile';
import Settings from './component/parent/Settings';
import TutorNotification from './component/teacher/TutorNotification';
import UpdateCentre from './component/teacher/UpdateCentre';
import TutorSettings from './component/teacher/TutorSettings';
import NavTutor from './component/Nav/NavTutor';
import NotificationData from './component/parent/NotificationData';

import InvigilatorDownload from './component/teacher/academic/InvigilatorDownload';
import AssesmentCapture from './component/teacher/academic/AssesmentCapture';
import AssesmentUpload from './component/teacher/academic/AssesmentUpload';

import AssessUpload from './component/parent/academic/AssessUpload'
import AssessCap from './component/parent/academic/AssessCap'
import InvigiDownload from './component/parent/academic/InvigiDownload'
const localServer = 'http://localhost:3001/';
const server_url = "https://equationserver.com";
axios.defaults.baseURL = server_url;
axios.defaults.withCredentials = true;

function App() {
   
  
   const [userAuth, setUserAuth] = useState(false);



     const authUserLog = (status) =>{

            setUserAuth(status);
     }
       
      useEffect(()=>{
         if(navigator.geolocation){
            navigator.geolocation.watchPosition( (position) => {

                localStorage.setItem('Parent_longi',position.coords.longitude)
                localStorage.setItem('Parent_lat',position.coords.latitude)

            })
        }else
        {
            console.log('Browser does not support location');
        }
            
         const AuthUser = "/checklogin/status";
        
         const checkLog = async () =>{
            axios.get(AuthUser,{
               headers: { 'Content-Type': 'application/json'},
               withCredentials: true
            }).then((response)=>{
                
                  switch(response.data.loggedIn){
                     case 'allow':
                        setUserAuth(true);
                        break;
                        case 'not allow':
                           setUserAuth(false);
                           break;
                           default:
                         setUserAuth(true); 
                 }
               
        }).catch(err=>{
          console.log(err)
        })
        
         }

           checkLog();

           
            
      },[userAuth])

   

         switch(userAuth){
                 case true:    
                  if(localStorage.getItem("userType") === "Tutor"){

                     return (
                        <div className="App">
                          <Router>
      <NavTutor checkLogin={authUserLog} />
      <Routes>
        <Route element={<ProtectRoute isAuth={userAuth} />}>
          <Route path="/" element={<TutorDashboard />} />
          <Route path="/tutordashboard" element={<TutorDashboard />} />
          <Route path="/centre/support" element={<TutorSupport />} />
          <Route path="/notification/:nid" element={<NotificationData />} />
          <Route path="/invigilator-download/:id" element={<InvigilatorDownload />} />
          <Route path="/assessment-summary" element={<ViewAssessment />} />
          <Route path="/assessment-capture/:id" element={<AssesmentCapture />} />
          <Route path="/upload-assessment/:id" element={<AssesmentUpload />} />
          <Route path="/order-management" element={<OrderManagment />} />
          <Route path="/centre/add-centre" element={<AddCentre />} />
          <Route path="/centre/all-centre" element={<AllCentre />} />
          <Route path="/academics/term-final" element={<Termfinal />} />
          <Route path="/centre/center-details/:id" element={<CentreData />} />
          <Route path="/my-wallet" element={<TutorWallet />} />
          <Route path="/notification" element={<TutorNotification />} />
          <Route path="/centre/update-centre-details/:cid" element={<UpdateCentre />} />
          <Route path="/tutor-settings" element={<TutorSettings />} />
          <Route path="*" element={<ErrorParent checkLogin={userAuth} />} />
        </Route>
      </Routes>
      <Footer />
    </Router>
                        </div>
                      )
         
                    }else
                    if(localStorage.getItem("userType") === "Parent"){
         
                     return (
                        <div className="App">
                       
                            <Router>
      <NavHead checkLogin={authUserLog} />
      <Routes>
        <Route element={<ProtectRoute isAuth={userAuth} />}>
          <Route path="/" element={<ParentDashboard />} />
          <Route path="/parentdashoard" element={<ParentDashboard />} />
          <Route path="/student/add-student" element={<AddStudent />} />
          <Route path="/student/all-student" element={<AllStudent />} />
          <Route path="/student/all-student/:id" element={<StudentDetails />} />
          <Route path="/student/add-student/complete/:id" element={<PaymentStatus />} />
          <Route path="/student/all-student/complete/invoice/:id" element={<Invoice />} />
          <Route path="/facilitator-support" element={<Support />} />
          <Route path="/notification/:nid" element={<NotificationData />} />
          <Route path="/invigilator-download/:id" element={<InvigiDownload />} />
          <Route path="/assessment-capture/:id" element={<AssessCap />} />
          <Route path="/upload-assessment/:id" element={<AssessUpload />} />
          <Route path="/assessment-summary" element={<AssessmentSummary />} />
          <Route path="/academics/term-final" element={<TermFinal />} />
          <Route path="/finance/paid-invoice" element={<Paid />} />
          <Route path="/finance/unpaid-invoice" element={<Unpaid />} />
          <Route path="/mywallet" element={<Wallet />} />
          <Route path="/order-management" element={<OrderManagement />} />
          <Route path="/student/linking-to-centre/:id" element={<CentresLinking />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/user-profile" element={<Profile />} />
          <Route path="/user-settings" element={<Settings />} />
        </Route>
        <Route path="*" element={<ErrorParent checkLogin={userAuth} />} />
      </Routes>
    </Router>
                        </div>
                      );  
                    }

                    break;
                  case false:

                     return (
                        <div className="App">
                          <Router>
      <Routes>
        <Route path="/" element={<Login checkLogin={authUserLog} />} />
        <Route path="/login" element={<Login checkLogin={authUserLog} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/term" element={<Term />} />
        <Route path="*" element={<ErrorPage checkLogin={userAuth} />} />
      </Routes>
    </Router>
                        </div>
                      );
                     break;
                     default:
                        return (
                           <div className="App">
                            <Router>
                           <Routes>
                           <Route path="/" element={<Login checkLogin={authUserLog} />} />
                           <Route path="/login" element={<Login checkLogin={authUserLog} />} />
                           <Route path="/register" element={<Register />} />
                           <Route path="/forgot" element={<Forgot />} />
                           <Route path="/term" element={<Term />} />
                           <Route path="*" element={<ErrorPage checkLogin={userAuth} />} />
                           </Routes>
                        </Router>
                           </div>
                         );
   
         }

   
}

export default App;
