import React, {useState} from "react";

function ProcessResult({viewResult,Terms}){

    const checkTerms = () =>{
        if(Terms.t1 !== '' && Terms.t2==='' && Terms.t3==='' && Terms.t4==='' && Terms.cfinal===''){
            return    <table className="table align-middle table-nowrap mb-0">
             
            { viewResult.map((subject, index) => (
               <React.Fragment key={index}>
                 <thead>
                   <tr className="table-dark">
                     <th colSpan="5">{subject.subject}</th>
                   </tr>
                   <tr className="table-primary">
                     <th>Assessment Area</th>
                     <th>Score Rating</th>
                     <th>Percentage</th>
                     <th>Symbol</th>
                   </tr>
                 </thead>
                 <tbody>
                   {subject.assessments.map((assessment, i) => (
                     <tr key={i}>
                       <td>{assessment.category}</td>
                       <td>{assessment.term1_score}</td>
                       <td>{assessment.percentage}</td>
                       <td>{assessment.symbol}</td>
                     </tr>
                   ))}
                 </tbody>
               </React.Fragment>
             ))}
           </table>
 
        }else
        if(Terms.t1 !== '' && Terms.t2 !=='' && Terms.t3==='' && Terms.t4==='' && Terms.cfinal===''){
       
          return (
            <table className="table table-bordered align-middle table-nowrap mb-0">
              <thead>
                <tr className="table-dark">
                  <th className="align-middle">Subject</th>
                  <th className="align-middle">Question</th>
                  <th className="align-middle">Term 1 Score Rating</th>
                  <th className="align-middle">Term 2 Score Rating</th>
                </tr>
              </thead>
              <tbody>
                {viewResult.map((row, rowIndex) => (
                  <React.Fragment key={rowIndex}>
                    
          
                    {row.map((item, index) => (
                      <tr key={index}>
                        <td>{item.subject}</td>
                        <td>{item.question}</td>
                        <td>{item.term1}</td>
                        <td>{item.term2}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          );
       
        }else
        if(Terms.t1 !== '' && Terms.t2 ==='' && Terms.t3 !=='' && Terms.t4==='' && Terms.cfinal===''){
       
          return (
            <table className="table table-bordered align-middle table-nowrap mb-0">
              <thead>
                <tr className="table-dark">
                  <th className="align-middle">Subject</th>
                  <th className="align-middle">Question</th>
                  <th className="align-middle">Term 1 Score Rating</th>
                  <th className="align-middle">Term 3 Score Rating</th>
                </tr>
              </thead>
              <tbody>
                {viewResult.map((row, rowIndex) => (
                  <React.Fragment key={rowIndex}>
                    
          
                    {row.map((item, index) => (
                      <tr key={index}>
                        <td>{item.subject}</td>
                        <td>{item.question}</td>
                        <td>{item.term1}</td>
                        <td>{item.term3}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          );
       
        }else
        if(Terms.t1 !== '' && Terms.t2 !=='' && Terms.t3 !=='' && Terms.t4==='' && Terms.cfinal===''){
          return (
            <table className="table table-bordered align-middle table-nowrap mb-0">
              <thead>
                <tr className="table-dark">
                  <th className="align-middle">Subject</th>
                  <th className="align-middle">Question</th>
                  <th className="align-middle">Term 1 Score Rating</th>
                  <th className="align-middle">Term 2 Score Rating</th>
                  <th className="align-middle">Term 3 Score Rating</th>
                </tr>
              </thead>
              <tbody>
                {viewResult.map((row, rowIndex) => (
                  <React.Fragment key={rowIndex}>
                    
          
                    {row.map((item, index) => (
                      <tr key={index}>
                        <td>{item.subject}</td>
                        <td>{item.question}</td>
                        <td>{item.term1}</td>
                        <td>{item.term2}</td>
                        <td>{item.term3}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          );
        }else
        if(Terms.t1 !== '' && Terms.t2 !=='' && Terms.t3 !=='' && Terms.t4 !=='' && Terms.cfinal ===''){
            return (
              <table className="table table-bordered align-middle table-nowrap mb-0">
              <thead>
                <tr className="table-dark">
                  <th className="align-middle">Subject</th>
                  <th className="align-middle">Question</th>
                  <th className="align-middle">Term 1 Score Rating</th>
                  <th className="align-middle">Term 2 Score Rating</th>
                  <th className="align-middle">Term 3 Score Rating</th>
                  <th className="align-middle">Term 4 Score Rating</th>
                </tr>
              </thead>
              <tbody>
                {viewResult.map((row, rowIndex) => (
                  <React.Fragment key={rowIndex}>
                    
          
                    {row.map((item, index) => (
                      <tr key={index}>
                        <td>{item.subject}</td>
                        <td>{item.question}</td>
                        <td>{item.term1}</td>
                        <td>{item.term2}</td>
                        <td>{item.term3}</td>
                        <td>{item.term4}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
              );
        }else if(Terms.t1 !== '' && Terms.t2==='' && Terms.t3==='' && Terms.t4 !=='' && Terms.cfinal ===''){
            return (
              <table className="table table-bordered align-middle table-nowrap mb-0">
              <thead>
                <tr className="table-dark">
                  <th className="align-middle">Subject</th>
                  <th className="align-middle">Question</th>
                  <th className="align-middle">Term 1 Score Rating</th>
                  <th className="align-middle">Term 4 Score Rating</th>
                </tr>
              </thead>
              <tbody>
                {viewResult.map((row, rowIndex) => (
                  <React.Fragment key={rowIndex}>
                    
          
                    {row.map((item, index) => (
                      <tr key={index}>
                        <td>{item.subject}</td>
                        <td>{item.question}</td>
                        <td>{item.term1}</td>
                       <td>{item.term4}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
              );
        }else
         if(Terms.t1 === '' && Terms.t2 !=='' && Terms.t3==='' && Terms.t4 ==='' && Terms.cfinal ===''){

            return    <table className="table align-middle table-nowrap mb-0">
             
            { viewResult.map((subject, index) => (
               <React.Fragment key={index}>
                 <thead>
                   <tr className="table-dark">
                     <th colSpan="5">{subject.subject}</th>
                   </tr>
                   <tr className="table-primary">
                   
                     <th>Assessment Area</th>
                     <th>Score Rating</th>
                     <th>Percentage</th>
                     <th>Symbol</th>
                   </tr>
                 </thead>
                 <tbody>
                   {subject.assessments.map((assessment, i) => (
                     <tr key={i}>
                       <td>{assessment.category}</td>
                       <td>{assessment.term2_score}</td>
                       <td>{assessment.percentage}</td>
                       <td>{assessment.symbol}</td>
                     </tr>
                   ))}
                 </tbody>
               </React.Fragment>
             ))}
           </table>

         }else
          if(Terms.t1 === '' && Terms.t2 ==='' && Terms.t3 !=='' && Terms.t4 ==='' && Terms.cfinal ===''){
            return    <table className="table align-middle table-nowrap mb-0">
             
            { viewResult.map((subject, index) => (
               <React.Fragment key={index}>
                 <thead>
                   <tr className="table-dark">
                     <th colSpan="5">{subject.subject}</th>
                   </tr>
                   <tr className="table-primary">
                   
                     <th>Assessment Area</th>
                     <th>Score Rating</th>
                     <th>Percentage</th>
                     <th>Symbol</th>
                   </tr>
                 </thead>
                 <tbody>
                   {subject.assessments.map((assessment, i) => (
                     <tr key={i}>
                       <td>{assessment.category}</td>
                       <td>{assessment.term3_score}</td>
                       <td>{assessment.percentage}</td>
                       <td>{assessment.symbol}</td>
                     </tr>
                   ))}
                 </tbody>
               </React.Fragment>
             ))}
           </table>
          }else
           if(Terms.t1 === '' && Terms.t2==='' && Terms.t3 ==='' && Terms.t4 !=='' && Terms.cfinal ===''){
            return    <table className="table align-middle table-nowrap mb-0">
             
            { viewResult.map((subject, index) => (
               <React.Fragment key={index}>
                 <thead>
                   <tr className="table-dark">
                     <th colSpan="5">{subject.subject}</th>
                   </tr>
                   <tr className="table-primary">
                    
                     <th>Assessment Area</th>
                     <th>Score Rating</th>
                     <th>Percentage</th>
                     <th>Symbol</th>
                   </tr>
                 </thead>
                 <tbody>
                   {subject.assessments.map((assessment, i) => (
                     <tr key={i}>
                       <td>{assessment.category}</td>
                       <td>{assessment.term4_score}</td>
                       <td>{assessment.percentage}</td>
                       <td>{assessment.symbol}</td>
                     </tr>
                   ))}
                 </tbody>
               </React.Fragment>
             ))}
           </table>
           }else
            if(Terms.t1 === '' && Terms.t2==='' && Terms.t3==='' && Terms.t4 ==='' && Terms.cfinal !==''){
              return (
                <table className="table table-bordered align-middle table-nowrap mb-0">
                <thead>
                  <tr className="table-dark">
                    <th className="align-middle">Subject</th>
                    <th className="align-middle">Question</th>
                    <th className="align-middle">Term 1 Score Rating</th>
                    <th className="align-middle">Term 2 Score Rating</th>
                    <th className="align-middle">Term 3 Score Rating</th>
                    <th className="align-middle">Term 4 Score Rating</th>
                  </tr>
                </thead>
                <tbody>
                  {viewResult.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      
            
                      {row.map((item, index) => (
                        <tr key={index}>
                          <td>{item.subject}</td>
                          <td>{item.question}</td>
                          <td>{item.term1}</td>
                          <td>{item.term2}</td>
                          <td>{item.term3}</td>
                          <td>{item.term4}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
                );
            }else
             if(Terms.t1 === '' && Terms.t2 !=='' && Terms.t3 !=='' && Terms.t4 ==='' && Terms.cfinal ===''){

              return (
                <table className="table table-bordered align-middle table-nowrap mb-0">
                <thead>
                  <tr className="table-dark">
                    <th className="align-middle">Subject</th>
                    <th className="align-middle">Question</th>
                    <th className="align-middle">Term 1 Score Rating</th>
                    <th className="align-middle">Term 2 Score Rating</th>
                    <th className="align-middle">Term 3 Score Rating</th>
                  
                  </tr>
                </thead>
                <tbody>
                  {viewResult.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      
            
                      {row.map((item, index) => (
                        <tr key={index}>
                          <td>{item.subject}</td>
                          <td>{item.question}</td>
                          <td>{item.term1}</td>
                          <td>{item.term2}</td>
                          <td>{item.term3}</td>
                        
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
                );

             }else
             if(Terms.t1 === '' && Terms.t2 ==='' && Terms.t3 !=='' && Terms.t4 !=='' && Terms.cfinal ===''){

              return (
                <table className="table table-bordered align-middle table-nowrap mb-0">
                <thead>
                  <tr className="table-dark">
                    <th className="align-middle">Subject</th>
                    <th className="align-middle">Question</th>
                    <th className="align-middle">Term 3 Score Rating</th>
                    <th className="align-middle">Term 4 Score Rating</th>
                  
                  </tr>
                </thead>
                <tbody>
                  {viewResult.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      
            
                      {row.map((item, index) => (
                        <tr key={index}>
                          <td>{item.subject}</td>
                          <td>{item.question}</td>
                      
                          <td>{item.term3}</td>
                          <td>{item.term4}</td>
                        
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
                );

             }else
             if(Terms.t1 === '' && Terms.t2 !=='' && Terms.t3 ==='' && Terms.t4 !=='' && Terms.cfinal ===''){

              return (
                <table className="table table-bordered align-middle table-nowrap mb-0">
                <thead>
                  <tr className="table-dark">
                    <th className="align-middle">Subject</th>
                    <th className="align-middle">Question</th>
                    <th className="align-middle">Term 2 Score Rating</th>
                    <th className="align-middle">Term 4 Score Rating</th>
                  
                  </tr>
                </thead>
                <tbody>
                  {viewResult.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      
            
                      {row.map((item, index) => (
                        <tr key={index}>
                          <td>{item.subject}</td>
                          <td>{item.question}</td>
                        
                          <td>{item.term2}</td>
                          <td>{item.term4}</td>
                        
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
                );
                
             }else
             if(Terms.t1 !== '' && Terms.t2 ==='' && Terms.t3 !=='' && Terms.t4 !=='' && Terms.cfinal ===''){

              return (
                <table className="table table-bordered align-middle table-nowrap mb-0">
                <thead>
                  <tr className="table-dark">
                    <th className="align-middle">Subject</th>
                    <th className="align-middle">Question</th>
                    <th className="align-middle">Term 1 Score Rating</th>
                    <th className="align-middle">Term 3 Score Rating</th>
                    <th className="align-middle">Term 4 Score Rating</th>
                  
                  </tr>
                </thead>
                <tbody>
                  {viewResult.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      
            
                      {row.map((item, index) => (
                        <tr key={index}>
                          <td>{item.subject}</td>
                          <td>{item.question}</td>
                          <td>{item.term1}</td>
                          <td>{item.term3}</td>
                          <td>{item.term4}</td>
                        
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
                );
                
             }else
             if(Terms.t1 !== '' && Terms.t2 ==='' && Terms.t3 ==='' && Terms.t4 !=='' && Terms.cfinal ===''){
              return (
                <table className="table table-bordered align-middle table-nowrap mb-0">
                <thead>
                  <tr className="table-dark">
                    <th className="align-middle">Subject</th>
                    <th className="align-middle">Question</th>
                    <th className="align-middle">Term 1 Score Rating</th>
                    <th className="align-middle">Term 4 Score Rating</th>
                  
                  </tr>
                </thead>
                <tbody>
                  {viewResult.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      
            
                      {row.map((item, index) => (
                        <tr key={index}>
                          <td>{item.subject}</td>
                          <td>{item.question}</td>
                          <td>{item.term1}</td>
                          <td>{item.term4}</td>
                        
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
                );
             }else
             if(Terms.t1 !== '' && Terms.t2 !=='' && Terms.t3 !=='' && Terms.t4 !=='' && Terms.cfinal !==''){

              return (
                <table className="table table-bordered align-middle table-nowrap mb-0">
                <thead>
                  <tr className="table-dark">
                    <th className="align-middle">Subject</th>
                    <th className="align-middle">Question</th>
                    <th className="align-middle">Term 1 Score Rating</th>
                    <th className="align-middle">Term 2 Score Rating</th>
                    <th className="align-middle">Term 3 Score Rating</th>
                    <th className="align-middle">Term 4 Score Rating</th>
                  
                  </tr>
                </thead>
                <tbody>
                  {viewResult.map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      
            
                      {row.map((item, index) => (
                        <tr key={index}>
                          <td>{item.subject}</td>
                          <td>{item.question}</td>
                          <td>{item.term1}</td>
                          <td>{item.term2}</td>
                          <td>{item.term3}</td>
                          <td>{item.term4}</td>
                        
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
                );
                
             }
  }
       
    return(
        <div className="table-responsive" style={{marginTop:-20}}>
                {checkTerms()}
          </div>
    )
}

export default ProcessResult