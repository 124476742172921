import React, { useState, useEffect } from 'react'
import axios from 'axios';

function Wallet() {
    const convertCurrency = (num) =>{
        return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }
        
         const [userName, setUsername] = useState();
         const [balance, setBalance] = useState(0);
      
         const [transStatus, setTransStatus] = useState(false);
         const [ transaction, setTransaction ] = useState();
         const [expenses, setExpenses] = useState();
         const [userid, setUserID] = useState();
         const [withdraw, setWithdraw] = useState(false);
         const [loader, setLoader] = useState(false);
         const [withAmt, setWithAmt] = useState('');
         const [notify, setNotify] = useState(null);
         const [amountWith, setAmountWith] = useState();
         const [dateWith, setDateWith] = useState();
         const [withStatus, setWithStatus] = useState();
         const [withID, setWithID] = useState();

          

         const formatAMPM = (date) => {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            let strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
          }

          const RemoveRequest = (id) =>{
        
            axios.post('remove-withdrawal-request',{
               id:id
            },{
             headers: { 'Content-Type': 'application/json'}
            }).then((res)=>{
                if(res.data.withd === false){
                 window.location.reload(false);
                }
                
            })
     } 

          const handleChange = (e) =>{
            const value = e.target.value.replace(/\D/g, "");
            setWithAmt(value);
          }
         const handleRequest = () => {
                    if(!withAmt.trim()){
                        setNotify('Please enter the amount you want to withdraw');
                        setTimeout(()=>{
                            setNotify(null);
                        },5000)
                    }
                    else
                    {
                         
                          if( withAmt > balance ){

                            setNotify('Unable to complete request due to insufficient fund');
                            setTimeout(()=>{
                                setNotify(null);
                            },5000);

                          }else{
                            setLoader(false)
                            setNotify(null);
                        let request_time = formatAMPM(new Date());
                        let today = new Date();
                        let currdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                        let total_date = currdate+' '+request_time;
                       axios.post('/request-withdraw',{
                             amount:withAmt,
                             date:total_date
                       },{
                        headers: { 'Content-Type': 'application/json'}
                     })
                       .then((response)=>{
                            setTimeout(()=>{
                                setLoader(true);
                                 if(response.data.withbody === false){
                                    setWithdraw(false);
                                 } else {
                                    setWithdraw(true);
                                    setAmountWith(response.data.withbody[0].amount);
                                    setDateWith(response.data.withbody[0].date_request);
                                    setWithStatus(response.data.withbody[0].status);
                                    setWithID(response.data.withbody[0].request_id);
                                    
                                 }    
                            },2000);
                       })    

                    }
                    }
         }

         const checkWithdrawStatus = () =>{
                    setLoader(false);
                axios.get('/withdrawal-status',{
                  headers: { 'Content-Type': 'application/json'}
               })
                .then((response)=>{
                      setTimeout(()=>{
                            setLoader(true)
                        if(response.data.withState === false){
                            setWithdraw(false);

                    }else{
                       setWithdraw(true);
                       setAmountWith(response.data.withState[0].amount);
                       setDateWith(response.data.withState[0].date_request);
                       setWithStatus(response.data.withState[0].status);
                       setWithID(response.data.withState[0].request_id);
                    }
                      },2000);
                   
                })
         }

          useEffect(()=>{
                 axios.get('/get-tutor-information',{
                  headers: { 'Content-Type': 'application/json'}
               })
                 .then((response)=>{
    
                                
                            setUsername(response.data.userData[0].name);
                            setBalance(response.data.userData[0].wallet);
                            setUserID(response.data.userData[0].userid);
                             
                             if(response.data.userData[0].wallet === null){
                                setBalance(0);
                             }else
                             {
                                setBalance(response.data.userData[0].wallet);
                             }
    
    
    
                              if(response.data.transData === false){
                                         setTransStatus(false);
                                         setExpenses(0);
                                         
                              }else{
                                            setTransStatus(true);
    
                                            let resp = response.data.transData;
                                            let total_exp = 0;
                                             resp.map((amt)=>(
                                                    total_exp = parseInt(total_exp) + parseInt(amt.amount)
                                             ))
    
                                               setExpenses(total_exp);
                                               setTransaction(response.data.transData);
                                             
                              }

                              
                 });
                 checkWithdrawStatus();
          },[])
    return (
        <div>

            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                    <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">Wallet</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">Wallet</li>
                <li className="breadcrumb-item active">My Wallet</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

                
      <div className="row">
      <div className="col-xl-1">
         
            
         </div>
        
      <div className="col-xl-4">
        <div className="card">
          <div className="card-body border-bottom">
            
            <div>
              <div className="mb-4 me-3">
                <i className="mdi mdi-account-circle text-primary h1" />
              </div>
              <div>
                <h5>{ userName }</h5>
                <p className="text-muted mb-1">Profile ID: { userid }</p>
              </div>
            </div>
          </div>
          <div className="card-body border-bottom">
          <div>
              <div className="row">
                <div className="col-sm-6">
                  <div>
                    <p className="text-muted mb-2">Available Balance</p>
                    <h5>{ convertCurrency(parseInt(balance)) }</h5>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-end mt-4 mt-sm-0">
                    <p className="text-muted mb-2">Total money withdrawn</p>
                    <h5>{ expenses === 0 ? 'R0.00' : convertCurrency(parseInt(expenses)) }  </h5>
                  </div>
                </div>
              </div>
            </div>
             </div>
        </div>

       
      </div>


        <div className="col-xl-7">
          <div className="card">
            <div className="card-body">
              
              <div>
                <p className="text-muted mb-2"><i className="mdi mdi-wallet me-1" /> Request Withdrawal</p>
                <h5>Wallet Balance: { convertCurrency(parseInt(balance)) }</h5>
              </div>
              <div className="mt-4">
                 { loader !== false ?
                 <div>
                { withdraw !== false ?
              <div className="table-responsive">
                   <p className="text-muted mb-2">Withdrawal Request Status</p>
                  <table className="table table-nowrap align-middle mb-0">
                  <thead>
                        <tr>
                            <th scope="col">#Request ID</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Date &amp; Time</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                                                        
                        </tr>
                     </thead>
                     <tbody>
                         <tr>
                                                        <td>
                                                             <div className="text-muted">
                                                                { withID }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            
                                                            <div className="text-muted">{ convertCurrency(parseInt(amountWith)) }</div>
                                                        </td>
                                                        <td>
                                                            
                                                            <div className="text-muted">{ dateWith }</div>
                                                        </td>
                                                        <td>
                                                        <span className="badge bg-danger">
                                                                { withStatus}
                                                            </span>
                                                        </td>
                                                        <td>  <button type="button" className="btn btn-light btn-sm btn-rounded waves-effect waves-light" onClick={()=>RemoveRequest(withID)}>
                                                                         Cancel Request
                                                                          </button>
                            </td>
                         </tr>
                     </tbody>
                  </table>
                  
                  </div>
                    :
                <div className="tab-content mt-4">
                  <div className="tab-pane active" id="buy-tab" role="tabpanel">
                    <h5 className="font-size-12 mb-2">Enter The Amount You Want Withdraw</h5>
                    <div>
                        { notify !== null ? <div  className="alert alert-info alert-dismissible fade show mb-2" role="alert">
                                                <i className="mdi mdi-alert-circle-outline me-3"></i>
                                               { notify}
                                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                            
                                            </div> : null}
                    <div className="input-group mb-3">
                             <label className="input-group-text">Amount</label>
                                    <input type="text" className="form-control" onChange={(e)=> handleChange(e)} value={ withAmt }/>
                                    <label className="input-group-text">R</label>
                    </div>

                    <div className="text-center">
                    <button type="button" className="btn btn-success w-md" onClick={()=> handleRequest()}>Request</button>
                             </div>
                     
                    </div>
                  </div>

                </div>
                }
                       </div>
                        :


                  <div className="text-center">
                  <div className="spinner-border text-primary m-1 " role="status">
                         <span className="sr-only">Loading...</span>
                 </div>
                  </div>
                    }
              
              
              </div>
            
            
            </div>
          </div>
        </div>
     

     
     
      </div>
                
               

      <div className="row">

        <div className="col-xl-1">
         
            
        </div>
       
       
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">Wallet Activity</h4>
              <div data-simplebar style={{maxHeight: '310px'}}>

                  { transStatus === false ? <div>
                    <p>No transaction at this moment</p>
                  </div> : 
                 <div>
                <ul className="verti-timeline list-unstyled">
                
                { transaction && transaction.map(trx=>(
                <li className="event-list" key={trx.id}>
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle font-size-18" />
                </div>
                <div className="media">
                  <div className="me-3">
                    <h5 className="font-size-14">{ trx.date_trans } <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" /></h5>
                  </div>
                  <div className="media-body">
                    <div>
                    { trx.details }
                    </div>
                  </div>
                </div>
                </li>
   ))}
            
                 </ul>
                
                </div>
}
              </div>
            </div>
          </div>
        </div>
      </div>
     


                    </div>
                </div>
            </div>


            
        </div>
    )
}

export default Wallet
