import React,{useState, useEffect} from 'react'
import axios from 'axios';
import {useParams, Link} from 'react-router-dom'

function AssessmentUpload() {
    const { id } = useParams();
    const [loadingstatus, setLoadingStatus] = useState(false);
    const [centrestatus, setCentreStatus] = useState(null);
    const [loadCentres, setLoadcentre] = useState(null);
    const [ viewGrade, setViewGrade] = useState(null);
    const [centresID, setCentresID] = useState();
    const [subject, setSubject] = useState(null);
 
    const [gradeId, setGradeID] = useState();
    const [assess, setAssess] = useState(null);
    const [assessStatus, setAssessStatus] = useState();
    const [subjectID, setSubjectID] = useState();
    const [displayCapture, setDisplayCapture] = useState(null);
    const [notifyState, setNotifyState] = useState(null)
    const [status, setStatus] = useState();
    const [marks, setMarks] = useState();
    const [subjectName,setSubjectName] = useState();

    const [loadupl, setUploadupl] = useState(false);
   
  
    const [subjectStatus, setSubjectStatus] = useState(null);
    const [uplApp, setUpApp] = useState(null);
  
 
    const [handleFile, setHandleFile] = useState('');
    const [assessID, setAssessID] = useState();
    const [notify, setNotify] = useState(null);
    const [studentN, setStudentN] = useState();
    const [checkUpload, setCheckUpload] = useState();
    const [year, setYears] = useState();
    const [term, setTerm] = useState();
    const [newUpload, setNewUpload] = useState(false);
    const [uploadSection, setUploadSection] = useState(false);
    const [newFile, setNewFile] = useState('');
    const [notifyNew, setNotifyNew] = useState(null);

    const [learners, setLearners] = useState(null);
    const [studName, setStudName] = useState();
    const [studLang, setStudLang] = useState();
    const [studentID, setStudentID] = useState();
    const [taskType, setTaskType] = useState();
    const [intD, setInvD] = useState();
    const [notifyInvoice, setNotifyInvoice] = useState(null);
    const [remo, setRemo] = useState(false);
    const [remoStatus, setRemoStatus] = useState(null);
    const [invd, setInvd] = useState();
    const [gradeData, setGradeData] = useState(null);
    const [currTerms, setCurrTerms] = useState(null)

    const task =id;

    const terms = [
      {termly:'Term 1', id:1},
      {termly:'Term 2', id:2},
      {termly:'Term 3', id:3},
      {termly:'Term 4', id:4}
      
    ]


    const checkTerms = () => {
      let options = "";
    
      switch (currTerms) {
        case "1":
          options = <option value={'Term 1'}>Term 1</option>;
          break;
        case "2":
          options = <option value={'Term 2'}>Term 2</option>; 
          break;
        case '3':
          options = <option value={'Term 3'}>Term 3</option>;
          break;
        case '4':
          options = <option value={'Term 4'}>Term 4</option>;
          break;
        default:
          options = <option disabled>Terms</option>;
      }
    
      return options;
    };


    
    const getStudentGrade = (cid) => {    
               
      axios.get('/get-user-grade?cid='+cid,{
         headers:{ 'Content-Type': 'application/json'}
       })
       .then((response)=>{
                  
             if(response.data.status === false){
            
                 setGradeData(null)
               
             }else{
              setGradeData(response.data.status)
             }

       }) 
  }

 
  const checkGrade = (centres) =>{
       if(centres === ''){
        setGradeData(null);
       }else
       {

         getStudentGrade(centres)
        setCentresID(centres);

       }
        
       
          
  }

  

  const getSubjectID = (subID) =>{
    axios.get('/getsubject-data/'+subID,{
      headers:{ 'Content-Type': 'application/json'}
    }).then((res)=>{
          setSubjectName(res.data.msubjectData[0].subject);
          return res.data.msubjectData[0].subject;
    })
}

  const checkSubject = (grade) =>{
    setLoadingStatus(true);
      setGradeID(grade);
        axios.get('/all-student-centre/'+grade+'/'+centresID,{
          headers:{ 'Content-Type': 'application/json'} 
        }).then((res)=>{
            setTimeout(()=>{
              setLoadingStatus(false);
              if(res.data.gradeFilter === false){
                setLearners(null)
          }else
          {
                setLearners(res.data.gradeFilter);
          }
            },2000)
           
        })
  }

  const checkAssessment = (e) =>{
        let gh = e.target.value;
          let mk = gh.split(',');
        setSubjectID(mk[0]);
        setSubjectName(mk[1]);
         
    setLoadingStatus(true);
         axios.post('/get-access-assessment',{
          subjectID:mk[0],
           gradeID:gradeId, 
           task:task,
           term:term,
           year:year
         },{
          headers:{ 'Content-Type': 'application/json'} 
         }).then((res)=>{
           setTimeout(()=>{
            setLoadingStatus(false);
            if(res.data.assData === false){
              setAssessStatus(<option disabled>Not available</option>)
         }else
         {
               setAssess(res.data.assData);
         }
           },2000)
         })
  }

  const handleUploadFile = () =>{

    if(handleFile === ''){
        setNotify(<div className='alert alert-info text-center'> Kindly upload your complete { task }</div>);
    }else
    {
       setUploadupl(true)
  const formData = new FormData();
   formData.append('file',handleFile);
   formData.append('assessID',assessID);
   formData.append('studentName',studName);
        axios.post('/upload-assessment-data',formData).then((res)=>{
            setTimeout(()=>{
              setUploadupl(false)
                  setNotify(<div className='alert alert-info text-center'> {res.data.message} </div>);
            },2000);
        })

       
    }

}

const updateData = (assID,studName,upload) => {
  setAssessID(assID);
  setStudentN(studName);
  setCheckUpload(upload);
}


const uploadAssessment = (invigi_ID) =>{
  setLoadingStatus(true)
     let hk = invigi_ID.split(',');
       setInvD(hk[0]);
       setInvd(invigi_ID);
      setTaskType(hk[1]);
axios.post('/get-assessmentdata-upload',{
      invigiI:hk[0],
      grades:gradeId,
      centreI:centresID, 
      subjectIs:subjectID,
      year:year,
      term:term,
      utype:'summary',
      studID:studentID


},{
  headers:{ 'Content-Type': 'application/json'} 
}).then((res)=>{
             console.log(res)
             setTimeout(()=>{
              setLoadingStatus(false);
              if(res.data.upData === false){
              
                setDisplayCapture(null)
                setUploadSection(true)
           }else
           {
                setDisplayCapture(res.data.upData);
               
                setUploadSection(false)
           }
             },2000)
})
}


const getmystudent = (e) =>{
          setLoadingStatus(true);
          let sd = e.target.value
            let uk = sd.split(',');

             let status = uk[4];
              console.log(status)
              if(status === '1'){

                setLoadingStatus(false);
                setNotifyInvoice(<div className='alert alert-info text-center'>This learner's account has been suspended. contact support for more info </div>)
          
                return;
              }
       
            if(uk[3] === 'Paid'){

              setNotifyInvoice(null)
             
              setStudLang(uk[2]);
              setStudName(uk[1]);
              setStudentID(uk[0])
             axios.get('/get-admin-studentsubject/'+uk[0]+'/'+gradeId,{
              headers:{ 'Content-Type': 'application/json'}
             }).then((resp)=>{
                  setTimeout(()=>{
                    setLoadingStatus(false);
                    if(resp.data.subjectData === false){
                      setSubject(null);
                      setSubjectStatus(<option disabled>Not Available</option>)
                }else
                {
                      setSubject(resp.data.subjectData);
                }
                  },2000);
                   
             })
            }else{
              setLoadingStatus(false);
               setNotifyInvoice(<div className='alert alert-info text-center'>Unable to retrive learner subject at this moment. kindly contact us for more information </div>)
            }
}



const handleNewUpload = () =>{
  if(newFile === ''){
     setNotifyNew('Please upload assessment');
  }else
  {
      setUpApp('rep');
     const uformData = new FormData();
     uformData.append('file',newFile);
     uformData.append('grade',gradeId);
     uformData.append('studID',studentID);
     uformData.append('subjectID',subjectID);
     uformData.append('year',year);
     uformData.append('term',term);
     uformData.append('utype','tutor');
     uformData.append('invDownloadID',intD);
     uformData.append('cate',task);
     uformData.append('studLang',studLang);
     uformData.append('studName',studName);
     uformData.append('subject',subjectName);
     uformData.append('taskType',taskType);
     uformData.append('centreID',centresID)


        axios.post('/upload-new-assesment-tutor',uformData).then((resp)=>{
           setTimeout(()=>{
            setUpApp(null);
            if(resp.data.status === 'not'){
              setNotifyNew(resp.data.message)
           }else{
            setNotifyNew(null)
            setDisplayCapture(resp.data.upData);   
            setUploadSection(false)
 
          }
           },2000)
         
        })


       
  }
}



    useEffect(()=>{

      let min = new Date().getFullYear(),
      max = 2040,
     select = document.getElementById('year');
 
 for (var i = min; i<=max; i++){
     var opt = document.createElement('option');
     opt.value = i;
     opt.innerHTML = i;
     select.appendChild(opt);
 }
 
 select.value = new Date().getFullYear();

     
       axios.get('/centres',{
        headers:{ 'Content-Type': 'application/json'}
       }).then((response)=>{

             setTimeout(()=>{
              setCurrTerms(response.data.term)
           
              setLoadingStatus(false);
              if(response.data.result === false){
                setCentreStatus(<option disabled>Not Available</option>);
          }else
          {
               setLoadcentre(response.data.result);
          }
             })
           
       })
       setNotifyInvoice(null)
       setLoadingStatus(false)
       document.getElementById('uploadForm').reset();

},[task]);

const removeData = (upid) => {
  setRemo(true);
   setRemoStatus(null)
  axios.post('/remove-assessmentupload',{uid:upid},{
    headers: { 'Content-Type': 'application/json'}
  }).then((res)=>{
        setTimeout(()=>{
            setRemo(false);
            uploadAssessment(invd);
            setRemoStatus(<div className='alert alert-info text-center'>{res.data.status}</div>)
        },1000);
  })
}


    return (
        <div>
     
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Upload { task }</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Academics</li>
                        <li className="breadcrumb-item active">Upload {task }</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>


          <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
            
              <form className="row gy-3 gx-5 align-items-center" id='uploadForm'>



              <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Term</label>
                   
                  <select className="form-select form-control" onChange={(e)=>setTerm(e.target.value)} id="autoSizingSelect" style={{textTransform:'capitalize'}}>
                  <option value=''> Term </option>
                        { checkTerms() }
                  </select>
                </div>


                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Academic Year</label>
                   
                  <select className="form-select form-control" id="year" onChange={(e)=>setYears(e.target.value)} >
                  <option value=''> Year </option> 
                 
                  </select>
                </div>
      

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Centres</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=>checkGrade(e.target.value)}>
                    <option value=''> Centres</option>
                        { loadCentres === null ? centrestatus :
                            loadCentres && loadCentres.map(ct=>(
                               <option key={ct.id} value={ct.center_id}>{ ct.centreName }</option>
                            ))
                        }
                  </select>
                </div>


                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Grade</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=>checkSubject(e.target.value)}>
                    <option value={false}> Grade</option>
                     { gradeData === null ? <option disabled>Not Available</option> :
                          gradeData.map(g=>(
                            <option key={g.id} value={g.grade}> Grade: { g.grade }</option>
                          ))
                     }
                  </select>
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Learners</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=> getmystudent(e)}>
                    <option value={false}> Learners</option>
                       {learners === null ? <option disabled>Not Available </option>
                       : 
                         learners.map((s)=>(
                              <option key={s.id} value={s.student_id+','+s.firstname+' '+s.lastname+','+s.lang_intruct+','+s.payment_status+','+s.status}>{s.firstname+' '+s.lastname}</option>
                         ))
                       }
                  </select>
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Subject</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=> checkAssessment(e)}>
                    <option value={false}> Subject</option>
                    { subject === null ? subjectStatus :
                      subject && subject.map(s=>(
                        <option key={s.id} value={s.subject_id+','+s.subject}>{s.subject} ({studLang})</option>
                      ))
                    }
                   
                  </select>
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect" >Assessment</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=>uploadAssessment(e.target.value)} >
                    <option value={false}> Assessment</option>
                      { assess === null ? assessStatus :
                        assess && assess.map(ad=>(
                          <option key={ad.id} value={ad.invigilator_id+','+ad.assessment}>{ ad.assessment }</option>
                        ))}
                  </select>
                </div>
                
                <center>
                  { notifyInvoice === null ? null : notifyInvoice }
                    { loadingstatus !== false ?
                <div className="spinner-border text-primary m-1 " role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            : 
                                            null 
}

                </center>
              </form>
            </div>
            {/* end card body */}
          </div>
          {/* end card */}
        </div>
        {/* end col */}
      </div>

      { uploadSection !== false ? 

<div className="row">
<div className="card">
           <div className="card-body">
             <h5 className="card-title mb-4">{task} Upload</h5>
             <div className="row">  
       <div className="col-lg-2">

         </div>
         <div className="col-lg-8">
           { notifyNew === null ? null : <div className="alert alert-info text-center">{notifyNew} </div>}

         <div className="mt-3">
                                                      
       <label for="formFile" className="form-label"> File Supported Format (PDF, MS WORDS, POWER POINT, EXCEL) </label>
                                                    
         <input className="form-control" type="file"  
         accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          id="formFile" onChange={(e)=> setNewFile(e.target.files[0])} />
       <br />
        { uplApp === null ? 
       <button type="button" className="btn btn-primary btn-xl" onClick={()=> handleNewUpload()}>Upload Assessment</button>
      :
       <button className="btn btn-primary waves-effect waves-light" disabled>

<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
   Please Wait...</button>
}
    </div>
                                      

         </div>
           </div>
           </div>
           </div>
           </div>

            :

            null }
        { displayCapture === null ? null :
        <div className="row">
        <div className="col-lg-12">
          
        <div className="table-responsive">

        { remo !== false ? 
          <div>
       <div className="spinner-border text-primary m-1 " role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <p>Removing Assessment. Please wait...</p>
                                            </div>
                                            :
                                            remoStatus === false ? null : remoStatus }
 
 <table className="table project-list-table table-nowrap align-middle table-borderless">
      <thead>
        <tr>
          
          <th scope="col">Learners</th>
          
          <th scope="col">Assessment</th>
 
          <th scope="col">Upload</th>
          <th scope="col">Upload date</th>
          <th scope="col">Action</th>
         
        </tr>
      </thead>
 
      <tbody>
         { displayCapture && displayCapture.map(({studentName,grade,subject_id,tasktype,assess_upload_id,date_upload,upload,subject_name},index)=>{
                return (
                     <tr key={index}>
                       <td>{ studentName }</td>
                       <td>Grade: {grade} - {subject_name } - {tasktype}</td>
                       <td>{ upload === null ? '--' : <span class="badge badge-pill badge-soft-success font-size-12">Available</span> }</td>
                       <td>{ date_upload === null ? '--' : date_upload }</td>
                       <td>
                       <div className="btn btn-primary btn-sm waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={()=> updateData(assess_upload_id,studentName,upload)}>Re-upload </div>  
                       &nbsp;&nbsp;&nbsp;&nbsp; 
                      <div className="btn btn-primary btn-sm waves-effect waves-light" onClick={()=> removeData(assess_upload_id)}>Remove </div>  
                     
                         </td>
                     </tr>
                )
         })}
           
      </tbody>
 
      </table>
 
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
         <div className="modal-dialog modal-dialog-centered" role="document">
           <div className="modal-content">
             <div className="modal-header">
               <h5 className="modal-title" id="staticBackdropLabel">{ task} Upload</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
             </div>
             <div className="modal-body">
 
 
                               <div class="row">
                                      <div class="col-sm-12">
                                                         { notify === null ? null : notify }
                                                         
 
                                                     <div class="mt-3">
                                                         <label for="formFile" class="form-label"> File Supported Format (PDF, MS WORDS, POWER POINT, EXCEL) </label>
                                                         
                                                         <input class="form-control" type="file"  
                                                         accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                          id="formFile" onChange={(e)=> setHandleFile(e.target.files[0])} />
                                                     </div>
                                                         
                                                 </div>
                                             </div>
 
 
               
             </div>
             <div className="modal-footer">
               <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
              
              { loadupl === false ? 
                
               <button type="button" className="btn btn-primary" onClick={()=> handleUploadFile()}>Upload</button>
                :
               <button type="button" className="btn btn-primary" disabled>
               <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Uploading...
               </button>
             }
             </div>
           </div>
         </div>
       </div>
 
 
  </div>
 
              </div>
        </div>
 
        
}
        
          </div> {/* container-fluid */}
        </div>
        {/* End Page-content */}
     
      </div>
      {/* end main content*/}
        </div>
    )
}

export default AssessmentUpload
