import axios from 'axios';
import React, {useState, useEffect} from 'react'
import Footer from '../Nav/Footer';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';

function Paid() {

  const convertCurrency = (num) =>{
    return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

     
      const [paidInvoce, setPaidInvoice] = useState();
      const [invoiceStatus, setInvoiceStatus] = useState(null);
      const [invoiceDetails, setInvoiceDetails] = useState(false);

      const [amount, setAmount] = useState();
      const [billcycle, setBillCycle] = useState();
      const [yearapply, setYearApply] = useState();
      const [datecreated, setDateCreated] = useState();
      const [paymentstatus, setPaymentStatus] = useState();
      const [loadinvoice, setLoadInvoice] = useState();
      const [invoiceid, setInvoiceId] = useState();
      const [grade, setGrade] = useState();

      const [pageNumber, setPageNumber] = useState(0);

      const [packageName, setPackageName] = useState();

      const studentPerPage = 10;
      const pagesVisited = pageNumber * studentPerPage;
      const displayStudent = paidInvoce && paidInvoce
      .slice(pagesVisited, pagesVisited + studentPerPage)
      .map((inv) =>{
        return (
          <tr key={inv.id} >
                                                                                            
          <td>{ inv.invoice_id } </td>

          <td> { convertCurrency(parseInt(inv.amount)) }</td>
          
          <td> 
                { inv.payment_status === 'Pending' ?
              <span className="badge bg-danger">{ inv.payment_status }</span>
              : 
              <span className="badge bg-success">{ inv.payment_status }</span>
               }
          </td>
          <td>{ inv.date_created } </td>

          <td>
          <button type="button" onClick={()=>handleInvoiceData(inv.invoice_id)} className="btn btn-primary btn-sm btn-rounded waves-effect waves-light">
                                          View Details
                                      </button>
          </td>
          </tr>
        )
      })
      
      const pageCount = Math.ceil(paidInvoce && paidInvoce.length / studentPerPage);
      const changePage = ({selected}) =>{
            setPageNumber(selected)
      }


      
    


    
       const handleInvoiceData = (invoiceID) => {

        axios.get("/get-student-invoice/"+invoiceID,{
          headers: { 'Content-Type': 'application/json'}
       })
        .then((response)=>{
            
              if(response.data.validInvoice === true){
                        setLoadInvoice(response.data.invoice);
                      setInvoiceId(invoiceID);
                      setAmount(response.data.invoice[0].amount);
                      setBillCycle(response.data.invoice[0].bill_cycle);
                      setDateCreated(response.data.invoice[0].date_created);
                      setPaymentStatus(response.data.invoice[0].payment_status);
                      setYearApply(response.data.invoice[0].applying_year);
                      setGrade(response.data.invoice[0].grade);
                      setPackageName(response.data.invoice[0].package_name);
                      
                      

              }else
              {

              }
        });

            setInvoiceDetails(true);


            
            
       }
  
      useEffect(()=>{
            let compoentMount = true;
           const paidIn = async () => {

            await axios.get('/get-user-invoice/Paid',{
              headers: { 'Content-Type': 'application/json'}
            })
            .then((response)=>{
                   if(compoentMount){
                    if(response.data.invoiceData === false){
                                setInvoiceStatus("No purchase has been made");
                    }else{
                            setPaidInvoice(response.data.invoiceData); 
                    }
                  }
            })
          }
             paidIn();
               return () =>{
                  compoentMount = false;
               }
      },[]);
    
    return (
        <div>
             <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Paid Invoice</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Finance</li>
                      <li className="breadcrumb-item active">Paid Invoice</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
             <div className="col-lg-12">
               <div className="tuk">
               <div className="row mb-2">
      
                 </div>
                 <div className="table-responsive">
                   
                    { invoiceStatus !== null ? <div className="alert alert-info">{invoiceStatus}</div>
                    :
                     invoiceDetails === false ?
                     <div>
                   <table className="table project-list-table table-nowrap align-middle table-borderless">
                     <thead>
                       <tr>
                         
                         <th scope="col">#invoiceID</th>
                         
                         <th scope="col">Amount</th>
                         <th scope="col">Payment Status</th>
                         <th scope="col">Date</th>
                         <th scope="col">Action</th>
                       </tr>
                     </thead>
                     <tbody>
             {  displayStudent  }
           
                    </tbody>
                   </table>

                   <ReactPaginate
 previousLabel={<i className="mdi mdi-chevron-left" />}
 nextLabel={<i className="mdi mdi-chevron-right" />}
pageCount={pageCount}
onPageChange={changePage}
breakClassName={'page-item'}
breakLinkClassName={'page-link'}
containerClassName={'pagination pagination-rounded justify-content-center mt-2 mb-5'}
pageClassName={'page-item'}
pageLinkClassName={'page-link'}
previousClassName={'page-item'}
previousLinkClassName={'page-link'}
nextClassName={'page-item'}
nextLinkClassName={'page-link'}
activeClassName={'active'}

/>

                     </div> 

                      :
                      
                     <div>

                <div className="card" id='outprint'>
                  <div className="card-body">
                    <div className="invoice-title">
                      <h4 className="float-end font-size-16">Invoice ID # { invoiceid } </h4>
                      <div className="mb-4">
                        <img src="/autheme/assets/images/eq4.png" alt="logo" height={40} />
                      </div>
                    </div>
                    <hr />
                    
                    <div className="row">
                      <div className="col-sm-6 mt-3">
                        <address>
                           
                            <div>
                            <strong>Payment Status  : <b style={{color:'darkgreen'}}>{ paymentstatus }</b></strong><br />
                            </div>
                         
                        </address>
                      </div>
                      <div className="col-sm-6 mt-3 text-sm-end">
                        <address>
                          <strong>Order Date:</strong><br />
                          { datecreated }
                        </address>
                      </div>
                    </div>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">Order summary</h3>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-nowrap">
                        <thead>
                          <tr>
                            <th style={{width: '70px'}}>Year Applying</th>
                            <th>Grade</th>
                            <th>Billing Cycle</th>
                            <th>Description</th>
                            
                            <th className="text-end">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{ yearapply } </td>
                            <td>{ grade }  { grade === 'R' ? 'Package: '+packageName : null}</td>
                            <td>{ billcycle }</td>
                             <td>Homeschool Curriculum &amp; Assessment </td>
                           
                            <td  className="text-end">{ convertCurrency(parseInt(amount)) }</td>
                          </tr>
                          
                          <tr>
                            <td colSpan={2} className="text-end">Sub Total</td>
                            <td className="text-end">{ convertCurrency(parseInt(amount)) }</td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="border-0 text-end">
                              <strong>Shipping</strong></td>
                            <td className="border-0 text-end">R0.00</td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="border-0 text-end">
                              <strong>Total</strong></td>
                            <td className="border-0 text-end"><h4 className="m-0">{ convertCurrency(parseInt(amount)) }</h4></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-print-none">
                    <div className="float-start">
                    
                        <div onClick={()=> setInvoiceDetails(false)} className="btn btn-primary w-md waves-effect waves-light">Back</div>
                      </div>
                      <div className="float-end">
                        <button className="btn btn-success waves-effect waves-light me-1" onClick={()=> window.print()}><i className="fa fa-print" /> Print Invoice</button>
                        
                      </div>
                    </div>
                  </div>
                </div>
 
                     </div>
                      

                        }


                  
                   
                    <div style={{marginTop:100}}></div>
                 </div>
               </div>
             </div>

          

              
           </div>

            <Footer />
          
          </div>
        </div>
          </div>
        </div>
    )
}

export default Paid
