import React, { useEffect, useState } from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import Footer from '../../Nav/Footer'
import axios from 'axios';
import '../../Authent/Loader.css';


function Invoice() {
  axios.defaults.withCredentials = true;
  const currencyFormat = (num) => {
    return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }
  const { id } = useParams();
    const [loaddata, setLoadData] = useState(true);
    const [invoiceid, setInvoiceId] = useState();
    const [amount, setAmount] = useState();
    const [billcycle, setBillCycle] = useState();
    const [yearapply, setYearApply] = useState();
    const [datecreated, setDateCreated] = useState();
    const [paymentstatus, setPaymentStatus] = useState();
    const [numbSub, setNumbSubj] = useState();
    const [active, setActive] = useState(false);
    const [notify, setNotify] = useState(null);
    const [grade, setGrade] = useState();
    const [studentID, setStudentID] = useState();
    const [displayFund, setDisplayFund] = useState(false);
    const [packagename, setPackageName] = useState();
    const history = useNavigate();
  

       

   
    const handleUpdateTransaction = (rembala,invoice_id) =>{
      axios.post('/update-invoice-data',{
         rembala:rembala,
         invoiceID:invoice_id,
         billcycle:billcycle,
         amount:amount,
         student_id:studentID,
         grade:grade,
         year:yearapply
      },{
        headers: { 'Content-Type': 'application/json'}
     }).then((response)=>{
              if(response.data.status === true){
                setNotify('Congratulation! your payment was successful');
              }

              setTimeout(()=>{
                      setNotify(null)
                      window.location.reload(false);
              },3000);
      })
}

const handleTransaction = () => {
setActive(true);
setNotify(null)
axios.get('/get-users-information',{
  headers: { 'Content-Type': 'application/json'}
})
.then((response)=>{
  const wallet = response.data.userData[0].wallet;
  
        setTimeout(()=>{
          setActive(false);
          if(wallet === null){
               setDisplayFund(true)
               setNotify('Unable to charge your wallet due to insufficient fund. Please fund your wallet to proceed to payment');
               
              }else{
               if(parseInt(amount) > parseInt(wallet)){
                setDisplayFund(true)
                 setNotify('Unable to complete request due to insufficient fund in your wallet. Please fund your wallet to proceed to payment');
               }else{
                    setDisplayFund(false)
                    const updatewallet = parseInt(wallet);
                    const updateAmount = parseInt(amount);
                      let spent = updatewallet - updateAmount;
                       
                    handleUpdateTransaction(spent,invoiceid);
               }
          }

        },2000);
       
})
}



    useEffect(()=>{
       
          axios.get("/get-student-invoice/"+id,{
            headers: { 'Content-Type': 'application/json'}
         })
          .then((response)=>{
                 setLoadData(false)
                if(response.data.validInvoice === true){
                        setInvoiceId(id);
                        setAmount(response.data.invoice[0].amount);
                        setBillCycle(response.data.invoice[0].bill_cycle);
                        setDateCreated(response.data.invoice[0].date_created);
                        setPaymentStatus(response.data.invoice[0].payment_status);
                        setYearApply(response.data.invoice[0].applying_year);
                         setNumbSubj(response.data.numberSub);
                         setGrade(response.data.invoice[0].grade);
                         setStudentID(response.data.invoice[0].student_id);
                         setPackageName(response.data.invoice[0].package_name);
                         
                         
                        
                        

                }else
                {

                }
          })
    },[])
    
    return (

        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Detail</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Invoices</li>
                      <li className="breadcrumb-item active">Detail</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            { active === false ? null :
                      <div id="preloader-spinner" className="preloader spinner">

                      <div className="wrapper">
                          <span className="loader"></span>
                      
                          <p style={{marginTop:120, marginLeft:-20}}>Transaction in progress. Please wait...</p>
                      </div>

                      </div>
                      }
           { loaddata === true ? (<p> Please wait...</p>) :
            <div className="row">
              <div className="col-lg-12">

                <div className="card">
                  <div className="card-body">
                    <div className="invoice-title">
                      <h4 className="float-end font-size-16">Invoice ID # { invoiceid }</h4>
                      <div className="mb-4">
                        <img src="/autheme/assets/images/eq4.png" alt="logo" height={40} />
                      </div>
                    </div>
                    <hr />
                    { notify !==null ? <div className='alert alert-info'>{ notify }</div> : null }
                    <div className="row">
                      <div className="col-sm-6 mt-3">
                          { displayFund !== false ? 
                             <div>
                          <button onClick={()=> history("/mywallet")} className="btn btn-primary w-md waves-effect waves-light">Fund Wallet</button><br></br><br></br>
                           </div>
                          : null
                        }

                        <address>
                            { paymentstatus === "Pending" ?
                            <div>
                          <strong>Payment Status:</strong> <strong style={{color:'darkred'}}>UNPAID</strong>
                          <br></br>
                          <table className='table'>
                                 <tbody>
                                   <tr>
                                     <td><strong>Bank Name</strong></td>
                                     <td><strong>FNB</strong></td>
                                   </tr>
                                   <tr>
                                     <td><strong>Account Holder</strong></td>
                                     <td><strong>FastExcellence</strong></td>
                                   </tr>
                                   <tr>
                                     <td><strong>Account Number</strong></td>
                                     <td><strong>62 891 239 511</strong></td>
                                   </tr>
                                   <tr>
                                     <td><strong>Reference</strong></td>
                                     <td><strong>Learners Full name and Grade</strong></td>
                                   </tr>
                                 </tbody>
                                </table>
                          <br />
                            </div> :
                            <div>
                            <strong>Payment Status</strong>: <strong style={{color:'darkgreen'}}>PAID</strong>
                            </div>
}
                         
                        </address>
                      </div>
                      <div className="col-sm-6 mt-3 text-sm-end">
                        <address>
                          <strong>Order Date:</strong><br />
                          { datecreated } <br /><br />
                        </address>
                      </div>
                    </div>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">Order summary</h3>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-nowrap">
                        <thead>
                          <tr>
                            <th style={{width: '70px'}}>Year Applying</th>
                            <th>Billing Cycle</th>
                            <th>Grade</th>
                            <th>Number of Subject</th>
                            <th>Description</th>
                            <th className="text-end">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{ yearapply } </td>
                            <td>{ billcycle }</td>
                            <td>{ grade === 'R'? grade+', Package: '+packagename : grade }</td>
                            <td>{ numbSub }</td>
                            <td>Homeschool Curriculum &amp; Assessment</td>
                            <td className="text-end">{ currencyFormat(parseInt(amount)) }</td>
                          </tr>
                          
                          <tr>
                            <td colSpan={2} ></td>
                            <td colSpan={2} className="text-end">Sub Total</td>
                            <td className="text-end">{ currencyFormat(parseInt(amount)) }</td>
                          </tr>
                          <tr>
                          <td colSpan={2} ></td>
                            <td colSpan={2} className="border-0 text-end">
                              <strong>Shipping</strong></td>
                            <td className="border-0 text-end">R0.00</td>
                          </tr>
                          <tr>
                          <td colSpan={2} ></td>
                            <td colSpan={2} className="border-0 text-end">
                              <strong>Total</strong></td>
                            <td className="border-0 text-end"><h4 className="m-0">{ currencyFormat(parseInt(amount)) }</h4></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-print-none">
                      <div className="float-end">
                        <button className="btn btn-success waves-effect waves-light me-1"><i className="fa fa-print" /> Print Invoice</button>
                        { paymentstatus === "Pending" ?
                        <button onClick={()=> handleTransaction()} className="btn btn-primary w-md waves-effect waves-light">Pay Now</button>
                        : <div></div> }
                      </div>
                    </div>
                  </div>
                </div>
            
            
              </div>
            </div>
}
            {/* end row */}
          </div> {/* container-fluid */}
        </div>
        {/* End Page-content */}
       <Footer />
      </div>
   )
}

export default Invoice
