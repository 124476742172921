import React,{useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import './Loader.css';
import Footer from '../Nav/Footer';
import axios from 'axios';




function CentresLinking() {
  axios.defaults.withCredentials = true;
    let RADIUS = 100;
     const {id } = useParams();
    
      const [loader, setLoader] = useState(false);
      const [pagin, setPagin] = useState(false);
      const [loadRegion, setLoadRegion] = useState();
      const [mregion, setMRegion] = useState(null);
      const [centreName, setCentreName] = useState('');
      const [centreRegion, setCentreRegion] = useState('');
      const [nameError, setNameError] = useState(null);
      const [regionError, setRegionError] = useState(null);
      const [loadCentre, setLoadCentre] = useState();
      const [notify, setNotify] = useState(null);
      const [checkCent, setCheckCent] = useState(false);
      const [loadingStatus, setLoadingStatus] = useState('Searching for tutor Please wait...');
      const [loadTutors, setLoadTutors] = useState();
      const plat = parseFloat(localStorage.getItem('Parent_longi'));
      const plong = parseFloat(localStorage.getItem('Parent_lat'))
  
     
    
    
      
      const getDistanceFromLatLonInKm = (lat1,lon1,lat2,lon2) => {
        var R = RADIUS; // Radius of the earth in km
        var dLat = deg2rad(lat2-lat1);  // deg2rad below
        var dLon = deg2rad(lon2-lon1); 
        var a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km
        return d.toFixed(2);
      }
      
      const deg2rad = (deg) => {
        return deg * (Math.PI/180)
      }
      


      const getAvailableTutor = () =>{
            setLoader(true)
        axios.get('/get-tutor-nearby/'+plat+'/'+plong,{
          headers: { 'Content-Type': 'application/json'}
        }).then((res)=>{
            setTimeout(()=>{
              setLoader(false)
              if(res.data.centreDatas === false){
                setNotify('Oops! no tutor found. Check back in few minute');
               }else
               {
                setNotify(null);
                setLoadTutors(res.data.centreDatas);

               }
            },2000)
               
              
        })

         


          }



          
            
    
      const validateSearch = () =>{
           let valid = true;

           if(centreRegion === ''){
            valid = false;
             setRegionError('Please select region')
         }else{
            setRegionError(null)
            valid = true;
         }


           if(centreName === ''){
               valid = false;
               setNameError('Please enter centre name');
           }else
           {
              setNameError(null)
              valid = true;
           }

        
            return valid;

      }

      const handleSubmitData = () => {
                    setNotify(null)
                  if(!validateSearch()){
                      
                  }else{
                     setLoader(true)
                 axios.post('/searchcentre',{
                    centreName:centreName,
                    regionName:centreRegion
                  },{
                    headers: { 'Content-Type': 'application/json'}
                 })
                  .then((response)=>{
                    
                      //console.log(response.data.searchData);

                       setTimeout(()=>{
                           setLoader(false);
                        if(response.data.searchData === false){
                            setNotify('No centre available in '+centreRegion+'');
                        }else
                        {
                             setNotify(null)
                            setCheckCent(true);
                            setLoadCentre(response.data.searchData);

                        }
                       },2000)
                     
                  })
                }


      }

    

       useEffect(()=>{
          //mloadStatus, setMLoadStatus
            
       
          getAvailableTutor();

        const fetchRegion = async () =>{
             await axios.get('/all-region',{
            headers: { 'Content-Type': 'application/json'}
         })
          .then((response)=>{
               
               if(response.data.allRegion === false){
                  setMRegion(<option disabled>No data available</option>)
               }else{
                 
                setLoadRegion(response.data.allRegion)
               }
              
          })
     } 

      
          fetchRegion()
        


            
       },[]);

     const handleRequest = (centerID,centName,tutorID) => {
                setLoader(true);
                setNotify(null);
                setLoadingStatus('Processing your request. Please wait');
            axios.post('/addingstudent-centre',{
                 centerID:centerID,
                 centName:centName,
                 studentID:id,
                 tutorID:tutorID
            },{
              headers: { 'Content-Type': 'application/json'}
           }).then((respond)=>{
                setTimeout(()=>{
                  setLoader(false);
                  switch(respond.data.acceptData){
                    case "accept":
                      setNotify('Student request was made successfully. Request is under review kindly check back');
                    break;
                      case "not accept":
                        setNotify('We are unable to process your request at this moment, due to server error');
                     break;
                     case "exist":
                      setNotify('Oops! we notice student already exist in this centre or still under review.');
                       break;
                       default:
                        setNotify(null);
          }
                },2000);
                
                     
            })
     }

    return (
        <div>
             { loader === false ? 
             
             <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Nearby Centres</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Centres</li>
                      <li className="breadcrumb-item active">Nearby Centre</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
               
          
            
                        <div className="col-lg-12">
                        <div className="row">
                            
                        <div className="col-sm-auto col-md-5">
                 
                           
                 <div className="position-relative">
                  
                 <select type='text' className="form-select" id="autoSizingSelect" onChange={(e)=> setCentreRegion(e.target.value)}>
                      <option>Select Region</option>
                    { mregion !== null ? mregion :
                        loadRegion && loadRegion.map(yu =>(
                          <option key={yu.id} defaultValue={yu.location}>{ yu.location }</option>
                        ))
                    }
                   </select>
                   <small style={{color:'red'}}>{ regionError !== null ? regionError: null}</small>
                  </div>
                 
             
                       </div>
                      


                             <div className="col-sm-auto col-md-5">
                 
                           
                  <div className="position-relative">
                  
                  <input type='text' className="form-control" onChange={(e)=> setCentreName(e.target.value)} id="autoSizingSelect" placeholder="Enter Name of Centre" value={centreName} />
                      
                      <small style={{color:'red'}}>{ nameError !== null ? nameError : null}</small>
                   </div>
                  
              
                        </div>
                       

                              <div className="col-sm-auto col-md-2">
                              <div className="position-relative">
                               
                              <button  type="button" className="btn btn-primary btn-rounded waves-effect waves-light" onClick={()=> handleSubmitData()}>Search</button>

                               </div>
                                </div>
                       
                        </div>
                        </div>

                        <div className="col-lg-12" style={{marginTop:30}}>
                            </div>
                       
                   
                { notify !== null ? (<div className="alert alert-info">{ notify }</div>) : 
                    
               checkCent !== false ?
               
                  loadCentre && loadCentre.map(k=>(
               
              <div className="col-xl-4 col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div className="media">
                      <div className="avatar-md me-4">
                        <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                          <img src="/autheme/assets/images/companies/img-1.png" alt="" height={30} />
                        </span>
                      </div>
                      <div className="media-body overflow-hidden">
                        <h5 className="text-truncate font-size-15"><div className="text-dark">{ k.centreName }</div></h5>
                        <p className="text-muted mb-4">
                          Address: { k.centreAddress }<br/>
                        Phone: { k.centrePhone }<br/>
                        Email: { k.centreEmail }
                        </p>
                      
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-2 border-top" style={{marginTop:-20}}>
                  
                  <button type="button" style={{float:'right', clear:'both'}} className="btn btn-primary waves-effect waves-light btn-sm" onClick={()=> handleRequest(k.center_id,k.centreName,k.tutor_id)}> 
                <i className="mdi mdi-account" /> Request to Join</button>
                  </div>
                </div>
              </div>
                  
                  ))
           
             : 
        
                  loadTutors && loadTutors.map(({centreAddress,centreEmail,centreName,centrePhone,lati,logi,center_id},index)=>{
                                        return(
                                          <div className="col-xl-4 col-sm-6" key={index}>
                                          <div className="card">
                                            <div className="card-body">
                                              <div className="media">
                                                <div className="avatar-md me-4">
                                                  <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                                                    <img src="/autheme/assets/images/companies/img-1.png" alt="" height={30} />
                                                  </span>
                                                </div>
                                                <div className="media-body overflow-hidden">
                                                  <h5 className="text-truncate font-size-15"><div className="text-dark">{ centreName }</div></h5>
                                                  <p className="text-muted mb-4">
                                                   
                                                  Email: { centreEmail }<br />
                                                  Phone: { centrePhone } <br />
                                                  Address: { centreAddress }<br />
                                                 
                                                  </p>
                                                
                                                </div>
                                              </div>
                                            </div>
                                            <div className="px-4 py-2 border-top" style={{marginTop:-20}}>
                                            <strong>Distance : { getDistanceFromLatLonInKm(plat,plong,parseFloat(lati),parseFloat(logi))+'km' }</strong>
                                                 
                                            <button type="button" style={{float:'right', clear:'both'}} className="btn btn-primary waves-effect waves-light btn-sm" onClick={()=> handleRequest(center_id,centreName )}> 
                                          <i className="mdi mdi-account" /> Request to Join</button>
                                            </div>
                                          </div>
                                        </div>
                            
                                        )
                  })

             }

             
            </div>
            {/* end row */}
           { pagin === false ?
            <div className="row">
              <div className="col-lg-12">
                {/* Pagination here */}
              
              </div>
            </div>
           :
           <div></div>
}
            {/* end row */}
          </div> {/* container-fluid */}
        </div>
        {/* End Page-content */}
        <Footer />
      </div>

            :
            
             <div id="preloader-spinner" className="preloader spinner">

<div className="wrapper">
    <span className="loader"></span>

    <p style={{marginTop:120, marginLeft:-20}}>{ loadingStatus }</p>
</div>

</div>


}


        </div>
    )
}

export default CentresLinking
