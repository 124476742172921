import React from 'react'
import { Link } from 'react-router-dom'

const ConfirmUserType = ({siteName, handleParent, handleTutor}) => {

    return (
        <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mb-5 text-muted">
                <Link to="/" className="d-block auth-logo">
                  <img src="/autheme/assets/images/eq4.png" alt="" height={40} className="auth-logo-dark mx-auto" />
                  <img src="/autheme/assets/images/eq4.png" alt="" height={40} className="auth-logo-light mx-auto" />
                </Link>
                <p className="mt-3">Kindly select the kind of account you wish to create</p>
              </div>
            </div>
          </div>
          {/* end row */}
         
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-5">
              <div className="card">
                <div className="card-body"> 
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-user h1 mb-0 text-primary" />
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Parent Account</h4>
                        <p>If you are parent, kindly use the button below to complete your registration</p>
                        <div className="mt-4">
                          <div className="btn btn-primary w-md" onClick={()=> handleParent()}>Continue</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            
            </div>
          

            <div className="col-md-12 col-lg-12 col-xl-5">
              <div className="card">
                <div className="card-body"> 
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-user h1 mb-0 text-primary" />
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Tutor Account</h4>
                        <p>If you are a tutor kindly click on the button below to complete registration</p>
                        
                        <div className="mt-4">
                          <div className="btn btn-primary w-md" onClick={()=> handleTutor()}>Continue</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>


          </div>


          <div className="mt-5 text-center">
                <p> © { siteName }</p>
              </div>



        </div>
      </div>
    )
}

export default ConfirmUserType
