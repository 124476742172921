import React, {useState, useEffect} from 'react'
import {Link, NavLink, useNavigate} from 'react-router-dom'


import axios from 'axios';
import '../Authent/Loader.css';
axios.defaults.withCredentials = true;

function NavHead({checkLogin}) {
const history = useNavigate();
const [parentname, setParentName] = useState();
const [notifycount, setNotifyCount] = useState(0);
const [notidat, setNotiDat] = useState(null);
const [notifiData, setNotifiData] = useState();
const [loader, setLoader] = useState(false);
const ApiName = "/user/Parent";

const notificationSystem = () => {
axios.get('/notification',{
  headers: { 'Content-Type': 'application/json'},
  withCredentials:true
})
.then((response)=> {
setNotifyCount(response.data.mcount);
if(response.data.notiData === false){
setNotiDat('No notifications');
}else{

setNotifiData(response.data.notiData)
}

})
}

  const logout = () =>{
        setLoader(true);
       axios.get('/user-logout',{
        headers: { 'Content-Type': 'application/json'},
        withCredentials:true
     })
       .then((response)=>{
                  if(response.data.status){
                    setTimeout(()=>{
                      setLoader(false);
                     checkLogin(false);
                     history("/"); 
                    },2000);
                  }

       })
  }

useEffect(() => {
            let compoentMount = true;
          const getUserInfo = async () =>{
          const resData = await axios.get(ApiName,{
            headers: { 'Content-Type': 'application/json'},
            withCredentials:true
          });
          if(compoentMount){
          if(resData.data){
          setParentName(resData.data.result[0].fnames);
          }
        }

          }
          getUserInfo();

          setInterval(()=>{
          notificationSystem();
          },2000);

          return () =>{
              compoentMount = false;
          }

},[])
    return (
        <div>
  <header id="page-topbar">
    <div className="navbar-header">
      <div className="d-flex">
        {/* LOGO */}
        <div className="navbar-brand-box">
              <div className="logo logo-dark">
                <span className="logo-sm">
                  <img src="/autheme/assets/images/fg.png" alt="" height={40} />
                </span>
                <span className="logo-lg">
                  <img src="/autheme/assets/images/eq4.png" alt="" height={40} />
                </span>
              </div>
              <div href="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src="/autheme/assets/images/fg.png" alt="" height={40} />
                </span>
                <span className="logo-lg">
                  <img src="/autheme/assets/images/eq4.png" alt="" height={40} />
                </span>
              </div>
            </div>
        <button type="button" className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
          <i className="fa fa-fw fa-bars" />
        </button>
        {/* App Search*/}
       
       
      </div>
      <div className="d-flex">
        <div className="dropdown d-inline-block d-lg-none ml-2">
        
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">
            
          </div>
        </div>
       
        <div className="dropdown d-inline-block">
          <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="bx bx-bell bx-tada" />
            { notifycount === 0 ? null : <span className="badge bg-danger rounded-pill"> {notifycount} </span>}
          </button>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">
            <div className="p-3">
              <div className="row align-items-center">
                <div className="col">
                  <h6 className="m-0"> Notifications </h6>
                </div>
                <div className="col-auto">
                  <Link to="/notification" className="small" key="t-view-all"> View All</Link>
                </div>
              </div>
            </div>
            
            { notidat !== null ? <center><p>{ notidat }</p></center> :
              
              <div data-simplebar style={{maxHeight: '430px'}}>
                { notifiData && notifiData.map(({note,status,subject,date_time,notify_id},index) =>{

                       return (
                        <div data-simplebar key={index}>
                        <Link to={"/notification/"+notify_id}  className="text-reset notification-item" style={{cursor:'pointer'}}>
                        <div className="media">
                        <div className="avatar-xs me-3">
                                                <span className="avatar-title bg-success rounded-circle font-size-16">
                                                    <i className="bx bx-bell"></i>
                                                </span>
                                            </div>
                          <div className="media-body">
                            <h6 className="mt-0 mb-1">{ subject }</h6>
                            <div className="font-size-12 text-muted">
                              <p className="mb-1" key="t-simplified">{ note }</p>
                              <p className="mb-0"><i className="mdi mdi-clock-outline" /> <span key="t-hours-ago">{ date_time }</span></p>
                            </div>
                          </div>
                        </div>
                        </Link>
                       </div>
                       )
                })
                  
                }
               
                  </div>
}
             
           


            
            <div className="p-2 border-top d-grid">
              <Link to="/notification" className="btn btn-sm btn-link font-size-14 text-center">
                <i className="mdi mdi-arrow-right-circle me-1" /> <span >View More..</span> 
              </Link>
            </div>
          </div>
        </div>
        

        <div className="dropdown d-inline-block">
          <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
           
            <span className="d-none d-xl-inline-block ms-1" style={{textTransform:'uppercase', color:'black'}}>{ parentname }</span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </button>
          
          <div className="dropdown-menu dropdown-menu-end">
         
            <div className="dropdown-item text-danger" onClick={()=>logout()} style={{cursor:'pointer'}}><i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" /> <span key="t-logout">Logout</span></div>

</div>
        </div>
       
      </div>
    </div>
  </header>
  <div className="topnav">
    <div className="container-fluid">
      <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
        <div className="collapse navbar-collapse" id="topnav-menu-content">
          <ul className="navbar-nav">

            <li className="nav-item">
              <Link className="nav-link " to="/parentdashoard" role="button">
                <i className="bx bx-home-circle me-2" /><span>Dashboard</span>
              </Link>
              
            </li>


            <li className="nav-item dropdown">
   
              <div className="nav-link dropdown-toggle arrow-none" id="topnav-learner" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                <i className="bx bx-collection me-2" /><span key="t-leaner">Learners</span> <div className="arrow-down" />
              </div>

              <div className="dropdown-menu" aria-labelledby="topnav-learner">
                <div className="dropdown">
                  <Link className="dropdown-item" to="/student/all-student">
                    <span>All Student</span> 
                  </Link>
                 
                </div>
                <div className="dropdown">
                <Link className="dropdown-item" to="/student/add-student">
                    <span>Add Student</span> 
                  </Link>
                 
                </div>

            
  
                </div>
           
           </li>

        
        
           <li className="nav-item dropdown">
              <div className="nav-link dropdown-toggle arrow-none" id="topnav-facilitator" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="bx bx-file me-2" /><span>Facilitator</span> <div className="arrow-down" />
              </div>

              <div className="dropdown-menu" aria-labelledby="topnav-facilitator">
                
               

              <div className="dropdown">
                  <Link className="dropdown-item" to="/facilitator-support">
                    <span>Support</span>
                  </Link>
                </div>



                <div className="dropdown">
                <Link className="dropdown-item" to="/order-management">
                    <span>Order Management</span>
                  </Link>
                 
                </div>
             
                
             
              </div>
            </li>
           
          
            <li className="nav-item dropdown">
        <div className="nav-link dropdown-toggle arrow-none" href="#" id="topnav-ui" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i className="bx bx-file me-2" /><span>Academics</span> <div className="arrow-down" />
        </div>
        <div className="dropdown-menu mega-dropdown-menu dropdown-mega-menu-xl" aria-labelledby="topnav-ui">
          <div className="row">
            <div className="col-lg-4">
            <h5 class="card-title mb-4" style={{marginLeft:20,fontSize:14}}>Invigilator Download</h5>
              <div style={{marginTop:-20}}>
                   <Link to="/invigilator-download/Task" className="dropdown-item">Task</Link>
                  <Link to="/invigilator-download/Assignment" className="dropdown-item" >Assignment</Link>
                  <Link to="/invigilator-download/Project" className="dropdown-item" >Project</Link>
                  <Link to="/invigilator-download/Practical" className="dropdown-item">Practical</Link>
                  <Link to="/invigilator-download/Test" className="dropdown-item">Test</Link>
                  <Link to="/invigilator-download/Examination" className="dropdown-item" >Examination</Link>
                      
              </div>
            </div>
            <div className="col-lg-4">
            <h5 class="card-title mb-4" style={{marginLeft:20,fontSize:14}}>Upload Assessment</h5>
            <div style={{marginTop:-20}}>
                 <Link to="/upload-assessment/Task" className="dropdown-item">Task</Link>
                  <Link to="/upload-assessment/Assignment" className="dropdown-item" >Assignment</Link>
                  <Link to="/upload-assessment/Project" className="dropdown-item" >Project</Link>
                  <Link to="/upload-assessment/Practical" className="dropdown-item">Practical</Link>
                  <Link to="/upload-assessment/Test" className="dropdown-item">Test</Link>
                  <Link to="/upload-assessment/Examination" className="dropdown-item" >Examination</Link>
                    
              </div>
            </div>
            <div className="col-lg-4">
            <h5 class="card-title mb-4" style={{marginLeft:20, fontSize:14}}>Capture Assessment</h5>
            <div style={{marginTop:-20}}>
            <Link to="/assessment-capture/task" className="dropdown-item">Task</Link>
                  <Link to="/assessment-capture/Assignment" className="dropdown-item" >Assignment</Link>
                  <Link to="/assessment-capture/Project" className="dropdown-item" >Project</Link>
                  <Link to="/assessment-capture/Practical" className="dropdown-item">Practical</Link>
                  <Link to="/assessment-capture/Test" className="dropdown-item">Test</Link>
                  <Link to="/assessment-capture/Examination" className="dropdown-item" >Examination</Link>
                 
              </div>
            </div>

            <div className="col-lg-12">
                 <hr style={{marginLeft:10, marginRight:10}}></hr>
              </div>

            <div className="col-lg-4">
           
            <div style={{marginTop:-10}}>
            <Link className="dropdown-item" to="/assessment-summary" role="button">
                    <span >View Assessment Summary</span> 
                  </Link>
                  <Link className="dropdown-item " to="/academics/term-final" >
                    <span>Term &amp; Final</span> 
                  </Link>

                
              </div>
            </div>
          </div>
        </div>
      </li>


            <li className="nav-item dropdown">
              <div className="nav-link dropdown-toggle arrow-none"  id="topnav-finance" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="bx bx-collection me-2" /><span >Finance</span> <div className="arrow-down" />
              </div>
              <div className="dropdown-menu" aria-labelledby="topnav-finance">
               
                  <Link className="dropdown-item" to="/finance/paid-invoice">
                    <span>Paid Invoice</span> 
                  </Link>
                 
               
               
                <Link className="dropdown-item" to="/finance/unpaid-invoice">
                    <span>Unpaid Invoice</span> 
                  </Link>
                 
               
  
                </div>
           
           </li>

           <li className="nav-item dropdown">
              <Link className="nav-link " to='/mywallet' role="button">
              <i className="bx bx-wallet font-size-16 align-middle me-1"></i> <span key="t-my-wallet">My Wallet</span>
              </Link>
              </li>
           
              <li className="nav-item">
              
              <Link className="nav-link" to="/user-settings"><i className="bx bx-wrench me-2" /> <span key="t-settings">Settings</span></Link>
              
            </li>

           
          </ul>
        </div>
      </nav>
      { loader === false ? null : 
      <div id="preloader-spinner" className="preloader spinner">

<div className="wrapper">
    <span className="loader"></span>

    <p style={{marginTop:120}}>Login you out. Please wait...</p>
</div>

</div>

}

    </div>
  </div>
</div>

    )
}

export default NavHead
