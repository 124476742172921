import React, { useState } from 'react';
import { Link} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';

function CompleteParentReg({companyName,myOption,myuserid}) {

    const curDate = new Date().getFullYear();
      const compUrl = "/registration/category";
      const [notify,setNotify] = useState(null);
      const [combut, setComBut] = useState(false);
     

    const Validschema = Yup.object().shape({
        fullName: Yup.string()
        .required('Full name is required'),

        passNumb: Yup.string()
        .required('Passport/ID No. is required'),

        email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),

        phone: Yup.string()
        .required('Phone number is required'),

        address: Yup.string()
        .required('Address is required'),

        userType: Yup.string()
        .required('userType is required'),

        parentid: Yup.string()
        .required('parent is required'),

      });

      const formOptions = { resolver: yupResolver(Validschema) };
      const {register, handleSubmit, formState } = useForm(formOptions);
    const {errors} = formState;



    const handleSubmitParent = (data) =>{
        setComBut(true);
   axios.post(compUrl,data,{
    headers: { 'Content-Type': 'application/json'}
 }).then((response)=>{
        setTimeout(()=>{
          setComBut(false);
          switch(response.data.auth){   
            case 'allow':
             setNotify(<div>Parent registration is complete. Click <Link to="/login">here to login</Link></div>);
              break;
              case 'not allow':
               setNotify(response.data.message);
                break;
                default:
                  setNotify(null);
        }
        },2000);
           
      
   })
}
      

    return (
   
        <div>
        <div id="layout-wrapper">
        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center mb-5 text-muted">
                  <div className="d-block auth-logo">
                  <img src="/autheme/assets/images/eq4.png" alt="" height={50} className="auth-logo-dark mx-auto" />
                  <img src="/autheme/assets/images/eq4.png" alt="" height={50} className="auth-logo-light mx-auto" />
                  </div>
                  <p className="mt-3">Complete your home tutor registration</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title" id="title">{myOption} Information </h5>
                    <p className="card-title-desc">Provide all details to complete your registration</p>
                    { notify !== null ? (<div className="alert alert-warning">{notify}</div>) : null}
                    <form onSubmit={handleSubmit(handleSubmitParent)}>
    
                    <input type="hidden" name="userType" id="userType" defaultValue="Parent" {...register('userType')} />
                    <input type="hidden" name="parentid" id="parentid" defaultValue={myuserid} {...register('parentid')} />
                    <div className="row">
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="text" name="fullName" id="fullName" placeholder="Enter Full Name" {...register('fullName')} className={`form-control ${errors.fullName ? 'is-invalid' : ''}`}  />
                            <label htmlFor="floatingemailInput">Full Name</label>
                            <div className="invalid-feedback">{errors.fullName?.message}</div>
                          </div>
                          
                        </div>
    
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="text"  name="passNumb" id="passNumb" placeholder="Enter ID/Passport Number" {...register('passNumb')} className={`form-control ${errors.passNumb ? 'is-invalid' : ''}`}   />
                            <label htmlFor="floatingemailInput">Whatsapp Number</label>
                            <div className="invalid-feedback">{errors.passNumb?.message}</div>
                           
                          </div>
                        </div>
                      </div>
                     
                 
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="email"  name="email" id="email" placeholder="Enter Email address" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`}  />
                            <label htmlFor="floatingemailInput">Email address</label>
                            <div className="invalid-feedback">{errors.email?.message}</div>
                          </div>
                         
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input type="text"  name="phone" id="phone" placeholder="Enter Phone"  {...register('phone')} className={`form-control ${errors.phone ? 'is-invalid' : ''}`}  />
                            <label htmlFor="floatingemailInput">Phone Number</label>
                            <div className="invalid-feedback">{errors.phone?.message}</div>
                          </div>
                         
                        </div>
                      </div>
                     
                     
                      <div className="form-floating mb-3">
                        <input type="text" name="address" id="address" placeholder="Enter Residential Address"  {...register('address')} className={`form-control ${errors.address ? 'is-invalid' : ''}`} />
                        <label htmlFor="floatingnameInput">Address</label>
                        <div className="invalid-feedback">{errors.address?.message}</div>
                      </div>
    
                      
                     
                      <div style={{float: 'right'}}>
                          { combut === false ?
                        <button type="submit" className="btn btn-primary w-md submit">Submit</button>
                              :
                        <button className="btn btn-primary waves-effect waves-light" disabled>
    
                                    <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                       Please Wait...</button>
           }
                  
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
         </div>
          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  { curDate } © {companyName}
                </div>
                <div className="col-sm-6">
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
        </div>
     )
}

export default CompleteParentReg
