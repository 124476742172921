import axios from 'axios';
import React,{ useState, useEffect} from 'react'
import {Link} from 'react-router-dom'


function Tdashboard() {
      const [centreData, setCentreData] = useState();
      const [centNotify, setCentNotify] = useState(null);
      const [countCentre, setCountCentre] = useState(0);
      const [name, setName] = useState();
      const [email, setEmail] = useState();
      const [id, setID] = useState();
      const [wallet, setWallet] = useState();
      const [totalW, setTotalW] = useState();
      axios.defaults.withCredentials = true;


      const convertCurrency = (num) =>{
        return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }
     

    useEffect(()=>{
      const fetchTutor = () =>{

        axios.get('/fetchuser-data',{
          headers: { 'Content-Type': 'application/json'}
       })
        .then((report)=>{
              setName(report.data.userData[0].name);
              setEmail(report.data.userData[0].email);
              setID(report.data.userData[0].userid);
              setWallet(report.data.userData[0].wallet);

              let total =0;
               let trxData = report.data.transData;
                  trxData && trxData.map((m)=>(
                         total = parseInt(total) + parseInt(m.amount)
                        
                  ))
                    setTotalW(total);
        })
       }


       const fetchCentre = () =>{
        axios.get('/fetchcentres-users',{
          headers: { 'Content-Type': 'application/json'}
       })
        .then((response)=>{
              if(response.data.centres === false){
                  setCentNotify(<div className="alert alert-info">Register a new tutor centre for free &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/centre/all-centre" className="btn btn-primary waves-effect waves-light btn-sm">Register here</Link></div>)
              }else
              {
                    setCentreData(response.data.centres); 
              }


              setCountCentre(response.data.numberCentre);

          
        });


 }


            fetchCentre();
            fetchTutor();
    },[])

    return (
        <div>
            

         <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Dashboard</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                    
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>



            <div className="row">


            <div className="col-xl-4">
        <div className="card">
          <div className="card-body border-bottom">
           
            <div>
              <div className="mb-4 me-3">
                <i className="mdi mdi-account-circle text-primary h1" />
              </div>
              <div>
                <h5>{ name }</h5>
                <p className="text-muted mb-1">{ email }</p>
                <p className="text-muted mb-0">PROFILE ID NO: #{ id }</p>
              </div>
            </div>
          </div>
          <div className="card-body border-bottom">
            <div>
              <div className="row">
                <div className="col-sm-6">
                  <div>
                    <p className="text-muted mb-2">Available Balance</p>
                    <h5>{ wallet === null ? 'R0.00' : convertCurrency(parseInt(wallet))}</h5>
                  </div>
                </div>
                <div className="col-sm-6">
                  
                </div>
              </div>
            </div>
          </div>
        </div>

    
      </div>
           
      <div className="col-xl-8">
        <div className="row">
         
          <div className="col-md-6">
            <div className="card mini-stats-wid">
              <div className="card-body">
                <div className="media">
                  <div className="media-body">
                    <p className="text-muted fw-medium">Total Withdrawal</p>
                    <h4 className="mb-0">{ totalW === 0 ? 'R0.00' :convertCurrency(parseInt(totalW)) }</h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg-primary">
                      <i className="bx bx-archive-in font-size-24" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mini-stats-wid">
              <div className="card-body">
                <div className="media">
                  <div className="media-body">
                    <p className="text-muted fw-medium">Total Centre Registered</p>
                    <h4 className="mb-0">{ countCentre } Centre(s) </h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg-primary">
                      <i className="bx bx-purchase-tag-alt font-size-24" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      

              
           <div className="col-md-12">

           { centNotify !== null ? centNotify : null }

<div className="table-responsive">
  <table className="table project-list-table table-nowrap align-middle table-borderless">
    <thead>
      <tr>
        <th scope="col" style={{width: '100px'}}>#</th>
        <th scope="col">Centre Names</th>
        <th scope="col">Centres Email</th>
        <th scope="col">Centres Phone</th>
        <th scope="col">Date Created</th>
      
      </tr>
    </thead>
    <tbody>
              { centNotify !== null ? null : 

               centreData && centreData.map(({centreName, centrePhone, centreEmail, centreAddress, date_reg},index) => {
                        return (
        <tr key={index}>
              
              <td>
             { 1 + index}
                 </td>
              <td>
                <h5 className="text-truncate font-size-14"><div className="text-dark">{ centreName }</div></h5>
                <p className="text-muted mb-0"> { centreAddress }</p>
              </td>
              <td>
              { centreEmail }
                  </td>
                  <td>
              { centrePhone }
                  </td>
              <td>
              { date_reg }
              </td>
              
             
             
            </tr>
                        )
               })
              
                    }
         
    </tbody>
  </table>
</div>


     </div>
  
      
        </div>
        {/* end row */}
       
      </div>
          
           
            </div>




              </div>
          </div>
       </div>
           


        </div>
    )
}

export default Tdashboard
