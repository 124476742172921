import React,{ useState, useEffect} from 'react'
import axios from 'axios';

function Wallet() {
  const convertCurrency = (num) =>{
    return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }


     const [userName, setUsername] = useState();
     const [balance, setBalance] = useState();
     const [statusDepo,setStatusDepo] = useState(false);
    
     const [transStatus, setTransStatus] = useState(null);
     const [ transaction, setTransaction ] = useState();
     const [expenses, setExpenses] = useState();
     const [userid, setUserID] = useState();

     const [withdraw, setWithdraw] = useState('');
     const [noteW, setNoteW] = useState(null);
     const [wload, setWload] = useState(false)

     const [amountWith, setAmountWith] = useState();
     const [dateWith, setDateWith] = useState();
     const [withStatus, setWithStatus] = useState();
     const [withID, setWithID] = useState();
     const [withd, setWithD] = useState(false);

     const formatAMPM = (date) => {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      let strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    }


    const displayAccount = (mds) =>{
            switch(mds){
                case'open':
                   setStatusDepo(true);
                break;
                case'close':
                setStatusDepo(false);
                break;
            }
    }

    const RemoveRequest = (id) =>{
        
           axios.post('remove-withdrawal-request',{
              id:id
           },{
            headers: { 'Content-Type': 'application/json'}
           }).then((res)=>{
               if(res.data.withd === false){
                window.location.reload(false);
               }
               
           })
    }

    const checkBalance = () =>{
      if(!withdraw.trim()){
        setNoteW('Please enter the amount you want to withdraw');
        setTimeout(()=>{
            setNoteW(null);
        },5000)
    }
    else
    {
         
          if( withdraw > balance ){

            setNoteW('Unable to complete request due to insufficient fund');
            setTimeout(()=>{
                setNoteW(null);
            },5000);

          }else{
            setWload(true)
            setNoteW(null);
        let request_time = formatAMPM(new Date());
        let today = new Date();
        let currdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let total_date = currdate+' '+request_time;
       axios.post('/request-withdraw',{
             amount:withdraw,
             date:total_date
       },{
        headers: { 'Content-Type': 'application/json'}
     })
       .then((response)=>{
            setTimeout(()=>{
                setWload(false);
                 if(response.data.withbody === false){
                    setWithD(false);
                    setNoteW('Unable to process your request due to server error');
                 } else {
                    setWithD(true);
                    setAmountWith(response.data.withbody[0].amount);
                    setDateWith(response.data.withbody[0].date_request);
                    setWithStatus(response.data.withbody[0].status);
                    setWithID(response.data.withbody[0].request_id);
                    setNoteW('Withdrawal request has been submitted successfully');
                    
                 }    
            },2000);
       })    

    }
    }
    }

    const checkWithdrawStatus = () =>{
     
  axios.get('/withdrawal-status',{
    headers: { 'Content-Type': 'application/json'}
 })
  .then((response)=>{
        setTimeout(()=>{
             
          if(response.data.withState === false){
              setWithD(false);

      }else{
         setWithD(true);
         setAmountWith(response.data.withState[0].amount);
         setDateWith(response.data.withState[0].date_request);
         setWithStatus(response.data.withState[0].status);
         setWithID(response.data.withState[0].request_id);
      }
        },2000);
     
  })
}


      useEffect(()=>{
             axios.get('/get-users-information',{
              headers: { 'Content-Type': 'application/json'}
           })
             .then((response)=>{

                            
                        setUsername(response.data.userData[0].fnames);
                        setBalance(response.data.userData[0].wallet);
                        
                        setUserID(response.data.userData[0].userid);
                        
                         if(response.data.userData[0].wallet === null){
                            setBalance("0.00");
                         }else
                         {
                            setBalance(response.data.userData[0].wallet);
                         }



                          if(response.data.transData === false){
                                     setTransStatus(null);
                                     setExpenses(0);
                                     
                          }else{
                                        setTransStatus('yes');

                                        let resp = response.data.wall;
                                         if(resp === false){
                                            setExpenses(0)
                                         }else{
                                        let total_exp = 0;
                                         resp.map((amt)=>(
                                                total_exp = parseInt(total_exp) + parseInt(amt.amount)
                                         ))

                                         setExpenses(total_exp);

                                         }

                                          
                                           setTransaction(response.data.transData);
                                         
                          }
             });

             checkWithdrawStatus();
      },[])
    return (
        <div>
           
            <div className="main-content">

            <div className="page-content">
                <div className="container-fluid">

           
                <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">Wallet</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">Wallet</li>
                <li className="breadcrumb-item active">My Wallet</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

                
      <div className="row">
        <div className="col-xl-1"></div>
      <div className="col-xl-4">
        <div className="card">
          <div className="card-body border-bottom">
            
            <div>
             
              <div className="mb-4 me-3">
                <i className="mdi mdi-account-circle text-primary h1" />
              </div>
              
              <div>
                <h5>{ userName }</h5>
                <p className="text-muted mb-1">Profile ID: { userid }</p>
              </div>

            </div>
          </div>
          <div className="card-body border-bottom">
            <div>
              <div className="row">
                <div className="col-sm-6">
                  <div>
                    <p className="text-muted mb-2">Available Balance</p>
                    <h5>{ convertCurrency(parseInt(balance)) }</h5>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-end mt-4 mt-sm-0">
                    <p className="text-muted mb-2">Total money spent</p>
                    <h5>{ expenses === 0 ? 'R0.00' : convertCurrency(parseInt(expenses)) }  </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       
      </div>


        <div className="col-xl-7">
          <div className="card">
            <div className="card-body">
              
              <div>
              
                <h5 className="font-size-14 mb-4">Add money to your wallet</h5>
              </div>
              <div className="mt-4">
           
          {/*<a rel="noopener noreferrer" href={"https://payment.equationeducators.com?rout="+userid} target="_blank" className="btn btn-primary waves-effect waves-light btn-sm">CARD PAYMENT <i class="mdi mdi-arrow-right ms-1"></i></a>
           &nbsp;&nbsp;&nbsp; */}

           { statusDepo === false ?
                     <button type="button" onClick={()=>displayAccount('open')} className="btn btn-primary waves-effect waves-light btn-sm">BANK DEPOSIT / EFT PAYMENT
                      <i class="mdi mdi-arrow-right ms-1"></i></button>
                      :
                      <button type="button" onClick={()=>displayAccount('close')} className="btn btn-primary waves-effect waves-light btn-sm">CLOSE
                      </button>
              }
               &nbsp;&nbsp;&nbsp;
               { withd === false ?

<button type="button" className="btn btn-primary waves-effect waves-light btn-sm" data-bs-toggle="modal" data-bs-target="#staticBank">Withdrawal Request
                      <i class="mdi mdi-arrow-right ms-1"></i></button> : null }
                                        
                      { statusDepo === false ? null
                : 
                
                <div className="tab-content mt-4">
                <div className="tab-pane active" id="buy-tab" role="tabpanel">
            
                    <p>To deposit to your wallet. Kindly make a payment to the account details below. Use your wallet ID as reference <strong>{ userid }</strong> or your registered full-name {userName} </p>
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>
                          Bank Name
                          </td>
                          <td>
                          FNB/RMB
                          </td>
                        </tr>

                        <tr>
                          <td>
                          Account Holder
                          </td>
                          <td>
                          Fast Excellence 
                          </td>
                        </tr>

                        <tr>
                          <td>
                          Account Type
                          </td>
                          <td>
                          Business Account 
                          </td>
                        </tr>
                        
                        <tr>
                          <td>
                          Account Number
                          </td>
                          <td>
                          62891239511
                          </td>
                        </tr>

                        <tr>
                          <td>
                          Branch Code
                          </td>
                          <td>
                          250655
                          </td>
                        </tr>

                      </tbody>
                    </table>


                  </div>
              

              </div>
             
                }


{ withd !== false ?
              <div className="table-responsive">
                <br /><br />
                   <h6 className="title mb-2">Withdrawal Request Status</h6>
                  <table className="table table-nowrap align-middle mb-0">
                  <thead>
                        <tr>
                            <th scope="col">#Request ID</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Date &amp; Time</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>                   
                        </tr>
                     </thead>
                     <tbody>
                         <tr>
                                                        <td>
                                                             <div className="text-muted">
                                                                { withID }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            
                                                            <div className="text-muted">{ convertCurrency(parseInt(amountWith)) }</div>
                                                        </td>
                                                        <td>
                                                            
                                                            <div className="text-muted">{ dateWith }</div>
                                                        </td>
                                                        <td>
                                                        <span className="badge bg-danger">
                                                                { withStatus}
                                                            </span>
                                                        </td>

                                                        <td>
                                                        <button type="button" className="btn btn-light btn-sm btn-rounded waves-effect waves-light" onClick={()=>RemoveRequest(withID)}>
                                                                         Cancel Request
                                                                          </button>
                                                        </td>
                         </tr>
                     </tbody>
                  </table>
                  
                  </div>
                    : null }


              </div>
            </div>
          </div>
        </div>
     

     
     
      </div>
                
               

      <div className="row">

        <div className="col-xl-1">
         
            
        </div>
       
       
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">Wallet Activity</h4>
              <div data-simplebar style={{maxHeight: '310px'}}>

                  { transStatus === null ? <div>
                    <p>No transaction at this moment</p>
                  </div> : 
                 <div>
                <ul className="verti-timeline list-unstyled">
                
                { transaction && transaction.map(trx=>(
                <li className="event-list" key={trx.id}>
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle font-size-18" />
                </div>
                <div className="media">
                  <div className="me-3">
                    <h5 className="font-size-14">{ trx.date_trans } <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" /></h5>
                  </div>
                  <div className="media-body">
                    <div>
                    { trx.details +" ("+trx.trans_type+")"} 
                    </div>
                  </div>
                </div>
                </li>
   ))}
            
                 </ul>
                
                </div>
}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end row */}


      <div className="modal fade" id="staticBank" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Withdrawal Request</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            
            <div className="modal-body">
            
                   

            <div>
        <div className="row">
          <div className="col-lg-12">
            <div>
             
                     { noteW === null ? null : <div className="alert alert-info text-center">{noteW} </div>}
            
                    <div className="input-group mb-3">
                             <label className="input-group-text">Amount</label>
                             <input type="number" className="form-control" 
                      onChange={(e)=> setWithdraw(e.target.value)}
                       value={withdraw}
                       placeholder="Enter Amount" autoComplete="off" />
                                    <label className="input-group-text">R</label>
                    </div>


              
            </div>
          </div>
       
        </div>
       


      </div>

           
            </div>
            
            <div className="modal-footer">
             
              <button type="button" className="btn btn-light btn-sm btn-rounded waves-effect waves-light" data-bs-dismiss="modal">
                            Close
                            </button>

              { wload === false ?
              <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" onClick={()=> checkBalance()} >
                            Submit Dateils
                            </button>
                      :
              <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" disabled>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Processing...
                            </button>

              }
                    
            </div>
          
          </div>
        </div>
      </div>




               
                 </div>
             </div>
          </div>
                        
        </div>
    )
}

export default Wallet
