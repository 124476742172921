import React, { useEffect, useState} from 'react'
import axios from 'axios';

function Settings() {

  const getUser = "/mydata/parent";
     const [fnames, setFnames] = useState('')
     const [email, setEmail] = useState('');
      const [phone, setPhone] = useState('');
      const [address, setAddress] = useState('');

      const [fnamesError, setFirstNameError] = useState(null);
      const [semailError, setEmailError] = useState(null);
      const [sphoneError, setPhoneError] = useState(null);
      const [addressError, setLocationError] = useState(null);
      const [notify, setNotify] = useState(null);
      const [upsub, setUpSub] = useState(false);

      const [loginEmail, setLoginEmail] = useState('');

      const [oldPassword, setOldPassword] = useState('');
      const [newPassword, setNewPassword] = useState('');
      const [conPassword, setConPassword] = useState('');

     
      const [notiEmail, setNotiEmail] = useState(null);
      const [notiPass, setNotiPass] = useState(null);

      const [upEma, setUpEma] = useState(false);
      const [upPass, setUpPass] = useState(false);

      const [bankName, setBankName] = useState('');
      const [bankHolder, setBankHolder] = useState('');
      const [bankNumber, setBankNumber] = useState('');
      const [branchCode, setBranchCode] = useState('');
      const [bankStatus, setBankStatus] = useState(false)
      const [mload,setMLoad] = useState(false)
      const [hstatus, setHStatus] = useState('Add Bank information');
      const [notes, setNotes] = useState(null);

      const validateInput = () =>{
        let valid = true;
          if(!fnames.trim()){
               setFirstNameError('Please provide first name');
              valid = false;
               
          }else{
           setFirstNameError(null);
             valid = true;

          }


       if(!email.trim()){
         setEmailError('Please provide email address');
         valid = false;
        }else if (!/\S+@\S+\.\S+/.test(email)){
         setEmailError('Please valid email address');
        valid = false;

        }else
        {
         setEmailError(null);
         valid = true;
        }

     if(!phone.trim()){
       valid = false;
       setPhoneError('Please provide phone number');
       }else{
         setPhoneError(null);
      valid = true;
        }

        if(!address.trim()){
         setLocationError('Please provide your location');
         valid = false;
         }else{
           setLocationError(null);
        valid = true;
          }


           return valid;
   }


   const updateUserPassword = () => {
          if(oldPassword ==='' || newPassword==='' || conPassword===''){
              setNotiPass('All password input must not be empty');
          }else{
            if(newPassword !== conPassword){
                setNotiPass('Your new and confirm password did not match');
            }else{
              setUpPass(true);
          axios.post('/security-update',{
             oldpass:oldPassword,
             newpass:newPassword,
             conpass:conPassword
          },{
            headers: { 'Content-Type': 'application/json'}
         })
          .then((response)=>{
                  setTimeout(()=>{
                       setUpPass(false);
                    setNotiPass(response.data.message);
                  },2000);
                    
          });
        }
      }
   }


   const getLoginEmail = () =>{
         axios.get('/get-user-data',{
          headers: { 'Content-Type': 'application/json'}
       })
         .then((respond)=>{
           
                setLoginEmail(respond.data.userData[0].email);
         })
   }

  

   const handleUpdate = () =>{
        if(!validateInput()){}else{
              setUpSub(true);
          axios.put('/update-personal-info',{
             fname:fnames,
             email:email,
             phone:phone,
             address:address,
             utype:'parent'
          },{
            headers: { 'Content-Type': 'application/json'}
         }).then((resp)=>{
                setTimeout(()=>{
                    setUpSub(false);
                  setNotify(resp.data.message);
                },2000);
                
          });


        }
   }

   const handleEmailUpdate = () =>{
         if(!loginEmail.trim()){
          setEmailError('Please valid email address');
         }else if (!/\S+@\S+\.\S+/.test(loginEmail)){
          setEmailError('Please valid email address');
         }else
         {
            setUpEma(true);
            axios.post('/update-user-login',{
                loginEmail:loginEmail
            },{
              headers: { 'Content-Type': 'application/json'}
           })
            .then((respond)=>{
                setTimeout(()=>{
                  setUpEma(false);
                  setNotiEmail(respond.data.message);
                },2000);
                 
            })

         }
   }

   const addBankData = () =>{
    if(bankHolder ==='' || bankName==='' || bankNumber==='' || branchCode===''){
            setNotes(<div className="alert alert-info text-center">Please all form field must be fill correctly</div>)
    }else
    {
        setMLoad(true)
         const mData = {
               bankholder:bankHolder,
               bankName:bankName,
               bankNumber:bankNumber,
               branchCode:branchCode
         }
             axios.post('/add-bank-data',mData,{
              headers: { 'Content-Type': 'application/json'}
             }).then((resp)=>{
                 setTimeout(()=>{
                  setMLoad(false)

                  if(resp.data.bData === false){
                    setNotes(<div className="alert alert-info text-center">Server error occur when processing your details</div>)
                   }else{
                    setNotes(<div className="alert alert-info text-center">{resp.data.bData}</div>);
                      setBankStatus(null);

                      setBankNumber(bankNumber)
                      setBankHolder(bankHolder)
                      setBankName(bankName);
                      setBranchCode(branchCode);


                   }

                 },2000)
             
             })
    }
}


const getUserBankData = () => {
     
  axios.get('/display-bank-data',{
    headers: { 'Content-Type': 'application/json'}
  }).then((res)=>{
        if(res.data.bankData === false){
             
          setBankStatus(false);
        }else
        {
           setHStatus('Update Bank Information');
          setBankStatus(null);
          setBankNumber(res.data.bankData[0].account_num)
          setBankHolder(res.data.bankData[0].account_name)
          setBankName(res.data.bankData[0].bank_name);
          setBranchCode(res.data.bankData[0].branch_code);

        }
  })
}


  useEffect(()=>{
    getUserBankData();
    
    axios.get(getUser,{
      headers: { 'Content-Type': 'application/json'}
   }).then((response)=>{
    
    setFnames(response.data.parentData[0].fnames);
     setEmail(response.data.parentData[0].email);
     setPhone(response.data.parentData[0].phone);
     setAddress(response.data.parentData[0].address);
    
        //console.log(response.data.result[0].fnames);
                   
        });

     getLoginEmail();
},[])
    return (
        <div>

<div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">Account Settings</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">Dashboard</li>
                <li className="breadcrumb-item active">Settings</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

                        <div className="row">

                        <div className="col-xl-12">
                        { bankStatus === false ? <div className="alert alert-info text-center">
                          Add your bank account details to receive withdrawal fund easily
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button className="btn btn-primary waves-effect waves-light btn-sm" data-bs-toggle="modal" data-bs-target="#staticBank">Add here</button>
                        </div> : null }
                        </div>

                            <div className="col-xl-6">

                            <div className="card">
        <div className="card-body">
          <h4 className="card-title mb-4">Personal Information</h4>
    
          <div className="table-responsive">
            <table className="table table-nowrap mb-0">
              <tbody>
                <tr>
                  <th scope="row">Full Name :</th>
                  <td>{ fnames }</td>
                </tr>
                <tr>
                  <th scope="row">Mobile :</th>
                  <td>{ phone }</td>
                </tr>
                <tr>
                  <th scope="row">E-mail :</th>
                  <td>{ email }</td>
                </tr>
                <tr>
                  <th scope="row">Location :</th>
                  <td>{ address }</td>
                </tr>
              </tbody>
            </table>

            <div className="text-center" style={{marginTop:20}}>
                      <button type="button" className="btn btn-primary btn-sm waves-effect waves-light"  data-bs-toggle="modal" data-bs-target="#staticBackdrop">Edit Information</button>
                     


                    </div>
          </div>
        </div>
      </div>
                                
                                </div>
                                
                                
     <div className="col-xl-6">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title mb-4">Security</h4>
            
            <div className="mt-4">
              <ul className="nav nav-pills bg-light rounded" role="tablist">
                <li className="nav-item">
                  <div style={{cursor:'pointer'}} className="nav-link active" data-bs-toggle="tab" href="#buy-tab" role="tab">Update Login Email</div>
                </li>
                <li className="nav-item">
                  <div style={{cursor:'pointer'}} className="nav-link" data-bs-toggle="tab" href="#sell-tab" role="tab">Update Password</div>
                </li>
              </ul>
              <div className="tab-content mt-4">
                <div className="tab-pane active" id="buy-tab" role="tabpanel">
                  <h5 className="font-size-14 mb-4">Update Login Email</h5>
                  <div>
                    <div>
                     { notiEmail !== null ? <div className="alert alert-info">{notiEmail}</div> : null}
                        
                      <div className="input-group mb-3">
                        <label className="input-group-text">New Login Email</label>
                        <input type="text" className="form-control" value={loginEmail} onChange={(e)=> setLoginEmail(e.target.value)} />
                      </div>
                    </div>  
                    <div className="text-center">
                      { upEma === false ?
                      <button type="button" className="btn btn-success w-md" onClick={()=> handleEmailUpdate()}>Update Email</button>
                          :
                      <button type="button" className="btn btn-success w-md" disabled>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Updating...
                            </button>
}
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="sell-tab" role="tabpanel">
                  <h5 className="font-size-14 mb-4">Update Password</h5>
                  <div>
                    <div>
                      
                       {notiPass !== null ? <div className="alert alert-info">{notiPass}</div> : null}
                     
                      <div className="input-group mb-3">
                        <label className="input-group-text">Old Password</label>
                        <input type="password" className="form-control" onChange={(e)=>setOldPassword(e.target.value)} />
                      </div>


                      <div className="input-group mb-3">
                        <label className="input-group-text">New Password</label>
                        <input type="password" className="form-control" onChange={(e)=>setNewPassword(e.target.value)} />
                      </div>


                      <div className="input-group mb-3">
                        <label className="input-group-text">Confirm Password</label>
                        <input type="password" className="form-control" onChange={(e)=>setConPassword(e.target.value)} />
                      </div>
                    </div>  
                    <div className="text-center">
                      { upPass === false ?
                      <button type="button" className="btn btn-danger w-md" onClick={()=> updateUserPassword()}>Update Password</button>
                          :
                      <button type="button" className="btn btn-danger w-md" disabled>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Updating...
                            </button>
                            }
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
                                
                                </div>


        <div className="row">

<div className="col-xl-12">
     { bankStatus === false ? null :
<div className="table-responsive">
<table className="table project-list-table table-nowrap align-middle table-borderless">
<thead>
<tr>

<th scope="col">Bank Name</th>
<th scope="col">Bank Account Holder</th>
<th scope="col">Bank Account Number</th>
<th scope="col">Bank Branch Code</th>
<th scope="col">Action</th>

</tr>
</thead>
<tbody>
<tr>
<td>{bankName }</td>
<td>{bankHolder }</td>
<td>{bankNumber }</td>
<td>{branchCode }</td>
<td> <button className="btn btn-primary waves-effect waves-light btn-sm" data-bs-toggle="modal" data-bs-target="#staticBank">Edit Info</button>
</td>
</tr>
</tbody>
</table>
</div>
        }

</div>


</div>
      


                                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Update Personal Information</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            
            <div className="modal-body">
            
                    { notify !== null ? <div className="alert alert-info">{notify }</div> : null }

            <div>
        <div className="row">
          <div className="col-lg-12">
            <div>
              <label className="form-label">Full Name</label>
                     
              <input type="text" className="form-control" name="lastname" id="lastname"
                      onChange={ (e)=> setFnames(e.target.value) }
                       value={fnames}
                       placeholder="Enter First Name" autoComplete="off" />


                <small style={{color:'red'}}>{ fnamesError !== null ? fnamesError : null }</small>
            </div>
          </div>
       
        </div>
       

 

        <div className="row" style={{marginTop:20}}>
          <div className="col-lg-12 ">
            <div className="mb-4">
              <label className="form-label">Email</label>
             
              <input type="text" className="form-control" name="EmailAddress" id="email"
                      onChange={ (e)=> setEmail(e.target.value) }
                       value={email}
                       placeholder="Enter Email Address" autoComplete="off" />
             
              <small style={{color:'red'}}>{ semailError !== null ? semailError : null }</small>
            </div>
          </div>
       
        </div>
      

        <div className="row">
          <div className="col-lg-12 ">
            <div >
              <label className="form-label">Phone</label>

              <input type="text" className="form-control" name="Phone" id="Phone"
                      onChange={ (e)=> setPhone(e.target.value) }
                       value={phone}
                       placeholder="Enter phone" autoComplete="off" />
               <small style={{color:'red'}}>{ sphoneError !== null ? sphoneError : null }</small>
            </div>
          </div>
       
        </div>


        
        <div className="row" style={{marginTop:20}}>
          <div className="col-lg-12 ">
            <div >
              <label className="form-label">Home Address</label>
              
              <input type="text" className="form-control" name="homeAddress" id="HomeAddress"
                      onChange={ (e)=> setAddress(e.target.value) }
                       value={address}
                       placeholder="Enter Home Address" autoComplete="off" />
              
              <small style={{color:'red'}}>{ addressError !== null ? addressError : null }</small>
            </div>
          </div>
       
        </div>


      </div>

           
            </div>
            
            <div className="modal-footer">
             
              <button type="button" className="btn btn-light btn-sm btn-rounded waves-effect waves-light" data-bs-dismiss="modal">
                            Close
                            </button>

                { upsub === false ? 
              <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" onClick={()=>handleUpdate()}>
                            Update Dateils
                            </button>
                      :
              <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" disabled>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Updating...
                            </button>
                    }
            </div>
          
          </div>
        </div>
      </div>


             
             
      <div className="modal fade" id="staticBank" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">{ hstatus }</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            
            <div className="modal-body">
            
                    { notes !== null ? notes : null }

            <div>
        <div className="row">
          <div className="col-lg-12">
            <div>
              <label className="form-label">Bank Name</label>
                     
              <input type="text" className="form-control" 
                      onChange={ (e)=> setBankName(e.target.value) }
                       value={bankName}
                       placeholder="Enter Bank Name" autoComplete="off" />


              
            </div>
          </div>
       
        </div>
       

 

        <div className="row" style={{marginTop:20}}>
          <div className="col-lg-12 ">
            <div className="mb-4">
              <label className="form-label">Bank Account Holder</label>
             
              <input type="text" className="form-control" 
                      onChange={ (e)=> setBankHolder(e.target.value) }
                       value={bankHolder}
                       placeholder="Enter Bank Account Holder" autoComplete="off" />
             
             
            </div>
          </div>
       
        </div>
      

        <div className="row">
          <div className="col-lg-12 ">
            <div >
              <label className="form-label">Bank Account Number</label>

              <input type="number" className="form-control" 
                      onChange={ (e)=> setBankNumber(e.target.value) }
                       value={bankNumber}
                       placeholder="Enter Account Number" autoComplete="off" />
            
            </div>
          </div>
       
        </div>


        
        <div className="row" style={{marginTop:20}}>
          <div className="col-lg-12 ">
            <div >
              <label className="form-label">Bank Branch Code</label>
              
              <input type="text" className="form-control"
                      onChange={ (e)=> setBranchCode(e.target.value) }
                       value={branchCode}
                       placeholder="Enter Bank Branch Code" autoComplete="off" />
              
             
            </div>
          </div>
       
        </div>
      
      </div>

           
            </div>
            
            <div className="modal-footer">
             
              <button type="button" className="btn btn-light btn-sm btn-rounded waves-effect waves-light" data-bs-dismiss="modal">
                            Close
                            </button>

                { mload === false ? 
              <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" onClick={()=>addBankData()} >
                            Submit Dateils
                            </button>
                      :
              <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" disabled>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Processing...
                            </button>
                    }
            </div>
          
          </div>
        </div>
      </div>
              </div>
              </div>
              </div>
            
        </div>
    )
}

export default Settings
