import axios from 'axios';
import React,{useState} from 'react'
import { Link } from 'react-router-dom'


function ValidateEmail({sendCode,siteName}) {
             const curDate = new Date().getFullYear();
            const [email, setEmail] = useState('');
            const [notify, setNotify] = useState(null);
            const [loadData, setLoadData] = useState(false);

       const validateUserEmail = () =>{
        if(!email.trim()){
            setNotify('Please provide your email address');
        }else
        if (!/\S+@\S+\.\S+/.test(email)) {
          setNotify('Please provide a valid email address');       
        }else
        {
                setLoadData(true)
            axios.post('/password-reset-user',{
                   email:email
            },{
              headers: { 'Content-Type': 'application/json'}
           })
            .then((response)=>{
                   setTimeout(()=>{
                        setLoadData(false)
                    if(response.data.status=== 'allow'){
                        setNotify(response.data.message); 
                          
                         sendCode('code',email);

                    }else
                    {
                        setNotify(response.data.message);  
                    }
                   },2000);
                  
            })
            
        }
       }
    return (
        <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card overflow-hidden">
                <div className="bg-primary bg-soft">
                  <div className="row">
                    <div className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary"> Reset Password</h5>
                      
                      </div>
                    </div>
                    <div className="col-5 align-self-end">
                    <img src="autheme/assets/images/eq4.png" alt="" className="img-fluid" style={{marginTop:-50, marginLeft:-20}} />
               
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0"> 
                  <div>
                    <Link href="/forgot">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                        <img src="autheme/assets/images/eq1.png" alt="" className="rounded-circle" height={34} />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">

                    { notify !== null ?
                    <div className="alert alert-success text-center mb-4" role="alert">
                      { notify }
                    </div>
                    :
                     null }

                   
                      <div className="mb-3">
                        <label htmlFor="useremail" className="form-label">Email</label>
                        <input type="email" className="form-control" onChange={(e)=> setEmail(e.target.value)} placeholder="Enter email" />
                      </div>
                      <div className="text-end">
                        <button className="btn btn-primary w-md waves-effect waves-light" onClick={()=>validateUserEmail()} type="submit">Reset</button>
                      </div>
                  
                  </div>
                </div>
              </div>
              { loadData === false ? null : 
      <div id="preloader-spinner" className="preloader spinner">

<div className="wrapper">
    <span className="loader"></span>

    <p style={{marginTop:120}}>Processing your request. Please wait...</p>
</div>

</div>
}
              <div className="mt-5 text-center">
                <p>Remember It ? <Link to="/login" className="fw-medium text-primary"> Sign In here</Link> </p>
                <p>©  { curDate +' '+ siteName } </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    )
}

export default ValidateEmail
