import React from 'react'
import { Link } from 'react-router-dom'

function PasswordConfirm({sitename}) {
    const curDate = new Date().getFullYear();
    return (
        <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mb-5 text-muted">
                <Link to="/" className="d-block auth-logo">
                  <img src="autheme/assets/images/eq4.png" alt="" height={40} className="auth-logo-dark mx-auto" />
                  <img src="autheme/assets/images/eq4.png" alt="" height={40} className="auth-logo-light mx-auto" />
                </Link>
                <p className="mt-3">Password Reset</p>
              </div>
            </div>
          </div>
          {/* end row */}
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card">
                <div className="card-body"> 
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary" />
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Success !</h4>
                        <p className="text-muted">
                           Yey! Your password was updated successfully. You can login to your account to enjoy home tutoring.
                           </p>
                        <div className="mt-4">
                          <Link to="/login" className="btn btn-success">Take me to login</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <p>© { curDate+' '+sitename }</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default PasswordConfirm
