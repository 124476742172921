import axios from 'axios';
import React,{useState} from 'react'
import { Link } from 'react-router-dom'

function ValidatePass({userEmail,sendCode,siteName,coder}) {
    const curDate = new Date().getFullYear();
     const [newPass, setNewPass] = useState('');
     const [conPass, setConPass] = useState('');
     const [loadData, setLoadData] = useState(false);
     const [notify, setNotify] = useState(null);
     
     const validateUserPass = () =>{
                if(!newPass.trim() || !conPass.trim()){
                     setNotify('New and confirm password is required');
                }else
                {
                     if(newPass.length >= 6){
                            if(newPass !== conPass){
                                setNotify('New and confirm password did not match');
                            }else
                            {
                                   setLoadData(true)
                                 axios.put('/password-reset-continue',{
                                      email:userEmail,
                                      password1:newPass,
                                      code:coder
                                 },{
                                  headers: { 'Content-Type': 'application/json'}
                               }).then((response)=>{
                                       setTimeout(()=>{
                                             setLoadData(false)
                                        if(response.data.status === false){
                                            setNotify(response.data.message);
                                        } else
                                        {
                                             sendCode('success',userEmail,coder)
                                            setNotify(response.data.message);
                                        } 
                                       },2000)
                                         
                                 })
                            }

                     }else
                     {
                        setNotify('Password must be more than 6 character');
                     }
                }
     }

    return (
        <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card overflow-hidden">
                <div className="bg-primary bg-soft">
                  <div className="row">
                    <div className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary"> Reset Password</h5>
                        <p>Re-Password with { siteName }.</p>
                      </div>
                    </div>
                    <div className="col-5 align-self-end">
                    <img src="autheme/assets/images/eq4.png" alt="" className="img-fluid" style={{marginTop:-50, marginLeft:-20}} />
               
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0"> 
                  <div>
                    <Link href="/forgot">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                        <img src="autheme/assets/images/eq1.png" alt="" className="rounded-circle" height={34} />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">

                    { notify !== null ?
                    <div className="alert alert-success text-center mb-4" role="alert">
                      { notify }
                    </div>
                    :
                     null }

                   
                      <div className="mb-3">
                        <label htmlFor="useremail" className="form-label">New Password</label>
                        <input type="password" className="form-control" onChange={(e)=> setNewPass(e.target.value)} placeholder="Password" />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="useremail" className="form-label">Confirm Password</label>
                        <input type="password" className="form-control" onChange={(e)=> setConPass(e.target.value)} placeholder="Password" />
                      </div>

                      <div className="text-end">
                        <button className="btn btn-primary w-md waves-effect waves-light" onClick={()=>validateUserPass()} type="submit">Reset</button>
                      </div>
                  
                  </div>
                </div>
              </div>
              { loadData === false ? null : 
      <div id="preloader-spinner" className="preloader spinner">

<div className="wrapper">
    <span className="loader"></span>

    <p style={{marginTop:120}}>Reseting your password. Please wait...</p>
</div>

</div>
}
              <div className="mt-5 text-center">
                <p>Remember It ? <Link to="/login" className="fw-medium text-primary"> Sign In here</Link> </p>
                <p>©  { curDate +' '+ siteName } </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    )
}

export default ValidatePass
