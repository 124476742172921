import React, { useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../../Nav/Footer'
import Axios from 'axios'
import StudentProfile from './StudentProfile';
import ReactPaginate from 'react-paginate';
import '../../Authent/Loader.css';




function AllStudent() {
 
    Axios.defaults.withCredentials = true;
    const [profileStatus, setProfileStatus] = useState(false);
    const [loadProfile, setLoadProfile] = useState(false);
    const [studentInfo, setStudentInfo] = useState();
    const [mdata, setMdata] = useState(null);
    const [userload, setUserLoad] = useState(false);
    const [checkstatus, setCheckStatus] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    

    const studentPerPage = 10;
    const pagesVisited = pageNumber * studentPerPage;
    const displayStudent = mdata && mdata
    .slice(pagesVisited, pagesVisited + studentPerPage)
    .map((stud) =>{
      return (
        <tr key={stud.id}>
        <td>{ stud.firstname+' '+stud.lastname }</td>
        <td>{ stud.email }</td>
       
        <td>{ stud.gender }</td>
        <td>{ stud.grade }</td>
        <td>{ stud.home_address }</td>
        <td>{ stud.registration_status === null ? <span>Uncomplete</span> : stud.registration_status }</td>
        <td>
        
          { stud.registration_status === 'save' ?
        <button type="button" onClick={()=> redirectSave(stud.grade,stud.student_id) } className="btn btn-primary btn-sm btn-rounded waves-effect waves-light">
         
         Add or Remove Subject

        </button> 
        : null
      }
&nbsp;&nbsp;&nbsp;
        <button type="button" onClick={()=> ViewProfile(stud.student_id,stud.registration_status,stud.grade ===  null ? null : stud.grade)} className="btn btn-primary btn-sm btn-rounded waves-effect waves-light">
         
         View Details

        </button>
         
                          
        </td>
      </tr>
      )
    })


    const redirectSave = (grade,studentID) => {
                const data = { grade:grade, studentID:studentID}
              Axios.post('/remove-previous-subject',data,{
                headers: { 'Content-Type': 'application/json'}
              }).then((response)=>{
                  if(response.data.mdatas === false){
                    history("/student/add-student/complete/"+studentID)
                  }
              })

             
    }
    
    const pageCount = Math.ceil(mdata && mdata.length / studentPerPage);

    const changePage = ({selected}) =>{
          setPageNumber(selected)
    }
    const history = useNavigate();

      const ViewProfile = (studentID,status,grade) =>{
            
        Axios.get('/validate-user-subject/'+studentID+'/'+grade,{
          headers: { 'Content-Type': 'application/json'}
       })
      .then((response)=>{
               //console.log(response.data.valid)
          if(response.data.valid === 'not valid'){
            history("/student/add-student/complete/"+studentID);        
          }else{

          if(status === 'save'){
            history("/finance/unpaid-invoice");

          }else{
          
          setLoadProfile(true)
        const ApiRout = "/get-student-data/"+studentID;
        Axios.get(ApiRout,{
          headers: { 'Content-Type': 'application/json'}
       })
        .then((response) => {
        if(response.data.userstatus === true){
              setTimeout(()=>{
                setStudentInfo(response.data.result); 
                setProfileStatus(true);
                setLoadProfile(false);
              },2000);
               
        }
      
  })
}   
          }

        })

      }

  
   
    useEffect(() => {
      
      const apiFetchStudent = "/mychild";
          Axios.get(apiFetchStudent,{
            headers: { 'Content-Type': 'application/json'}
         })
          .then((response)=>{
                  //console.log(response.data);
               if(response.data.result === false){
                       setUserLoad(false);  
                        setCheckStatus(false)   
               } else
               {
                setUserLoad(null);
                setMdata(response.data.result);
                   
                    setCheckStatus(true)
                   
               }
          });
            
            
    }, [checkstatus])

     const handleProfileBack = () =>{
      setProfileStatus(false);
          
     }  

    

    return (
          <div>  
          
          { profileStatus === false ?
        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">All Student</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Student</li>
                      <li className="breadcrumb-item active">All Student</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
           
            { userload === false ?

            <div className="row justify-content-center mt-lg-5">
              <div className="col-xl-5 col-sm-8">
                <div className="card">
                  <div className="card-body">
                    <div className="text-center">
                      <div className="row justify-content-center">
                        <div className="col-lg-10">
                          <h4 className="mt-4 font-weight-semibold">No Student Found</h4>
                          <p className="text-muted mt-3">Oops! you have not register any student. Kindly use the add student button below to add student</p>
                          <div className="mt-4">
                            {/* Button trigger modal */}
                            <Link className="btn btn-primary" to="/student/add-student">
                              Add Student
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center mt-5 mb-2">
                        <div className="col-sm-6 col-8">
                          <div>
                            <img src="/autheme/assets/images/verification-img.png" alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    {/* Modal */}
                   
                 
                  </div>
                </div>
              </div>
            </div>
                   
                : 

                <div className="row">
             <div className="col-lg-12">
               <div className="tuk">
               <div className="table-responsive">

               <table className="table project-list-table table-nowrap align-middle table-borderless">
                     <thead>
                       <tr>
                         
                         <th scope="col">Names</th>
                         
                         <th scope="col">Email</th>
                        
                         <th scope="col">Gender</th>
                         <th scope="col">Grade</th>
                         <th scope="col">Home address</th>
                         <th scope="col">Registration Status</th>
                         <th scope="col">Action</th>
                       </tr>
                     </thead>

                     <tbody>
                         {  displayStudent  }
                          
                     </tbody>

                     </table>

                            <center>
                            { loadProfile === false ? null :
                             <div className="spinner-border text-primary m-1 " role="status">
                             <span className="sr-only">Loading...</span>
                         </div>
                            }
                            </center>

                 </div>

                 </div>
                 </div>
                 </div>
                
                }


        <div className="row">
              <div className="col-lg-12">

                  <ReactPaginate
                   previousLabel={<i className="mdi mdi-chevron-left" />}
                   nextLabel={<i className="mdi mdi-chevron-right" />}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  breakClassName={'page-item'}
                  breakLinkClassName={'page-link'}
                  containerClassName={'pagination pagination-rounded justify-content-center mt-2 mb-5'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextClassName={'page-item'}
                  nextLinkClassName={'page-link'}
                  activeClassName={'active'}
                  
                  />


              
              </div>
         
            </div>
          
           
         
          </div> 
        </div>
       
      
       <Footer />
      </div>
            :
              <StudentProfile studentInfo={studentInfo} handleBack={handleProfileBack} />
              }


            </div>
      
    )
}

export default AllStudent
