import React,{useState, useEffect} from 'react'
import axios from 'axios';
import $ from 'jquery';
import HTMLReactParser from 'html-react-parser';


function ViewAssessment() {
    const [loadingstatus, setLoadingStatus] = useState(false);
    const [years, setYears] = useState();
    const [centrestatus, setCentreStatus] = useState(null);
    const [loadCentres, setLoadcentre] = useState(null);
    const [viewGrade, setViewGrade] = useState(null);
    const [centresID, setCentresID] = useState();
    const [subject, setSubject] = useState(null);
    const [subjectStatus, setSubjectStatus] = useState();
    const [gradeId, setGradeID] = useState();
    const [assess, setAssess] = useState(null);
    const [assessStatus, setAssessStatus] = useState();
    const [subjectID, setSubjectID] = useState();
    const [displayCapture, setDisplayCapture] = useState(null);
    const [notifyState, setNotifyState] = useState(null)
    const [status, setStatus] = useState();
    const [marks, setMarks] = useState();
    const [subjectName,setSubjectName] = useState();
    const [term, setTerm] = useState();
    const [gradeRstatus, setGradeRStatus] = useState(false);
    const [viewAssData, setViewAssesData] = useState(null);
    const [studentID, setStudentID] = useState();
    const [questMark, setQuestMark] = useState();
    const [studentName, setStudentName] = useState();
    const [gradeData, setGradeData] = useState(null);
    const [currTerms, setCurrTerms] = useState(null)


const terms = [
  {termly:'Term 1', id:1},
  {termly:'Term 2', id:2},
  {termly:'Term 3', id:3},
  {termly:'Term 4', id:4}
  
]


const checkTerms = () => {
  let options = "";

  switch (currTerms) {
    case "1":
      options = <option value={'Term 1'}>Term 1</option>;
      break;
    case "2":
      options = <option value={'Term 2'}>Term 2</option>; 
      break;
    case '3':
      options = <option value={'Term 3'}>Term 3</option>;
      break;
    case '4':
      options = <option value={'Term 4'}>Term 4</option>;
      break;
    default:
      options = <option disabled>Terms</option>;
  }

  return options;
};

  

  const task ='Task';


  const getStudentGrade = (cid) => {    
               
    axios.get('/get-user-grade?cid='+cid,{
       headers:{ 'Content-Type': 'application/json'}
     })
     .then((response)=>{

           if(response.data.status){
               
                 setGradeData(response.data.status)
           }else{
                 setGradeData(null)
           }
     }) 
}


  const checkGrade = (centres) =>{
       if(centres === ''){
      
         setGradeData(null)

       }else
       {
         getStudentGrade(centres)
        setCentresID(centres);
       }
        
       
          
  }



     const addingMark = (mark,uploadID,totalMark) =>{
    setMarks(mark);
   
if(parseInt(mark) > parseInt(totalMark)){
  setStatus(<strong style={{color:'red'}}>Invalid Mark</strong>);
}else{
     setStatus(null)
axios.post('/add-assessmentmark',{
    mark:mark,
    uploadID:uploadID
},{
headers:{ 'Content-Type': 'application/json'}
})
}
}


  const getSubjectID = (subID) =>{
    axios.get('/getsubject-data/'+subID,{
      headers:{ 'Content-Type': 'application/json'}
    }).then((res)=>{
          setSubjectName(res.data.msubjectData[0].subject);
          return res.data.msubjectData[0].subject;
    })
}

  const checkSubject = (grade) =>{
    setLoadingStatus(true);
      setGradeID(grade);
        axios.get('/get-student-subject-data/'+grade,{
          headers:{ 'Content-Type': 'application/json'} 
        }).then((res)=>{
            setTimeout(()=>{
              setLoadingStatus(false);
              if(res.data.studentSubject === false){
                setSubjectStatus(<option disabled>Not Available</option>);
          }else
          {
                setSubject(res.data.studentSubject);
          }
            },2000)
           
        })
  }

  const checkAssessment = (subjectID) =>{
        setSubjectID(subjectID);
    setLoadingStatus(true);
         axios.post('/get-access-assessment',{
          subjectID:subjectID,
           gradeID:gradeId, 
           task:task,
           term:term,
           year:years
         },{
          headers:{ 'Content-Type': 'application/json'} 
         }).then((res)=>{
           setTimeout(()=>{
            setLoadingStatus(false);
            if(res.data.assData === false){
              setAssessStatus(<option disabled>Not available</option>)
         }else
         {
               setAssess(res.data.assData);
         }
           },2000)
         })
  }


  const captureData = (inviID) => {
     
    setLoadingStatus(true);
        axios.post('/get-assessmentdata-upload',{
          inviID:inviID, 
          gradeID:gradeId,
          centreID:centresID, 
          subjectIDs:subjectID,
          cterm:term,
          cyear:years,
           utype:'tutor'
        }).then((res)=>{
             setTimeout(()=>{
              setLoadingStatus(false);
              if(res.data.upData === false){
                setNotifyState('Oops no data found');
                setDisplayCapture(null)
           }else
           {
                setDisplayCapture(res.data.upData);
                setNotifyState(null)
           }
             },2000)
          
        })
  }




  const handleData = (studentName,subject_name,grade,subject_id,invigilator_id,studentID,taskType)=>{
                  setGradeRStatus(true);
                  setSubjectName(subject_name);
                  setStudentName(studentName);
                    setLoadingStatus(true)
                    //{studentID:studentID,indown:invid[0],assment:invid[1],subject_id:subjectID,term:termo,grade:gradeID,year:mYear}
                    let mData = {studentID:studentID,assment:taskType,indown:invigilator_id,subject_id:subject_id,term:term,grade:grade,year:years}
                   let base_url = 'https://termfinals-config.equationeducators.com/control_data.php';
                    $.ajax({
                      url:base_url,
                      data:mData,
                       type:'POST',
                       success:function(res){
                        setLoadingStatus(false)
                            setViewAssesData(res)
                       }
                    })
               

             
  }

 

    useEffect(()=>{

      let min = 2020,
      max = new Date().getFullYear(),
     select = document.getElementById('year');
 
 for (var i = min; i<=max; i++){
     var opt = document.createElement('option');
     opt.value = i;
     opt.innerHTML = i;
     select.appendChild(opt);
 }
 
 select.value = new Date().getFullYear();


        setLoadingStatus(true);
      axios.get('/getassessment-years',{
       headers:{ 'Content-Type': 'application/json'} 
      }).then((response) =>{
           setYears(response.data.years);
      })

       axios.get('/centres',{
        headers:{ 'Content-Type': 'application/json'}
       }).then((response)=>{
             setTimeout(()=>{
              setCurrTerms(response.data.term)
              setLoadingStatus(false);
              if(response.data.result === false){
                setCentreStatus(<option disabled>Not Available</option>);
          }else
          {
               setLoadcentre(response.data.result);
          }
             })
           
       })

       setNotifyState(null)
       setLoadingStatus(false)
       document.getElementById('formAss').reset()
      
},[task])

    return (
        <div>
     
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18"> Assessment Summary</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Academics</li>
                        <li className="breadcrumb-item active"> Assessment Summary</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>


          <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
            
              <form className="row gy-3 gx-5 align-items-center" id='formAss'>

              <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Term</label>
                   
                  <select className="form-select form-control" onChange={(e)=>setTerm(e.target.value)} id="autoSizingSelect" style={{textTransform:'capitalize'}}>
                  <option value=''> Term </option>
                        {checkTerms()}
                  </select>
                </div>


                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Academic Year</label>
                   
                  <select className="form-select form-control" id="year" onChange={(e)=>setYears(e.target.value)} >
                   <option value=''> Year </option> 

                   
                  </select>
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Centres</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=>checkGrade(e.target.value)}>
                    <option value=''> Centres</option>
                        { loadCentres === null ? centrestatus :
                            loadCentres && loadCentres.map(ct=>(
                               <option key={ct.id} value={ct.center_id}>{ ct.centreName }</option>
                            ))
                        }
                  </select>
                </div>


                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Grade</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=>checkSubject(e.target.value)}>
                    <option value={false}> Grade</option>
                     { gradeData === null ? <option disabled>Not Available</option> :
                          gradeData.map(g=>(
                            <option key={g.id} value={g.grade}> Grade: { g.grade }</option>
                          ))
                     }
                  </select>
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Subject</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=> checkAssessment(e.target.value)}>
                    <option value={false}> Subject</option>
                    { subject === null ? subjectStatus :
                      subject && subject.map(s=>(
                        <option key={s.id} value={s.subject_id}>{s.subject+ ' ('+s.lang+')'}</option>
                      ))
                    }
                   
                  </select>
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect" >Assessment</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=> captureData(e.target.value)} >
                    <option value={false}> Assessment</option>
                      { assess === null ? assessStatus :
                        assess && assess.map(ad=>(
                          <option key={ad.id} value={ad.invigilator_id}>{ ad.assessment }</option>
                        ))}
                  </select>
                </div>
                
                <center>
                    { loadingstatus !== false ?
                <div className="spinner-border text-primary m-1 " role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            : 
                                            notifyState !== null ? <div className="alert alert-info text-center"> { notifyState }</div> :
                                            null
}

                </center>
              </form>
            </div>
            {/* end card body */}
          </div>
          {/* end card */}
        </div>
        {/* end col */}
      </div>


        { displayCapture === null ? null :
         <div className="row">


           { gradeRstatus !== false? 
           
             viewAssData !== null ?
           <div className="col-lg-12">

<button type="button" onClick={()=> setGradeRStatus(false)} className="btn btn-primary btn-sm waves-effect waves-light" style={{float:'right'}}> Go Back </button>

           <h3 className="card-title">{studentName}, ({subjectName}, {term})</h3>

             
             {HTMLReactParser(viewAssData)}
               </div>
               : <div className='alert alert-info text-center'>No data found for this learner</div>
      
           
           :
         <div className="col-lg-12">
           
         <div className="table-responsive">
 
 <table className="table project-list-table table-nowrap align-middle table-borderless">
       <thead>
         <tr>
           
           <th scope="col">Learners</th>
           
           <th scope="col">Grade</th>
          
           <th scope="col">Assessment</th>
          
           <th scope="col">View Assessment</th>
          
         </tr>
       </thead>
 
       <tbody>
          { displayCapture && displayCapture.map(({studentName,subject_name,subject_id,tasktype,mark,grade,invigilator_id,student_id},index)=>{
                 return (
                      <tr key={index}>
                        <td style={{textTransform:'capitalize'}}>{ studentName }</td>
                        <td>{ grade }</td>
                        <td>{subject_name} - {tasktype}</td>
                       
                        <td>
                          
                             <button type="button" onClick={()=>handleData(studentName,subject_name,grade,subject_id,invigilator_id,student_id,tasktype)} className="btn btn-primary btn-sm waves-effect waves-light"> View Mark </button>
                     
                          </td>
                      </tr>
                 )
          })}
            
       </tbody>
 
       </table>
 
 
   </div>
 
               </div>
}

         </div>
 
        
}
        
          </div> {/* container-fluid */}
        </div>
        {/* End Page-content */}
     
      </div>
      {/* end main content*/}
        </div>
    )
}

export default ViewAssessment
