import React, { useEffect, useState} from 'react'
import { Link} from 'react-router-dom'
import axios from 'axios';
import Footer from '../Nav/Footer';
import '../Authent/Loader.css';
axios.defaults.withCredentials=true;
function Pdashboard() {

  const convertCurrency = (num) =>{
    return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

   const checkBill = (bill) =>{
        switch(bill){
            case 'term':
               return 'Termly';
              break;
              case 'year':
                 return ('Yearly')
                break;
                default:
                  return 'No Billing Yet';
        }
   }
          const projectName = 'FastExcellence';
        
             const [fnames, setFnames] = useState();
             const [email, setEmail] = useState();
             const [wallet, setWallet] = useState();
             const [userid, setUserid] = useState();
             const [userLoad, setUserLoad] = useState(false);
             const [mData, setMData] = useState();
             const [loader, setLoader] = useState(null);

          

           useEffect(()=>{

            const allStudent = () =>{
            
  
              axios.get("/childcomplete",{
                headers: { 'Content-Type': 'application/json'},
                 
             })
              .then((response)=>{
                    
                   if(response.data.studentData === false){
                           setUserLoad(false);  
                          
                   } else
                   {
                    setMData(response.data.studentData);
                        setUserLoad(true);   
                       
                   }
              });
  
             }
              
                        axios.get("/mydata/parent",{
                          headers: { 'Content-Type': 'application/json'},
                       }).then((response)=>{
                                
                          
                                setLoader('too');
                               
                                  setFnames(response.data.parentData[0].fnames);
                                  setEmail(response.data.parentData[0].email);
                                  setWallet(response.data.parentData[0].wallet);
                                  setUserid(response.data.parentData[0].userid); 
                               
                        
                          //console.log(response.data.result[0].fnames);
                                       
                            });

                            allStudent();
           },[])
    return (
       <div>
      
        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          { loader == null ? 
          <div id="preloader-spinner" className="preloader spinner">

<div className="wrapper">
    <span className="loader"></span>

    <p style={{marginTop:120}}>Loading Content. Please wait...</p>
</div>

</div>
:
null
}


            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Dashboard</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">{ projectName }</li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}


            

            <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="media">
              <div className="me-4">
                  <i className="mdi mdi-account-circle text-primary h1"></i>
                  </div>
                <div className="media-body align-self-center">
                  <div className="text-muted">
                    <h5>{ fnames }</h5>
                    <p className="mb-1">{ email }</p>
                    <p className="mb-0">PROFILE ID NO: #{ userid }</p>
                  </div>
                </div>

               
               
                <div className="dropdown">
                 
                <div className="media-body ">
                  <div className="text-muted">
                  <i className="mdi mdi-wallet me-1" /> 
                    <span className=" ">Wallet Balance </span>
                    <h5 className="mb-0">{ wallet ===null ? 'R0.00' : convertCurrency(parseInt(wallet)) }</h5>
                   
                  </div>
                </div>
               
                </div>
             
              </div>
            </div>
          </div>
        </div>
      </div>
           
            <div className="row">
           
           <div className="col-xl-12">
     
         { userLoad === false ? <div className="alert alert-info text-center">You have not register any child 
         &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; <Link to="/student/add-student" className="btn btn-primary waves-effect waves-light btn-sm"> 
                      Click here to add child </Link>
          </div> : null }

      <div className="table-responsive">
        <table className="table project-list-table table-nowrap align-middle table-borderless">
          <thead>
            <tr>
              <th scope="col" style={{width: '100px'}}>#</th>
              <th scope="col">Names</th>
              <th scope="col">Email</th>
              <th scope="col">Billing Circle</th>
              <th scope="col">Status</th>
              <th scope="col">Due Date</th>
            </tr>
          </thead>
          <tbody>
            { userLoad !== false ?
               mData && mData.map(({firstname,lastname,grade,email, registration_status, bill_cycle, due_date},index) =>{
                  return (
                    <tr key={index}>
                    <td>
                        { 1+index}
                        </td>
                    <td>
                      <h5 className="text-truncate font-size-14"><div className="text-dark">{ firstname+' '+lastname}</div></h5>
                      <p className="text-muted mb-0">Grade: {grade === null ? '--' : grade}</p>
                    </td>
                    <td>{email}</td>
                    <td>{ bill_cycle === 'Year' ? 'Yearly' : 'Monthly' }</td>
                    <td>
                        { registration_status === 'complete' ? <span className="badge bg-success">Complete</span>
                        :
                        <span className="badge bg-success">{ registration_status }</span>
                      }
                      
                      
                      </td>
                   
                    <td>
                       
                        { due_date === null ? '-' : due_date}

                    </td>
                  </tr>
                  )
               })
           
            :
            null
}
           
          </tbody>
        </table>
      </div>


           </div>
           
            </div>



          </div> {/* container-fluid */}
        </div>
      
        <Footer/>
      </div>
      </div>
    )
}

export default Pdashboard
