import React from 'react'

function Footer() {
    const curDate = new Date().getFullYear();
    return (
        <div>
             <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                © {curDate} FastExcellence
              </div>
              <div className="col-sm-6">
                
              </div>
            </div>
          </div>
        </footer>
        </div>
    )
}

export default Footer
