import axios from 'axios';
import React, {useState, useEffect} from 'react'
import Footer from '../../Nav/Footer'
import { useBarcode } from 'react-barcode';
import download from 'downloadjs'
import {useParams, Link} from 'react-router-dom'
import jsPDF from 'jspdf'


function InvigilatorDownload() {
       
     const { id } = useParams();
        const [loadingstatus, setLoadingStatus] = useState(false);
         const [vigilate, setVigilate] = useState(null);
         const [grade, setGrade] = useState(null);
         const [gradeStatus, setGradeStatus] = useState();
         const [subject, setSubject] = useState(null);
         const [subjectStatus, setSubjectStatus] = useState();
         const [gradeID, setGradeID] = useState();
         const [mTerm, setMTerm] = useState();
         const [loadvigilate, setLoadVigilate] = useState(null);
         const [subjectName,setSubjectName] = useState();
         const [year, setYear] = useState();
         const [coverSubject, setCoverSubject] = useState();
         const [covergrade, setCoverGrade] = useState();
         const [coverTask, setCoverTask] = useState();
         const [downloadStatus, setDownloadStatus] = useState(null);
         const [coverDown, setCoverDown] = useState(null);
         const [mgrade, setMgrade] = useState(null);
         const [currTerms, setcurrTerms] = useState(null);
        const cate =id;

     
        const terms = [
          {termly:'Term 1', id:1},
          {termly:'Term 2', id:2},
          {termly:'Term 3', id:3},
          {termly:'Term 4', id:4}
          
        ]


        const checkTerms = () => {
          let options = "";
        
          switch (currTerms) {
            case "1":
              options = <option value={'Term 1'}>Term 1</option>;
              break;
            case "2":
              options = <option value={'Term 2'}>Term 2</option>; 
              break;
            case '3':
              options = <option value={'Term 3'}>Term 3</option>;
              break;
            case '4':
              options = <option value={'Term 4'}>Term 4</option>;
              break;
            default:
              options = <option disabled>Terms</option>;
          }
        
          return options;
        };


   const downloadData = (type,downloadType,downloadId) =>{
     setDownloadStatus('dn')
    axios.post('/download-question',{
      category:downloadType,
      downloadID:downloadId
  },{
    responseType: 'blob'
  }
  
  ).then((response)=>{
     setDownloadStatus(null)
    const content = response.headers['content-type'];
    download(response.data, type+'.pdf', content)
    
    
  })
               
   }
  
   const displayDownload = (subjectID)=> {
            setLoadingStatus(true)
           
        axios.post('tut-invigilator-dowload',{
            grade:gradeID,
            mterm:mTerm,
            cate:cate,
            subjectID:subjectID,
            year:year

        },{
          headers:{ 'Content-Type': 'application/json'}
        }).then((res)=>{
            setTimeout(()=>{
               setLoadingStatus(false);
                 if(res.data.downloadData === false){
                       setVigilate('No data found to dowload');
                       setLoadVigilate(null)
                 }else
                 {
                       setVigilate(null);

                       setLoadVigilate(res.data.downloadData);
                 }
            },2000)
        })
   }
           
   const getSubject = (gradeam) =>{
          setGradeID(gradeam);
          setLoadingStatus(true);
         axios.post('/getsubject-data-assessment',{
            grade:gradeam
         }).then((response)=>{
             setTimeout(()=>{
                  setLoadingStatus(false);
              if(response.data.subjectData === false){
                setSubjectStatus(<option disabled>Not Available</option>);
         }else
         {
                setSubject(response.data.subjectData);
         }
             },2000)
             
         }).catch((err)=>{
            console.log(err)
         })
   }

   const coverPageDownload = (invigilatorID,subject,grade,task) =>{
             setCoverSubject(subject);
             setCoverGrade(grade);
             setCoverTask(task)
   }



   const getStudentGrade = () => {    
               
    axios.get('/get-user-grade',{
       headers:{ 'Content-Type': 'application/json'}
     })
     .then((response)=>{

           if(response.data.status){
               
                 setMgrade(response.data.status)
           }else{
            mgrade = null;
           }
     }) 
}

  

    useEffect(()=>{

      let min = new Date().getFullYear(),
      max = 2040,
     select = document.getElementById('year');
 
 for (var i = min; i<=max; i++){
     var opt = document.createElement('option');
     opt.value = i;
     opt.innerHTML = i;
     select.appendChild(opt);
 }
 
 select.value = new Date().getFullYear();

      axios.get('/mychild',{
        headers:{ 'Content-Type': 'application/json'}
      }).then((response)=>{
        setcurrTerms(response.data.term)
            if(response.data.nonUser === false){
                   setGradeStatus(<option disabled>Not available</option>);
            }else
            {
                    setGrade(response.data.result);
                    getStudentGrade();

            }
      })
         setVigilate(null)
        document.getElementById('downForm').reset();
    },[cate])

    const generateCoverPages = () => {
        setCoverDown('Yes');
      let doc = new jsPDF('p', 'pt', [1420, 1600], true);
          doc.html(document.querySelector("#coverPages"),{
             callback: (pdf) =>{
                      setCoverDown(null)
                      pdf.save(cate+"-Cover Page For-"+coverSubject+".pdf");
             }
          })
    }
  
    return (
       
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">{ cate }</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Academics</li>
                      <li className="breadcrumb-item">Invigilator Download</li>
                      <li className="breadcrumb-item active">{ cate }</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>


          <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-4">Invigilator Download: { cate }</h5>
              <form className="row gy-3 gx-5 align-items-center" id='downForm'>


              <div className="col-sm-auto col-md-3">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Year</label>
                   
                  <select className="form-select form-control" onChange={(e)=>setYear(e.target.value)} id="year" style={{textTransform:'capitalize'}}>
                  <option value=''> Year </option>
                
                  </select>
                </div>


              <div className="col-sm-auto col-md-3">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Term</label>
                   
                  <select className="form-select form-control" onChange={(e)=>setMTerm(e.target.value)} id="autoSizingSelect" style={{textTransform:'capitalize'}}>
                  <option value=''> Term </option>
                        { checkTerms()}
                  </select>
                </div>


                <div className="col-sm-auto col-md-3">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Grade</label>
                  
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=>getSubject(e.target.value)} >
                  <option value=''> Grade </option>
                    { 
                       mgrade === null ? '<option value="">No data available</option>' :
                       mgrade.map(gd=>(
                         <option value={gd.grade} key={gd.id}>{'Grade '+gd.grade}</option>
                       ))
                    }
                          
                  </select>
                 
                </div>

                <div className="col-sm-auto col-md-3">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Subject</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=> displayDownload(e.target.value)}>
                    <option value=''> Subject</option>
                   { subject === null ? subjectStatus :
                       subject && subject.map(sub=>(
                          <option key={sub.id} value={sub.subject_id}>{ sub.subject+' ('+sub.lang+')'}</option>
                       ))
                   }
                  </select>
                </div>
                
                <center>
                    { loadingstatus !== false ?
                <div className="spinner-border text-primary m-1 " role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            : 
                                            vigilate !== null ? <div className="alert alert-info text-center"> { vigilate }</div> :
                                            null
}

                </center>

              </form>
            </div>
            {/* end card body */}
          </div>
          {/* end card */}
        </div>
        {/* end col */}
      </div>





      <div className="row">
        <div className="col-lg-12">
          
              { loadvigilate === null ? null :
                
                <div className="table-responsive">
                      { downloadStatus === null ? null : 
                                <div>
                            <div className="spinner-border text-primary m-1 " role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> <p>Processing your request. Please wait </p> </div> } 
               <table className="table project-list-table table-nowrap align-middle table-borderless">
                     <thead>
                       <tr>
                         
                         <th scope="col">Assessment</th>
                         
                         <th scope="col">Period</th>
                        
                         <th scope="col">Type</th>
                         <th scope="col">Due Date</th>
                         <th scope="col">Question (Afr)</th>
                         <th scope="col">Question (Eng)</th>
                         <th scope="col">Memo (Afr)</th>
                         <th scope="col">Memo (Eng)</th>
                         <th scope="col">Cover Page</th>
                         
                       </tr>
                     </thead>

                     <tbody>
                        { loadvigilate && loadvigilate.map(({invigilator_id,assessment,subject_id,subject,download_type,period,due_date,
                          question_afr,question_eng,memo_eng,memo_afr,grade},index)=>{
                              return(
                                <tr key={index}>
                                  <td>Grade: {grade}&nbsp;&nbsp;
                                  { subject }&nbsp;&nbsp;
                                  {assessment}
                                  </td>
                                  <td>
                                    { download_type }
                                  </td>
                                  <td>
                                    { period }
                                  </td>
                                  <td>
                                    {due_date}
                                  </td>
                                  <td>
                                    { question_afr === null ? 'None' : <div className="btn btn-primary btn-sm waves-effect waves-light" onClick={()=> downloadData(subject+'-'+assessment,'qAfr',invigilator_id)}>Download </div> }
                                  </td>
                                  <td>
                                    { question_eng === null ? 'None' : <div className="btn btn-primary btn-sm waves-effect waves-light" onClick={()=> downloadData(subject+'-'+assessment,'qEng',invigilator_id)}>Download </div> }
                                  </td>

                                  <td>
                                    { memo_afr === null ? 'None' : <div className="btn btn-primary btn-sm waves-effect waves-light" onClick={()=> downloadData(subject+'-'+assessment,'mAfr',invigilator_id)}>Download </div> }
                                  </td>

                                  <td>
                                    { memo_eng === null ? 'None' : <div className="btn btn-primary btn-sm waves-effect waves-light" onClick={()=> downloadData(subject+'-'+assessment,'mEng',invigilator_id)}>Download </div> }
                                  </td>

                                  <td>
                                  <div onClick={()=> coverPageDownload(invigilator_id,subject,grade,assessment)} className="btn btn-primary btn-sm waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#exampleModalFullscreen">Download Cover Page </div> 
                                  </td>
                                </tr>
                              )
                          })}
                          
                     </tbody>

                     </table>

                           

                 </div>

              
              }


           <br />
        </div>
        {/* end col */}
      </div>

    
      <div id="exampleModalFullscreen" className="modal fade" tabIndex={-1} aria-labelledby="#exampleModalFullscreenLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel"> { cate } Cover Page Preview For { coverSubject } </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body" id="coverPages">
             
             
             <div className="row">
             <div className="col-md-2">
               </div>
                
                <div className="col-md-5">
                <span className="logo-lg">
                  <img src="/autheme/assets/images/eq4.png" alt="" height={50} />
                </span>
                </div>

             

                  <div className="col-md-4">
                  <div>
                    <h3>Task Cover - { cate }</h3>
                    <h5>Grade { covergrade+' '+coverSubject+' - '+coverTask }</h5>
                  
                    <img src="/autheme/assets/images/barcode.png" alt="" style={{width:400,height:60}} /><br></br>
                   
                      <p style={{color:'black'}}> Student No | GR | Subject | Tsk no</p>
                    </div>

                  
                  </div>


                


             </div>

             <div className="row" style={{marginTop:40}}>
             <div className="col-xl-2">
               </div>
               <div className="col-xl-7">
                 <div className="row">
                 <div className="col-md-4">
                   <strong style={{color:'black', fontSize:18, fontFamily:'serif'}}>Student No:</strong>
                  
                   <hr style={{marginTop:-5}}></hr>
              
                   </div>

                   <div className="col-md-8">
                   <strong style={{color:'black', fontSize:18, fontFamily:'serif'}}>Name &amp; Surname:</strong>
                  
                   <hr style={{marginTop:-5}}></hr>
              
                   </div>

                  
             
                 </div>
                  
               </div>
             </div>


             <div className="row" style={{marginTop:40}}>
             <div className="col-xl-2">
               </div>
               <div className="col-xl-7">
                 <div className="row">
                
                   <div className="col-md-5">
                   <strong style={{color:'black', fontSize:18, fontFamily:'serif'}}>Tel:</strong>
                  
                   <hr style={{marginTop:-5}}></hr>
              
                   </div>



                   <div className="col-md-5">
                   <strong style={{color:'black', fontSize:18, fontFamily:'serif'}}>Email:</strong>
                  
                   <hr style={{marginTop:-5}}></hr>
              
                   </div>
                  
             
                 
                 </div>
                  
               </div>
             </div>


                <div className="row" style={{marginTop:20}}>
                <div className="col-md-2">
                  </div>
                  <div className="col-md-8">
                    <ul className="ps-4">
                      <li className="py-1 font-size-18">
                      Ensure the { cate } cover page corresponds to the attached portfolio piece.
                      </li>
                      <li className="py-1 font-size-18">
                      Every learner has a unique bar-coded cover page. You cannot copy another learner's cover pages.
                      </li>
                      <li className="py-1 font-size-20">
                      <strong>Send to:</strong> <i>31KILLBURN STREET, HORZION</i>
                      </li>
                    </ul>

                  </div>

                </div>

                <div className="row">
                <div className="col-md-2"></div>
                  <div className="col-md-5">
                    <strong style={{color:'black',fontSize:18, textDecorationLine:'underline'}}>To be completed by the learner:</strong>
                    <p>I declare that this {cate } is my own work and not copied from any other person/source/memorandum.</p>
                  </div>


                  <div className="col-md-4">
                    <strong style={{color:'black',fontSize:18, textDecorationLine:'underline'}}>To be completed by the supervisor/facilitator:</strong>
                    <p>I declare that this {cate } is the learner's own work and that the {cate} was completed in a fitting environment relative to the nature of the {cate}.</p>
                  </div>
                </div>

                <div className="row">
                    <div className="col-md-2">

                    </div>

                    <div className="col-md-4">
                    <div className="card border border-primary">
                                    <div className="card-header bg-transparent border-primary">
                                        <h5 className="my-0 text-primary">SIGNATURE OF THE LEARNER</h5>
                                    </div>
                                    <div className="card-body">
                                      <div>
                                  
                                    <h5 className="card-title mt-0">Signature</h5>
                                          <hr></hr>   
                                          </div>

                                          <div>
                                          <strong style={{color:'black', fontSize:18, fontFamily:'serif'}}>Date:</strong>
                  
                                         <hr style={{marginTop:-5}}></hr>
             
                                          </div>
                                          
                                          </div>
                                </div>
                    </div>

                    <div className="col-md-5">

                    <div className="card border border-primary">
                                    <div className="card-header bg-transparent border-primary">
                                        <h5 className="my-0 text-primary">NAME AND SIGNATURE OF THE SUPERVISOR/FACILITATOR</h5>
                                    </div>
                                    <div className="card-body">
                                      <div>
                                  
                                    <h5 className="card-title mt-0">Signature</h5>
                                          <hr></hr>   
                                          </div>

                                          <div>
                                          <strong style={{color:'black', fontSize:18, fontFamily:'serif'}}>Date:</strong>
                  
                                         <hr style={{marginTop:-5}}></hr>
             
                                          </div>
                                          
                                          </div>
                                </div>

                            
                      
                    </div>


                </div>


                <div className="row" style={{marginTop:100}}>
                <div className="col-xl-2">
                  </div>
                <div className="col-xl-5">
                                       
                                        <div className="table-responsive">
                                            <div style={{height:60,width:800,background:'black',textAlign:'center'}}>
                                                <br></br>
                                               <strong style={{marginRight:150,marginTop:-10,color:'white', fontSize:20}}>Complete the section below in full</strong>
                                            </div>
                                            <table className="table table-bordered mb-0">  
                                                            
                                                <tbody>
                                                    <tr style={{height:80}}>
                                                        
                                                        <td style={{width:100,borderWidth:1, borderColor:"black", borderStyle:'solid'}} align="center">
                                                           <div>
                                                             <br></br> 
                                                           DATE RECEIVED BY MARKER
                                                             </div>
                                                         
                                                          </td>
                                                        <td style={{width:100,borderWidth:1, borderColor:"black", borderStyle:'solid'}}></td>
                                                        
                                                    </tr>
                                                    <tr style={{height:80}}>
                                                        
                                                        <td style={{width:100,borderWidth:1, borderColor:"black", borderStyle:'solid'}} align="center">
                                                           <div>
                                                             <br></br> 
                                                             NAME OF MARKER
                                                             </div>
                                                         
                                                          </td>
                                                        <td style={{width:100,borderWidth:1, borderColor:"black", borderStyle:'solid'}}></td>
                                                        
                                                    </tr>
                                                    <tr style={{height:80}}>
                                                        
                                                        <td style={{width:100,borderWidth:1, borderColor:"black", borderStyle:'solid'}} align="center">
                                                           <div>
                                                             <br></br> 
                                                             SIGNATURE
                                                             </div>
                                                         
                                                          </td>
                                                        <td style={{width:100,borderWidth:1, borderColor:"black", borderStyle:'solid'}}></td>
                                                        
                                                    </tr>
                                                    <tr style={{height:80}}>
                                                        
                                                        <td style={{width:100,borderWidth:1, borderColor:"black", borderStyle:'solid'}} align="center">
                                                           <div>
                                                             <br></br> 
                                                             DATE RETURNED BY MARKER
                                                             </div>
                                                         
                                                          </td>
                                                        <td style={{width:100,borderWidth:1, borderColor:"black", borderStyle:'solid'}}></td>
                                                        
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>
        
                                   
                            </div>
                            <div className="col-xl-12">
                              <br></br>
                              </div>

                              <div className="col-xl-2">
                  </div>
                            <div className="col-xl-5">
                                       
                                        <div className="table-responsive">
                                           
                                            <table className="table mb-0" style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}>  
                                                            
                                                <tbody>
                                                    <tr style={{height:60}}>
                                                        
                                                        <td style={{width:200,borderWidth:1, borderColor:"black", borderStyle:'solid'}} align="center">
                                                           <div>
                                                         
                                                             NAME OF MODERATOR
                                                             </div>
                                                         
                                                          </td>
                                                        <td style={{width:200,borderWidth:1, borderColor:"black", borderStyle:'solid'}}></td>
                                                        
                                                    </tr>
                                                    <tr style={{height:60}}>
                                                        
                                                        <td style={{width:200,borderWidth:1, borderColor:"black", borderStyle:'solid'}} align="center">
                                                           <div>
                                                          
                                                             SIGNATURE
                                                             </div>
                                                         
                                                          </td>
                                                        <td style={{width:200,borderWidth:1, borderColor:"black", borderStyle:'solid'}}></td>
                                                        
                                                    </tr>
                                                    <tr style={{height:60}}>
                                                        
                                                        <td style={{width:200,borderWidth:1, borderColor:"black", borderStyle:'solid'}} align="center">
                                                           <div>
                                                           
                                                             DATE MODERATED
                                                             </div>
                                                         
                                                          </td>
                                                        <td style={{width:200,borderWidth:1, borderColor:"black", borderStyle:'solid'}}></td>
                                                        
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>
        
                                   
                            </div>

                            <div className="col-xl-4" style={{marginTop:-40}}>
                                       
                                       <div className="table-responsive">
                                           <div style={{height:40,width:800,background:'black',textAlign:'center'}}>
                                              
                                                <strong style={{marginRight:380,color:'white', fontSize:17}}>Marks - for office use</strong>
                                         
                                               
                                            </div>
                                           <table className="table mb-0" style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}>  
                                                        
                                               <tbody>

                                               <tr style={{background:'darkgrey'}}>
                                                       <td align="center" style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}>
                                                         <div style={{textAlign:'center', color:'black', fontWeight:'bold'}}>
                                                         Units
                                                         </div>
                                                       </td>
                                                       <td align="center" style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}>
                                                       <div style={{textAlign:'center', color:'black', fontWeight:'bold'}}>
                                                          100
                                                        </div>
                                                      
                                                       </td>
                                                       <td align="center" style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}>
                                                         
                                                       <div style={{textAlign:'center', color:'black', fontWeight:'bold'}}>
                                                           10
                                                         </div>
                                                       </td>
                                                       <td align="center" style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}>
                                                       <div style={{textAlign:'center', color:'black', fontWeight:'bold'}}>
                                                           1
                                                         </div>
                                                       </td>
                                                     </tr>


                                                   <tr>
                                                   <td style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}>
                                                   <div style={{textAlign:'center', color:'black', fontWeight:'bold'}}>
                                                      Achieved
                                                      </div>
                                                      </td>
                                                       <td style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}></td>
                                                       <td style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}> </td>
                                                         <td style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}> </td>
                                                   </tr>
                                                   <tr >
                                                       
                                                   <td style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}>
                                                   <div style={{textAlign:'center', color:'black', fontWeight:'bold'}}>
                                                     Mod
                                                     </div>
                                                      </td>
                                                       <td style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}></td>
                                                       <td style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}> </td>
                                                         <td style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}> </td>
                                                       
                                                   </tr>
                                                   <tr>
                                                       
                                                       <td style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}>
                                                       <div style={{textAlign:'center', color:'black', fontWeight:'bold'}}>
                                                         Max 
                                                         </div>
                                                         </td>
                                                       <td style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}>
                                                       <div style={{textAlign:'center', color:'black', fontWeight:'bold'}}>
                                                         0
                                                         </div>
                                                         </td>
                                                       <td style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}>
                                                       <div style={{textAlign:'center', color:'black', fontWeight:'bold'}}>
                                                         9 
                                                         </div>
                                                         </td>
                                                         <td style={{borderWidth:1, borderColor:"black", borderStyle:'solid'}}>
                                                         <div style={{textAlign:'center', color:'black', fontWeight:'bold'}}>
                                                           0 
                                                           </div>
                                                           </td>
                                                       
                                                   </tr>
                                                 
                                               </tbody>
                                           </table>
                                       </div>
       
                                  
                           </div>
                </div>



            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary waves-effect" data-bs-dismiss="modal">Close</button>
              { coverDown === null ?
              <button type="primary" className="btn btn-primary waves-effect waves-light" onClick={()=> generateCoverPages()}>Download Cover Page</button>
              :
              <button className="btn btn-primary waves-effect waves-light" disabled>

<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
  Please Wait...</button>
}
            </div>
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}


          </div> {/* container-fluid */}
        </div>
        {/* End Page-content */}
        <Footer />
      </div>
    
      
    )
}

export default InvigilatorDownload
