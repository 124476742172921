import React, { useState, useEffect} from 'react'
import {useParams, Link} from 'react-router-dom'
import FilterStud from './FilterStud';
import axios from 'axios';


function CentreData() {
         const { id } = useParams();

         const [centreName,setCentreName] = useState();
         const [centreEmail, setCentreEmail] = useState();
         const [centreID, setCentreID] = useState();
         const [studentData, setStudentData] = useState();
         const [studentStatus, setStudentStatus] = useState(false);
         const [countData, setCountData] = useState();
         const [filterStud, setFilterStud] = useState(false);
         const [gradeFilters, setGradeFilters] = useState(false);
         const [displayFilterStudent, setDisplayFilterStudent] = useState();
         const [load, setLoad] = useState(false);
         const [grade, setGrade] = useState();
         const [studID, setStudID] = useState();

         const [subjectData, setSubjectData] = useState();
         const [studentName, setStudentName] = useState();
         const [status, setStatus] = useState();
         const [activateStatus,SetActivationStatus] = useState(false);
         const [studentAge, setStudentAge] = useState();
         const [homeLang, setHomeLang] = useState();
         const [langInstruct, setLangInstruct] = useState();
         const [otherLang, setOtherLang] = useState();
         const [sgrade, setSGrade] = useState();
         const [gender, setGender] = useState();
         const [email, setEmail] = useState();
         const [phone, setPhone] = useState();
         const [homeAddress, setHomeAddress] = useState();
         const [deletePermit, setDeletePermit] = useState();
         const [loadam, setLoadAm] = useState(false);
         const [noteStatus, setNoteStatus] = useState(false);
         const [note, setNote] = useState('');
         const [studentID, setStudentID] = useState();




         const gradeData = [
                 {grade:'R',id:1},
               {grade:'1',id:3},
               {grade:'2',id:4},
               {grade:'3',id:5},
               {grade:'4',id:6},
               {grade:'5',id:7},
               {grade:'6',id:8},
               {grade:'7',id:9},
               {grade:'8',id:10},
               {grade:'9',id:11},
               {grade:'10',id:12},
               {grade:'11',id:13},
               {grade:'12',id:14}
               
         ];


         const fetchStudentData = (studID,studentName) =>{
          setStudentName(studentName);
        axios.get('/get-centre-student-data/'+studID+'/'+centreID,{
          headers: { 'Content-Type': 'application/json'}
        }).then((res)=>{
            // res.json({link:link,studentData:studentData,subjectData:result});


                  setStudentAge(res.data.studentData[0].age);
                  setGender(res.data.studentData[0].gender);
                  setHomeAddress(res.data.studentData[0].home_address);
                  setLangInstruct(res.data.studentData[0].lang_intruct);
                  setSGrade(res.data.studentData[0].grade);
                  setPhone(res.data.studentData[0].phone);
                  setEmail(res.data.studentData[0].email);
                  setHomeLang(res.data.studentData[0].home_lang);
                  setOtherLang(res.data.studentData[0].other_lang);
                  setStudID(res.data.studentData[0].student_id_pass);
                  setSubjectData(res.data.subjectData);
                  setStudentID(res.data.studentData[0].student_id);
             

                if(res.data.link[0].verify === 'Pending'){
                        SetActivationStatus(false)

                }else
                {
                  SetActivationStatus(true)

                }
             
                
        })
     }
    
     

         useEffect(()=>{

         
              let uid = id;
            axios.get('/getcentre-data/'+uid,{
              headers: { 'Content-Type': 'application/json'}
           })
            .then((response)=>{
                       if (response.data.centreData !== false) {
                                   setCentreName(response.data.centreData[0].centreName);
                                   setCentreEmail(response.data.centreData[0].centreEmail);
                                   setDeletePermit(response.data.centreData[0].delete_request);
                                   setCentreID(id);
                                   
                       }

                       if (response.data.studentData !== false) {
                                       setStudentStatus(true)
                                       setStudentData(response.data.studentData);
                                        let coutdata = response.data.studentData;
                                         setCountData(coutdata);
                       }else
                       {
                               setStudentStatus(false)
                       }
            })
       
              
         },[]);


         const removeFromCentre = () => {
       
          if(note === ''){
                setNoteStatus('Please make sure input field is not empty');
          }else{
              setLoadAm(true)
        axios.put('/centre/removestudent',{
           sid_cid:id,
           note:note,
            userType:'Tutor'
        },{
          headers: { 'Content-Type': 'application/json'}
       })
        .then((response)=>{
                setTimeout(()=>{
                  setLoadAm(false)
                  if(response.data.status=== false){
                    setNoteStatus('Unable to process your request at this moment');
                  }else{
                    setNoteStatus('Your request was sent successfully!');
                    setNote('');
                  }
                },2000)
             
        })
      }  
    }

         const handleGradeSelect = (e) => {
                let grade = e.target.value;
                 setGrade(grade);
                 setLoad(true);
                axios.get('/all-student-centre/'+grade+'/'+id,{
                  headers: { 'Content-Type': 'application/json'}
               })
                .then((response)=>{
                            setTimeout(() =>{
                              
                               setStudentStatus(true);
                              setLoad(false);
                              setFilterStud(true);
                              if(response.data.gradeFilter === false){
                                      setGradeFilters(false);
                              }else
                              {
                                setGradeFilters(true);
                                setDisplayFilterStudent(response.data.gradeFilter)
                                  
                              }
                            },2000);
                            
                });

                 
         }

    return (
        <div>
   
        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Learners</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Centre</li>
                      <li className="breadcrumb-item active">Learner</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}

            <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="media">
              <div className="avatar-lg mx-auto mb-4">
                              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-16">
                                D
                              </span>
                            </div>
                <div className="media-body align-self-center" style={{marginLeft:20, marginTop:-20}}>
                  <div className="text-muted">
                    <h5>{ centreName }</h5>
                    <p className="mb-1">{ centreEmail }</p>
                    <p className="mb-0">Id no: #{ centreID }</p>
                  </div>
                </div>

              

                <div className="col-sm-auto col-md-4" style={{marginTop:20}}>
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Select Student by Grade</label>
                   
                  <select className="form-select form-control" id="autoSizingSelect" onChange={(e)=> handleGradeSelect(e)}>
                  <option value={false}>Display Student by Grade</option>
                            { gradeData.map((data) =>(
                                    <option key={data.id} value={data.grade}>Grade { data.grade }</option>
                            ))}
                  </select>
                                { load !== false ?
                              <center>
                                <br/>
                              <div className="spinner-border text-primary m-1" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                              </center>
                              : null }
                 

                </div>
               

              </div>
              { deletePermit === null ?
              <button type="button"  className="btn btn-primary btn-sm waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#staticNote" style={{clear:'both', float:'right'}}> Request to Delete Centre </button>
                  :
                  <button type="button"  className="btn btn-primary btn-sm waves-effect waves-light" data-bs-toggle="modal" data-bs-target=".bs-example-modal-sm" style={{clear:'both', float:'right'}}> Delete Centre </button>
                                }
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade bs-example-modal-sm" tabIndex={-1} role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="mySmallModalLabel">Small modal</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <p>Cras mattis consectetur purus sit amet fermentum.
                Cras justo odio, dapibus ac facilisis in,
                egestas eget quam. Morbi leo risus, porta ac
                consectetur ac, vestibulum at eros.</p>
              <p>Praesent commodo cursus magna, vel scelerisque
                nisl consectetur et. Vivamus sagittis lacus vel
                augue laoreet rutrum faucibus dolor auctor.</p>
              <p className="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                Praesent commodo cursus magna, vel scelerisque
                nisl consectetur et. Donec sed odio dui. Donec
                ullamcorper nulla non metus auctor
                fringilla.</p>
            </div>
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}


            <div className="row">


                { studentStatus !== false ?

                        filterStud === false ?
                    studentData && studentData.map((s) => (
                        <div className="col-xl-3 col-sm-6" key={s.id}>
                        <div className="card text-center">
                          <div className="card-body">
                            <div className="avatar-sm mx-auto mb-4">
                              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-16">
                                D
                              </span>
                            </div>
                            <h5 className="font-size-15 mb-1"><Link to="#" className="text-dark">{ s.firstname+' '+s.lastname }</Link></h5>
                            <p className="text-muted">Grade: { s.grade }</p>
                           
                          </div>
                          <div className="card-footer bg-transparent border-top">
                            <div className="contact-links font-size-20">
                              
                            <button type="button" onClick={()=>fetchStudentData(s.student_id,s.firstname+' '+s.lastname)}  data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="btn btn-success w-lg waves-effect waves-light text-white">
                                              View Profile</button>

                             
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                        :

                         gradeFilters === false ?

                         <div className="row justify-content-center mt-lg-5">
                         <div className="col-xl-5 col-sm-8">
                           <div className="card">
                             <div className="card-body">
                               <div className="text-center">
                                 <div className="row justify-content-center">
                                   <div className="col-lg-10">
                                     <h4 className="mt-4 font-weight-semibold">No Student Found </h4>
                                     <p className="text-muted mt-3">Oops! We can't find any student in grade { grade }</p>
                                     
                                   </div>
                                 </div>
                                 <div className="row justify-content-center mt-5 mb-2">
                                   <div className="col-sm-6 col-8">
                                     <div>
                                       <img src="/autheme/assets/images/verification-img.png" alt="" className="img-fluid" />
                                     </div>
                                   </div>
                                 </div>
                                 
                               </div>
                               {/* Modal */}
                              
                             </div>
                           </div>
                         </div>
                       </div>
                     

                           :
                         
                        <FilterStud studentFilter={displayFilterStudent} centreID={id} />


                    :
                    <div className="row justify-content-center mt-lg-5">
                    <div className="col-xl-5 col-sm-8">
                      <div className="card">
                        <div className="card-body">
                          <div className="text-center">
                            <div className="row justify-content-center">
                              <div className="col-lg-10">
                                <h4 className="mt-4 font-weight-semibold">No Student Found</h4>
                                <p className="text-muted mt-3">Oops! no student found in this centre.</p>
                                
                              </div>
                            </div>
                            <div className="row justify-content-center mt-5 mb-2">
                              <div className="col-sm-6 col-8">
                                <div>
                                  <img src="/autheme/assets/images/verification-img.png" alt="" className="img-fluid" />
                                </div>
                              </div>
                            </div>
                            
                          </div>
                          {/* Modal */}
                         
                        </div>
                      </div>
                    </div>
                  </div>
                
                }
           
            </div>
           
            {/* end row */}
            
            {/* end row */}

                    { countData > 12 ?
            <div className="row">
              <div className="col-12">
                <div className="text-center my-3">
                  <button className="text-success"><i className="bx bx-hourglass bx-spin me-2" /> Load more </button>
                </div>
              </div> {/* end col*/}
            </div>
            :
            null
                    }
            
            {/* end row */}
          </div> {/* container-fluid */}
        </div>
        {/* End Page-content */}


        <div class="modal fade bs-example-modal-xl" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="staticBackdropLabel">{ studentName } Profile Data</h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body">

                                                       { activateStatus === false ?   <div className="alert alert-info text-center">Learner request is stil under review</div>
                                                           :
 
                                                          <div className="row">
                                                            
                                                             
                                                            <div className="col-md-5">

                                                            <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-4">Personal Information</h4>

                                        
                                        <div class="table-responsive">
                                            <table class="table table-nowrap mb-0">
                                                <tbody>
                                                   
                                                    <tr>
                                                        <th scope="row">Full Name :</th>
                                                        <td>{ studentName }</td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row">Student ID No :</th>
                                                        <td>#{    studentID   }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Student Passport ID :</th>
                                                        <td>{ studID }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Gender :</th>
                                                        <td>{ gender }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Current Grade :</th>
                                                        <td>{ sgrade }</td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row">Age :</th>
                                                        <td>{ studentAge }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Email :</th>
                                                        <td>{ email }</td>  
                                                      </tr>
                                                    <tr>
                                                        <th scope="row">Phone :</th>
                                                        <td>{ phone }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Home Language :</th>
                                                        <td>{ homeLang }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Other Language :</th>
                                                        <td>{ otherLang }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Language of Instruct:</th>
                                                        <td>{ langInstruct }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Home Address:</th>
                                                        <td>{ homeAddress }</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                                              </div>

                                                              <div className="col-md-7">



                                                              <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-4">Subject Offerring</h4>
                                        <div class="table-responsive">
                                            <table class="table table-nowrap table-hover mb-0">
                                                <thead style={{background:'darkblue',color:'white'}}>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Subject Name</th>
                                                        <th scope="col">Subject Code</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { subjectData && subjectData.map(({subject_id,subject,id},index)=>{
                                                          return (
                                                            <tr key={id}>
                                                            <th scope="row">{ index + 1}</th>
                                                            <td>{ subject }</td>
                                                            <td>{ subject_id }</td>
                                                          
                                                        </tr>
                                                          )
                                                    })}
                                                   

                                                  
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                                                </div>
                                                                    
                                                         
                                                            </div>
                                                           
                                                  }

                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                     
       
      </div>



      <div className="modal fade" id="staticNote" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Your Reason</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>

            <div className="modal-body">
            { noteStatus !== null ? <div className="alert alert-info">{noteStatus}</div> : null}
                 
                                     <div className="mb-3">
                                        <label className="form-label">Reason why you want to remove { centreName }</label>
                                        <div>
                                            <textarea className="form-control" rows="5" 
                                            style={{resize:'none'}} 
                                            ></textarea>
                                        </div>
                                    </div>

            </div>

            <div className="modal-footer">
              { loadam === false ?
            <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light">
                            Submit Request
                            </button>
                      :
              <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" disabled>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Requesting...
                            </button> }
                    
              </div>

            </div>
        </div>
      </div>

            </div>
    )
}


export default CentreData