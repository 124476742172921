import axios from 'axios'
import React,{useState} from 'react'
import {Link} from 'react-router-dom'

function FilterStud({studentFilter,centreID}) {
          const [subjectData, setSubjectData] = useState();
          const [studentName, setStudentName] = useState();
          const [status, setStatus] = useState();
          const [activateStatus,SetActivationStatus] = useState(false);
          const [studentAge, setStudentAge] = useState();
          const [homeLang, setHomeLang] = useState();
          const [langInstruct, setLangInstruct] = useState();
          const [otherLang, setOtherLang] = useState();
          const [grade, setGrade] = useState();
          const [gender, setGender] = useState();
          const [email, setEmail] = useState();
          const [phone, setPhone] = useState();
          const [homeAddress, setHomeAddress] = useState();

     const fetchStudentData = (studID,studentName) =>{
          setStudentName(studentName);
        axios.get('/get-centre-student-data/'+studID+'/'+centreID,{
          headers: { 'Content-Type': 'application/json'}
        }).then((res)=>{
            // res.json({link:link,studentData:studentData,subjectData:result});


                  setStudentAge(res.data.studentData[0].age);
                  setGender(res.data.studentData[0].gender);
                  setHomeAddress(res.data.studentData[0].home_address);
                  setLangInstruct(res.data.studentData[0].lang_intruct);
                  setGrade(res.data.studentData[0].grade);
                  setPhone(res.data.studentData[0].phone);
                  setEmail(res.data.studentData[0].email);
                  setHomeLang(res.data.studentData[0].home_lang);
                  setOtherLang(res.data.studentData[0].other_lang);

                  setSubjectData(res.data.subjectData);
             

                if(res.data.link[0].verify === 'Pending'){
                        SetActivationStatus(false)

                }else
                {
                  SetActivationStatus(true)

                }
             
                
        })
     }
    return (
        <div>
            {
             studentFilter && studentFilter.map((s) => (
                        <div className="col-xl-3 col-sm-6" key={s.id}>
                        <div className="card text-center">
                          <div className="card-body">
                            <div className="avatar-sm mx-auto mb-4">
                              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-16">
                                D
                              </span>
                            </div>
                            <h5 className="font-size-15 mb-1"><Link to="#" className="text-dark">{ s.firstname+' '+s.lastname }</Link></h5>
                            <p className="text-muted">Grade: { s.grade }</p>
                           
                          </div>
                          <div className="card-footer bg-transparent border-top">
                            <div className="contact-links font-size-15">
                              
                          
                                            <button type="button" onClick={()=>fetchStudentData(s.student_id,s.firstname+' '+s.lastname)}  data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="btn btn-success w-lg waves-effect waves-light text-white">
                                              View Profile</button>

                             
                            </div>
                          </div>
                        </div>


                        <div class="modal fade bs-example-modal-xl" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="staticBackdropLabel">{ studentName } Profile Data</h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body">
                                                       
                                                        { activateStatus === false ?  <div className="alert alert-info text-center">Leaner request is stil under review</div> 
                                                        :
                                                          <div className="row">
                                                           
                                                            <div className="col-md-5">

                                                            <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-4">Personal Information</h4>

    
                                        <div class="table-responsive">
                                            <table class="table table-nowrap mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Full Name :</th>
                                                        <td>{ studentName }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Gender :</th>
                                                        <td>{ gender }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Current Grade :</th>
                                                        <td>{ grade }</td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row">Age :</th>
                                                        <td>{ studentAge }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Email :</th>
                                                        <td>{ email }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Phone :</th>
                                                        <td>{ phone }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Home Language :</th>
                                                        <td>{ homeLang }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Other Language :</th>
                                                        <td>{ otherLang }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Language of Instruct:</th>
                                                        <td>{ langInstruct }</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Home Address:</th>
                                                        <td>{ homeAddress }</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                                              </div>

                                                              <div className="col-md-7">

                                                              <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-4">Subject Offerring</h4>
                                        <div class="table-responsive">
                                            <table class="table table-nowrap table-hover mb-0">
                                                <thead style={{background:'darkblue',color:'white'}}>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Subject Name</th>
                                                        <th scope="col">Subject Code</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { subjectData && subjectData.map(({subject_id,subject,id},index)=>{
                                                          return (
                                                            <tr key={id}>
                                                            <th scope="row">{ index + 1}</th>
                                                            <td>{ subject }</td>
                                                            <td>{ subject_id }</td>
                                                          
                                                        </tr>
                                                          )
                                                    })}
                                                   

                                                  
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                                                </div>
                                                            
                                                            </div>
                                                        }


                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                   
                      </div>
                    ))
             }
        </div>
    )
}

export default FilterStud
