import axios from 'axios';
import React, {useState, useEffect} from 'react'
import Footer from '../Nav/Footer';
import '../Authent/Loader.css';
import ReactPaginate from 'react-paginate';
import {useNavigate} from 'react-router-dom'




function Unpaid() {
      
      const [paidInvoce, setPaidInvoice] = useState();
      const [invoiceStatus, setInvoiceStatus] = useState(null);
      const [invoiceDetails, setInvoiceDetails] = useState(false);
      const history = useNavigate();

      const [amount, setAmount] = useState();
      const [billcycle, setBillCycle] = useState();
      const [yearapply, setYearApply] = useState();
      const [datecreated, setDateCreated] = useState();
      const [paymentstatus, setPaymentStatus] = useState();
      const [loadinvoice, setLoadInvoice] = useState();
      const [invoiceid, setInvoiceId] = useState();
      const [notify, setNotify] = useState(null);
      const [active, setActive] = useState(false);
      const [student_id, setStudentID] = useState();
      const [pageNumber, setPageNumber] = useState(0);
      const [grade, setGrade] = useState();
      const [year, setYear] = useState();
      const [displayFund, setDisplayFund] = useState(false);
      const [packageName, setPackageName] = useState();
    
    

      
      
      const convertCurrency = (num) =>{
        return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }


      const studentPerPage = 10;
      const pagesVisited = pageNumber * studentPerPage;
      const displayStudent = paidInvoce && paidInvoce
      .slice(pagesVisited, pagesVisited + studentPerPage)
      .map((inv) =>{
        return (
          <tr key={inv.id} >
                                                                                            
          <td>{ inv.invoice_id } </td>

          <td> { convertCurrency(parseInt(inv.amount)) }</td>
          
          <td> 
                { inv.payment_status === 'Pending' ?
              <span className="badge bg-danger">{ inv.payment_status }</span>
              : 
              <span className="badge bg-success">{ inv.payment_status }</span>
               }
          </td>
          <td>{ inv.date_created } </td>

          <td>
          <button type="button" onClick={()=>handleInvoiceData(inv.invoice_id)} className="btn btn-primary btn-sm btn-rounded waves-effect waves-light">
                                          View Details
                                      </button>
          </td>
          </tr>
        )
      })
      
      const pageCount = Math.ceil(paidInvoce && paidInvoce.length / studentPerPage);
      const changePage = ({selected}) =>{
            setPageNumber(selected)
      }



       const handleInvoiceData = (invoiceID) => {

        axios.get("/get-student-invoice/"+invoiceID,{
          headers: { 'Content-Type': 'application/json'}
       })
        .then((response)=>{
            
              if(response.data.validInvoice === true){
                        setLoadInvoice(response.data.invoice);
                      setInvoiceId(invoiceID);
                      setAmount(response.data.invoice[0].amount);
                      setBillCycle(response.data.invoice[0].bill_cycle);
                      setDateCreated(response.data.invoice[0].date_created);
                      setPaymentStatus(response.data.invoice[0].payment_status);
                      setYearApply(response.data.invoice[0].applying_year);
                      setStudentID(response.data.invoice[0].student_id);
                      setYear(response.data.invoice[0].applying_year);
                      setGrade(response.data.invoice[0].grade);
                      setPackageName(response.data.invoice[0].package_name);
                      
                      

              }else
              {

              }
        });

            setInvoiceDetails(true); 
            
       }

       const handleUpdateTransaction = (rembala,invoice_id) =>{
                  axios.post('/update-invoice-data',{
                     rembala:rembala,
                     invoiceID:invoice_id,
                     billcycle:billcycle,
                     amount:amount,
                     student_id:student_id,
                     grade:grade
                  },{
                    headers: { 'Content-Type': 'application/json'}
                 }).then((response)=>{
                          if(response.data.status === true){
                            setNotify('Congratulation! your payment was successful');
                            setPaymentStatus('PAID');
                          }

                          setTimeout(()=>{
                                  setNotify(null)
                          },3000);
                  })
       }

       const handleTransaction = () => {
            setActive(true);
            setNotify(null)
            axios.get('/get-users-information',{
              headers: { 'Content-Type': 'application/json'}
           })
            .then((response)=>{
              const wallet = response.data.userData[0].wallet;
              
                    setTimeout(()=>{
                      setActive(false);
                      if(wallet === null){
                           setNotify('Unable to charge your wallet due to insufficient fund. Please fund your wallet to proceed to payment');
                           setDisplayFund(true)
                      }else{
                           if(parseInt(amount) > parseInt(wallet)){
                             setNotify('Unable to complete request due to insufficient fund in your wallet. Please fund your wallet to proceed to payment');
                             setDisplayFund(true)
                           }else{
                            setDisplayFund(false)
                                const updatewallet = parseInt(wallet);
                                const updateAmount = parseInt(amount);
                                  let spent = updatewallet - updateAmount;
                                 
                                handleUpdateTransaction(spent,invoiceid);
                           }
                      }

                    },2000);
                   
            })
       }

  
      useEffect(()=>{
            let compoentMount = true;
             const unPad = async () =>{
              await axios.get('/get-user-invoice/Pending',{
                headers: { 'Content-Type': 'application/json'}
             })
              .then((response)=>{
                       if(compoentMount){
                      if(response.data.invoiceData === false){
                                  setInvoiceStatus("No purchase has been made");
                      }else{
                              setPaidInvoice(response.data.invoiceData); 
                      }
                    }
              })
             }
              unPad();
              return ()=>{
                  compoentMount = false;
              }
           
      })
    return (
        <div>

             <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Unpaid Invoice</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Finance</li>
                      <li className="breadcrumb-item active">Unpaid Invoice</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
             <div className="col-lg-12">
               <div className="tuk">
               <div className="row mb-2">
      
                 </div>
                 <div className="table-responsive">
                   
                    { invoiceStatus !== null ? <div><br></br><div className="alert alert-info">{invoiceStatus}</div><br></br> </div>
                    :
                     invoiceDetails === false ?
                     <div>
                   <table className="table project-list-table table-nowrap align-middle table-borderless">
                     <thead>
                       <tr>
                         
                         <th scope="col">#invoiceID</th>
                         
                         <th scope="col">Amount</th>
                         <th scope="col">Payment Status</th>
                         <th scope="col">Date</th>
                         <th scope="col">Action</th>
                       </tr>
                     </thead>
                     <tbody>
             {  displayStudent  }
           
                    </tbody>
                   </table>

                   <ReactPaginate
 previousLabel={<i className="mdi mdi-chevron-left" />}
 nextLabel={<i className="mdi mdi-chevron-right" />}
pageCount={pageCount}
onPageChange={changePage}
breakClassName={'page-item'}
breakLinkClassName={'page-link'}
containerClassName={'pagination pagination-rounded justify-content-center mt-2 mb-5'}
pageClassName={'page-item'}
pageLinkClassName={'page-link'}
previousClassName={'page-item'}
previousLinkClassName={'page-link'}
nextClassName={'page-item'}
nextLinkClassName={'page-link'}
activeClassName={'active'}

/>

                     </div> 
     :
                      
                     <div>

                        { active === false ? null :
                      <div id="preloader-spinner" className="preloader spinner">

                      <div className="wrapper">
                          <span className="loader"></span>
                      
                          <p style={{marginTop:120, marginLeft:-20}}>Transaction in progress. Please wait...</p>
                      </div>

                      </div>
                     
                     
                     }
                  
                <div className="card">
                  <div className="card-body">
                    <div className="invoice-title">
                      <h4 className="float-end font-size-16">Invoice ID # { invoiceid } </h4>
                      <div className="mb-4">
                        <img src="/autheme/assets/images/eq4.png" alt="logo" height={40} />
                      </div>
                    </div>
                    <hr />
                    { notify !==null ? <div className='alert alert-info'>{ notify }</div> : null }
                    <div className="row">
                      <div className="col-sm-6 mt-3">
                      { displayFund !== false ? 
                             <div>
                          <button onClick={()=> history("/mywallet")} className="btn btn-primary w-md waves-effect waves-light">Fund Wallet</button><br></br><br></br>
                           </div>
                          : null
                        }
                        <address>
                            { paymentstatus === "Pending" ?
                            <div>
                               <strong>Payment Status  : <span className="badge bg-danger" style={{padding:5}}>{ paymentstatus }</span></strong><br />
                              <br/>
                          <div className="btn btn-primary waves-effect waves-light me-1" onClick={()=>handleTransaction()}> Pay Now</div><br />
                          <br />
                              <h4>Banking Details</h4>
                              <table className='table'>
                                 <tbody>
                                   <tr>
                                     <td><strong>Bank Name</strong></td>
                                     <td><strong>FNB</strong></td>
                                   </tr>
                                   <tr>
                                     <td><strong>Account Holder</strong></td>
                                     <td><strong>FastExcellence</strong></td>
                                   </tr>
                                   <tr>
                                     <td><strong>Account Number</strong></td>
                                     <td><strong>62 891 239 511</strong></td>
                                   </tr>
                                   <tr>
                                     <td><strong>Reference</strong></td>
                                     <td><strong>Learners Full name and Grade</strong></td>
                                   </tr>
                                 </tbody>
                                </table>
                                    
                        
                          <br />
                            </div> :
                            <div>
                            <strong style={{color:'darkgreen'}}>PAID</strong>
                            </div>
}
                         
                        </address>
                      </div>
                      <div className="col-sm-6 mt-3 text-sm-end">
                        <address>
                          <strong>Order Date:</strong><br />
                          { datecreated } <br /><br />
                        </address>
                      </div>
                    </div>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">Order summary</h3>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-nowrap">
                        <thead>
                          <tr>
                            <th style={{width: '70px'}}>Year Applying</th>
                            <th>Grade</th>
                            <th>Billing Cycle</th>
                            <th>Description</th>
                            <th className="text-end">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{ yearapply } </td>
                            <td>{ grade }  { grade === 'R' ? 'Package: '+packageName : null} </td>
                            <td>{ billcycle }</td>
                            <td>Homeschool Curriculum &amp; Assessment </td>
                            <td className="text-end">{ convertCurrency(parseInt(amount)) }</td>
                          </tr>
                          
                          <tr>
                            <td colSpan={2} className="text-end">Sub Total</td>
                            <td className="text-end">{ convertCurrency(parseInt(amount)) }</td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="border-0 text-end">
                              <strong>Shipping</strong></td>
                            <td className="border-0 text-end">R0.00</td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="border-0 text-end">
                              <strong>Total</strong></td>
                            <td className="border-0 text-end"><h4 className="m-0">{ convertCurrency(parseInt(amount)) }</h4></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-print-none">
                    <div className="float-start">
                    
                        <div onClick={()=> setInvoiceDetails(false)} className="btn btn-primary w-md waves-effect waves-light">Back</div>
                      </div>
                      <div className="float-end">
                        <button className="btn btn-success waves-effect waves-light me-1" onClick={()=> window.print()}><i className="fa fa-print" /> Print Invoice</button>
                        
                      </div>
                    </div>
                  </div>
                </div>
 
                     </div>
                      

                        }


                    <div style={{marginTop:100}}></div>
                 </div>
               </div>
             </div>
              
           </div>

            <Footer />
          </div>
        </div>
          </div>
        </div>
    )
}

export default Unpaid
